<script setup>
import PageHeader from "@/components/PageHeader.vue"
import ActionButton from '@/components/ActionButton.vue'
import CountryLevels from '@/components/CountryLevels.vue'
import Accordion from "@/components/Accordion.vue"
import AccordionItem from "@/components/AccordionItem.vue"

const eb=0

</script>


<template>
    <PageHeader title="Registration fees for online event">
        <template #summary>Registration opens: October 2022</template>
        <template #buttons>
            <ActionButton action="registerCongress" />
        </template>
    </PageHeader>

    <!-- Main content -->
    <main id="content">
        <div class="container py-5">

            <div class="row g-5">
                <div class="col-12">
                    <div class="bg-wp-congress-light fs-6 wp-rounded shadow h-100 p-4">
                        <p class="fw-bold fg-wp-dark fs-5">If you can’t make the in-person congress, sign up to our online event taking place 23-25 June 2023.</p>

                        <ul>
                            <li>To make it possible for as many people as possible to share knowledge and experiences, World Physiotherapy is offering an online event, 23-25 June 2023. The online event will include access to all recordings of presentations, and posters from the in-person event and live Q&A discussions with presenters from the in-person event.</li>
                            <li>All registration fees are in US dollars</li>
                            <li>World Physiotherapy is offering reduced fees for people from low and lower middle income countries/territories.</li>
                            <li>Prices shown exclude VAT which will be charged at 20%, as per the rate applicable in the United Kingdom.</li>
                        </ul>
                    </div>
                </div>
            </div>

            <div class="row mt-0 g-5 align-items-center text-center">
                <div class="col-6">
                    <router-link to="/registration/categories" class="btn bg-wp-exercise shadow p-3 fs-4">Definitions of registration categories</router-link>
                </div>

                <div class="col-6">
                    <router-link to="/registration/fees" class="btn bg-wp-exercise shadow p-3 fs-4">View fees for in-person event</router-link>
                </div>
            </div>


            <CountryLevels />


            <!-- Fees  -->
            <div id="fees" class="mt-5 text-center">
                <h2 class="fw-bold">Registration fees (online event)</h2>

                <div id="fees-high" class="mt-5 text-center">
                    <h2>High and upper middle income country/territory</h2>

                    <div class="row g-5 justify-content-center">

                        <!-- High:  Early bird -->
                        <div v-if="eb" class="col-lg-4">
                            <div class="bg-wp-congress p-3">
                                <div class="fs-4 fw-bold">Early bird</div>
                                <div class="fs-5">(up to 15 December 2022)</div>
                            </div>        

                            <div class="row row-cols-1 g-0 text-center align-items-center bg-wp-congress-light">
                                <div class="col p-3 ">
                                    <div class="fs-6 fw-bold fg-wp-dark">Full access to all online content and live Q&A with presenters from key sessions</div>
                                </div>
                                <div class="col bg-wp-grey-light p-3" >
                                    <div class="fs-6">Member</div>
                                    <div class="fs-6 text-nowrap">PT assistant</div>
                                    <div class="fs-1 fg-wp-black">$130</div>
                                </div>
                                <div class="col bg-wp-grey-light p-3 " >
                                    <div class="fs-6">Retired</div>
                                    <div class="fs-6">Entry level student</div>
                                    <div class="fs-1 fg-wp-black">$110</div>
                                </div>
                                <div class="col bg-wp-grey-light p-3 " >
                                    <div class="fs-6 text-nowrap">Non member</div>
                                    <div class="fs-1 fg-wp-black">$200</div>
                                </div>
                            </div>
                        </div>

                        <!-- High: Advance -->
                        <div class="col-lg-4 ">
                            <div class="bg-wp-congress p-3">
                                <div class="fs-4 fw-bold">Advance</div>
                                <div class="fs-5">(up to 16 June 2023)</div>
                            </div>   

                            <div class="row row-cols-1 g-0 text-center align-items-center bg-wp-congress-light">
                                <div class="col p-3">
                                    <div class="fs-6 fw-bold fg-wp-dark">Full access to all online content and live Q&A with presenters from key sessions</div>
                                </div>
                                <div class="col bg-wp-grey-light p-3" >
                                    <div class="fs-6">Member</div>
                                    <div class="fs-6 text-nowrap">PT assistant</div>
                                    <div class="fs-1 fg-wp-black">$160</div>
                                </div>
                                <div class="col bg-wp-grey-light p-3" >
                                    <div class="fs-6">Retired</div>
                                    <div class="fs-6 ">Entry level student</div>
                                    <div class="fs-1 fg-wp-black">$125</div>
                                </div>
                                <div class="col bg-wp-grey-light p-3" >
                                    <div class="fs-6 text-nowrap">Non member</div>
                                    <div class="fs-1 fg-wp-black">$250</div>
                                </div>
                            </div>
                        </div>

                    </div>
                </div>


                <div id="fees-mid" class="mt-5 text-center">
                    <h2>Lower middle income country/territory</h2>

                   <div class="row g-5 justify-content-center">

                        <!-- High:  Early bird -->
                        <div v-if="eb" class="col-lg-4">
                            <div class="bg-wp-congress p-3">
                                <div class="fs-4 fw-bold">Early bird</div>
                                <div class="fs-5">(up to 15 December 2022)</div>
                            </div>        

                            <div class="row row-cols-1 g-0 text-center align-items-center bg-wp-congress-light">
                                <div class="col p-3 ">
                                    <div class="fs-6 fw-bold fg-wp-dark">Full access to all online content and live Q&A with presenters from key sessions</div>
                                </div>
                                <div class="col bg-wp-grey-light p-3" >
                                    <div class="fs-6">Member</div>
                                    <div class="fs-6 text-nowrap">PT assistant</div>
                                    <div class="fs-1 fg-wp-black">$97</div>
                                </div>
                                <div class="col bg-wp-grey-light p-3" >
                                    <div class="fs-6">Retired</div>
                                    <div class="fs-6">Entry level student</div>
                                    <div class="fs-1 fg-wp-black">$85</div>
                                </div>
                                <div class="col bg-wp-grey-light p-3 " >
                                    <div class="fs-6 text-nowrap">Non member</div>
                                    <div class="fs-1 fg-wp-black">$200</div>
                                </div>
                            </div>
                        </div>

                        <!-- High: Advance -->
                        <div class="col-lg-4 ">
                            <div class="bg-wp-congress p-3">
                                <div class="fs-4 fw-bold">Advance</div>
                                <div class="fs-5">(up to 16 June 2023)</div>
                            </div>   

                            <div class="row row-cols-1 g-0 text-center align-items-center bg-wp-congress-light">
                                <div class="col p-3 ">
                                    <div class="fs-6 fw-bold fg-wp-dark">Full access to all online content and live Q&A with presenters from key sessions</div>
                                </div>
                                <div class="col bg-wp-grey-light p-3 " >
                                    <div class="fs-6">Member</div>
                                    <div class="fs-6 text-nowrap">PT assistant</div>
                                    <div class="fs-1 fg-wp-black">$120</div>
                                </div>
                                <div class="col bg-wp-grey-light p-3 " >
                                    <div class="fs-6">Retired</div>
                                    <div class="fs-6 ">Entry level student</div>
                                    <div class="fs-1 fg-wp-black">$95</div>
                                </div>
                                <div class="col bg-wp-grey-light p-3 " >
                                    <div class="fs-6 text-nowrap">Non member</div>
                                    <div class="fs-1 fg-wp-black">$250</div>
                                </div>
                            </div>
                        </div>

                    </div>
                </div>


                <div id="fees-low" class="mt-5 text-center">

                <h2>Lower income country/territory</h2>

                <div class="row g-5 justify-content-center">

                    <!-- High:  Early bird -->
                    <div v-if="eb" class="col-lg-4">
                        <div class="bg-wp-congress p-3">
                            <div class="fs-4 fw-bold">Early bird</div>
                            <div class="fs-5">(up to 15 December 2022)</div>
                        </div>        

                        <div class="row row-cols-1 g-0 text-center align-items-center bg-wp-congress-light">
                            <div class="col p-3 ">
                                <div class="fs-6 fw-bold fg-wp-dark">Full access to all online content and live Q&A with presenters from key sessions</div>
                            </div>
                            <div class="col bg-wp-grey-light p-3" >
                                <div class="fs-6">Member</div>
                                <div class="fs-6 text-nowrap">PT assistant</div>
                                <div class="fs-1 fg-wp-black">$65</div>
                            </div>
                            <div class="col bg-wp-grey-light p-3" >
                                <div class="fs-6">Retired</div>
                                <div class="fs-6">Entry level student</div>
                                <div class="fs-1 fg-wp-black">$55</div>
                            </div>
                            <div class="col bg-wp-grey-light p-3 " >
                                <div class="fs-6 text-nowrap">Non member</div>
                                <div class="fs-1 fg-wp-black">$200</div>
                            </div>
                        </div>
                    </div>

                    <!-- High: Advance -->
                    <div class="col-lg-4 ">
                        <div class="bg-wp-congress p-3">
                            <div class="fs-4 fw-bold">Advance</div>
                            <div class="fs-5">(up to 16 June 2023)</div>
                        </div>   

                        <div class="row row-cols-1 g-0 text-center align-items-center bg-wp-congress-light">
                            <div class="col p-3 ">
                                <div class="fs-6 fw-bold fg-wp-dark">Full access to all online content and live Q&A with presenters from key sessions</div>
                            </div>
                            <div class="col bg-wp-grey-light p-3 " >
                                <div class="fs-6">Member</div>
                                <div class="fs-6 text-nowrap">PT assistant</div>
                                <div class="fs-1 fg-wp-black">$80</div>
                            </div>
                            <div class="col bg-wp-grey-light p-3 " >
                                <div class="fs-6">Retired</div>
                                <div class="fs-6 ">Entry level student</div>
                                <div class="fs-1 fg-wp-black">$63</div>
                            </div>
                            <div class="col bg-wp-grey-light p-3 " >
                                <div class="fs-6 text-nowrap">Non member</div>
                                <div class="fs-1 fg-wp-black">$250</div>
                            </div>
                        </div>
                    </div>

                </div>
            </div>

        </div>


        <!-- Whats included -->
        <div class="row g-5 mt-0">
            <div class="col-12">
                <div class="wp-rounded shadow h-100 bg-wp-congress-light">
                    <h3 class="bg-wp-congress wp-rounded-top text-center p-3 mb-0">What’s included in your registration for the online event</h3>
                    <div class="wp-rounded-bottom p-3 fs-5">
                        <div class="row gx-0 gy-3">
                            <h4>
                                The registration fee for the online event includes the following for all participants:
                            </h4>
                            <div class="d-flex align-items-start">
                                <img height="50" src="../../public\img\reg\RegFees-7-ondemand.svg" />
                                <div class="ms-2">
                                    opportunity to participate in online live discussions with presenters from key sessions and watch recordings of all sessions recorded at the in-person event in Dubai including:
                                    <ul class="mt-2">
                                        <li>short online live welcome and closing sessions</li>
                                        <li>30-minute live Q&A sessions with the relevant presenters to discuss issues raised in:
                                            <ul >
                                                <li>17 focused symposia sessions</li>
                                                <li>13 discussion sessions</li>
                                                <li>13 seminar sessions</li>
                                                <li>3 World Physiotherapy seminar sessions</li>
                                            </ul>
                                        </li>
                                        <li>access to over 800 uploaded poster and ePoster presentations</li>
                                    </ul>
                                </div>
                            </div>
                            <div class="d-flex align-items-center">
                                <img height="50" src="../../public\img\reg\RegFees-OD-cafe.svg" />
                                <div class="ms-2">
                                    connect with colleagues in the global physiotherapy community in live World Café sessions
                                </div>
                            </div>
                            <div class="d-flex align-items-center">
                                <img height="50" src="../../public\img\reg\RegFees-2-abstracts.svg" />
                                <div class="ms-2">
                                    access to all submitted abstracts and biographies of presenters online
                                </div>
                            </div>
                            <div class="d-flex align-items-center">
                                <img height="50" src="../../public\img\reg\RegFees-OD-calendar.svg" />
                                <div class="ms-2">
                                    on-demand content will be <b>available until 30 September 2023</b> for all in-person and online registered participants.
                                </div>
                            </div>

                            <p class="mt-5 fw-bold">
                                Programme subject to change
                            </p>
                        </div>

                    </div>
                </div>
            </div>
        </div>


            <!-- Yellow feature boxes -->
            <div class="row row-eq-height g-5 mt-0">
                <div class="col-lg-4">
                    <div class="wp-rounded shadow h-100 text-center position-relative bg-wp-white">
                        <div class="bg-wp-exercise wp-rounded-top p-3 mb-0">
                            <img height="128" src="../../public/img/reg/RegCategories.svg" />
                        </div>
                        <div class="text-center p-4">
                            <router-link to="/registration/categories" class="text-decoration-none fw-bold fs-5 stretched-link">View registration categories</router-link>
                        </div>
                    </div>
                </div>

                <div class="col-lg-4">
                    <div class="wp-rounded shadow h-100 text-center position-relative bg-wp-white">
                        <div class="bg-wp-exercise wp-rounded-top p-3 mb-0">
                            <img height="128" src="../../public/img/reg/RegFees-inperson.svg" />
                        </div>
                        <div class="text-center p-4">
                            <router-link to="/registration/fees" target="_blank" class="text-decoration-none fw-bold fs-5 stretched-link">View registration fees for in-person event</router-link>
                        </div>
                    </div>
                </div>

                <div class="col-lg-4">
                    <div class="wp-rounded shadow h-100 text-center position-relative bg-wp-white">
                        <div class="bg-wp-exercise wp-rounded-top p-3 mb-0">
                            <img height="128" src="../../public/img/Download-bursary.svg" />
                        </div>
                        <div class="text-center p-4">
                            <router-link to="/registration/bursary" class="text-decoration-none fw-bold fs-5 stretched-link">Bursary programme</router-link>
                        </div>
                    </div>
                </div>
            </div>



            <!-- FAQs -->
            <div class="mt-5">
                <Accordion parentId="registration">
                    <AccordionItem parentId="registration" itemId="cancellation">
                        <template #header>
                            <img height="50" class="me-3" src="../../public/img/reg/RegFees-OD-cancellation.svg" /> Cancellation policy
                        </template>
                        <template #body>
                            <p>Cancellations, substitutions and changes to registrations must be submitted in writing to the World Physiotherapy Congress 2023 registration team by email to <a href='mailto:worldphysio2023@kenes.com'>worldphysio2023@kenes.com</a></p>   
                            <p>Substitutions for registration will be accepted up until Friday 13 May 2023 (23:59 UTC).</p>
                            
                            <h4>Refunds</h4>
                            <p>Refunds for registration, including those resulting from changes to registrations, will be made as follows:</p>
                            <ul>
                                <li>In-person event registration: refund less $60 [$40 for lower middle income fee band; $40 for low income fee band] up to Friday 13 May 2023</li>
                                <li>Online event registration: refund less $20 [$15 for lower middle income fee band; $10 for low income fee band] up to Friday 13 May 2023</li>
                                <li>No refunds for cancellations made on or after Friday 13 May 2023 (23:59 UTC)</li>
                            </ul>
                        </template> 
                    </AccordionItem>
                    <AccordionItem parentId="registration" itemId="contact">
                        <template #header>
                            <img height="50" class="me-3" src="../../public/img/reg/RegFees-OD-contact.svg" /> Contact
                        </template>
                        <template #body>
                            <p>For questions or further information regarding registration, please email: <a href='mailto:worldphysio2023@kenes.com'>worldphysio2023@kenes.com</a></p>   
                        </template> 
                    </AccordionItem>
                    <AccordionItem parentId="registration" itemId="faq">
                        <template #header>
                            <img height="50" class="me-3" src="../../public/img/reg/RegFees-OD-FAQ.svg" /> FAQs
                        </template>
                        <template #body>
                            <p>View our <a href="#/app/generalinfo/d03ca532-0318-47c2-82c4-235ec9aec420">FAQs</a></p>   
                        </template> 
                    </AccordionItem>                    
                </Accordion>
            </div>

        </div>
    </main>



</template>
