<script setup>
import PageHeader from '@/components/PageHeader.vue'
import SessionCardList from '@/components/SessionCardList.vue'
import { useRoute } from 'vue-router'
import store from '@/store'

const route = useRoute()
const sessionTypeId = route.params.type

const typeResults = store.state.types.filter((type) => type.id == sessionTypeId)
const sessionType = typeResults[0]

const title = (sessionType && sessionType.name) ? sessionType.name + " sessions" : "Congress programme"

</script>


<template>
    <PageHeader :title="title">
        <template #summary>View sessions as they are added</template>
        <template #buttons>
            <!--<router-link to="#" class="btn btn-wp-light me-2 mb-2">Programme overview</router-link>-->
            <!--<router-link to="#" class="btn btn-outline-light me-2 mb-2">Presentations</router-link>-->
            <router-link to="/programme/participants" class="btn btn-outline-light me-2 mb-2">View presenters/chairs</router-link>
        </template>
    </PageHeader>

    <SessionCardList :type="sessionTypeId" showpeople searchBar />
</template>