<script setup>
    import IndustryCard from '@/components/IndustryCard.vue'
    import store from '@/store'
    import { defineProps } from 'vue'

    const props = defineProps({
        levels: { type: Object, default() { return [] }},
        buttons: { type: Boolean, default() { return false }}
    })

    let industry = store.state.industry
    industry =  industry.filter((ind) => ind.level != "Exhibitor")
    if (props.levels.length>0) industry = industry.filter((ind) => props.levels.includes(ind.level))

    let headingclass = props.buttons ? "col" : "col text-center";

</script>



<template v-if="industry.length>0">     
    <!-- Congress partners listing -->
    <section id="partners" class="bg-wp-congress-mid py-5">
        <div class="container">
            <div v-if="props.buttons" class="d-flex flex-wrap justify-content-between">
                <div :class="headingclass">
                    <h1 class="pb-3 pe-2 fg-wp-dark">Partners</h1>
                </div>
                <div class="col-sm-auto">
                    <div class="d-flex flex-wrap justify-content-end">
                        <div><router-link class="btn btn-outline-primary me-3 mb-3" to="/exhibition/exhibitors">View partners/exhibitors</router-link></div>
                        <div><router-link class="btn btn-outline-primary" to="/exhibition">View exhibition</router-link></div>
                    </div>
                </div>
            </div>

            <div class="row row-eq-height row-cols-2 row-cols-md-3 row-cols-lg-4 row-cols-xl-5 g-5 justify-content-center">

                <IndustryCard v-for="item in industry" v-bind:key="item.id"
                    :id="item.id"
                    :name="item.name"
                    :level="item.level"
                    :image="item.image">
                </IndustryCard>

            </div>
        </div>
    </section>
</template>