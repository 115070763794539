import { createRouter, createWebHashHistory } from 'vue-router'

import Home from '../views/Home.vue'
import Registration from '../views/Registration.vue'
import RegistrationCategories from '../views/RegistrationCategories.vue'
import RegistrationFees from '../views/RegistrationFees.vue'
import RegistrationFeesOnline from '../views/RegistrationFeesOnline.vue'
import RegistrationTravel from '../views/RegistrationTravel.vue'
import RegistrationPhoto from '../views/RegistrationPhoto.vue'
import RegistrationAccommodation from '../views/RegistrationAccommodation.vue'
import Exhibition from '../views/Exhibition.vue'
import About from '../views/About.vue'
import Visitor from '../views/Visitor.vue'
import CPC from '../views/CPC.vue'
import Formats from '../views/Formats.vue'
import Media from '../views/Media.vue'
import Profile from '../views/Profile.vue'
import Settings from '../views/Settings.vue'
import Messages from '../views/Messages.vue'
import Search from '../views/Search.vue'
import Session from '../views/Session.vue'
import MyCongress from '../views/MyCongress.vue'
import Sessions from '../views/Sessions.vue'
import Presentations from '../views/Presentations.vue'
import Presentation from '../views/Presentation.vue'
import Participants from '../views/Participants.vue'
import faq from '../views/FAQ.vue'
import Person from '../views/Person.vue'
import PreviousFS from '../views/PreviousFS.vue'
import Timelines from '../views/Timelines.vue'
import Types from '../views/SessionTypes.vue'
import RoleOverview from '../views/SessionRole.vue'
import ExhibitorList from '../views/ExhibitorList.vue'
import Industry from '../views/Industry.vue'
import PdfTest from '../views/PdfTest.vue'
import ExhibitionMap from '../views/ExhibitionMap.vue'
import MediaRegistration from '../views/MediaRegistration.vue'
import ProgrammeOnline from '../views/ProgrammeOnlineLive.vue'
import Volunteering from '../views/VolunteeringOpen.vue'

const routes = [
  { path: '/', name: 'Home', component: Home, meta: {title: 'Home'} },
  { path: '/programme/call-for-fs-closed', name: 'Call for FS Closed', component: () => import('../views/Call-For-FS-Closed.vue'), meta: {title: 'Call for FS'} },
  { path: '/programme/call-for-fs', name: 'Call for FS', component: () => import('../views/Call-For-FS.vue'), meta: {title: 'Call for FS'} },
  { path: '/programme/abstracts', name: 'Abstracts', component: () => import('../views/AbstractsOpen.vue'), meta: {title: 'Abstracts'} },
  { path: '/programme/abstracts-LBA', name: 'Abstracts LBA', component: () => import('../views/AbstractsLBAOpen.vue'), meta: {title: 'Abstracts'} },
  { path: '/programme/abstracts-closed', name: 'Abstracts Closed', component: () => import('../views/AbstractsClose.vue'), meta: {title: 'Abstracts'} },
  { path: '/programme/abstracts/support', name: 'Abstract mentoring', component: () => import('../views/AbstractMentoringOpen.vue'), meta: {title: 'Support for abstract submitters'} },
  { path: '/programme/abstracts/support-closed', name: 'Abstract mentoring Closed', component: () => import('../views/AbstractMentoringClose.vue'), meta: {title: 'Support for abstract submitters'} },
  { path: '/programme/sessions', name: 'Sessions', component: Sessions, meta: {title: 'Sessions'} },
  { path: '/programme/presentations', name: 'Presentations', component: Presentations, meta: {title: 'Presentations'} },
  { path: '/programme/participants', name: 'Participants', component: Participants, meta: {title: 'Chairs and presenters'} },
  { path: '/programme/overview', name: 'Programme at a glance', component: () => import('../views/ProgrammeOutlineNew.vue'), meta: {title: 'Programme at a glance'} },
  { path: '/programme/online', name: 'Online event programme', component: ProgrammeOnline, meta: {title: 'Online event programme'} },
  { path: '/registration/categories', name: 'RegistrationCatgories', component: RegistrationCategories, meta: {title: 'Registration categories'} },
  { path: '/registration/fees', name: 'RegistrationFees', component: RegistrationFees, meta: {title: 'Registration fees'} },
  { path: '/registration/fees-online', name: 'RegistrationFeesOnline', component: RegistrationFeesOnline, meta: {title: 'Registration fees for online event'} },
  { path: '/registration/bursary', name: 'RegistrationBursary', component: () => import('../views/RegistrationBursary.vue'), meta: {title: 'Bursary programme'} },
  { path: '/registration/bursary-closed', name: 'RegistrationBursary Closed', component: () => import('../views/RegistrationBursaryClose.vue'), meta: {title: 'Bursary programme'} },
  { path: '/registration/travel', name: 'RegistrationTravel', component: RegistrationTravel, meta: {title: 'Travel'} },
  { path: '/registration/accommodation', name: 'RegistrationAccommodation', component: RegistrationAccommodation, meta: {title: 'Accommodation'} },
  { path: '/registration/photo', name: 'RegistrationPhoto', component: RegistrationPhoto, meta: {title: 'Photography'} },
  { path: '/registration', name: 'Registration', component: Registration, meta: {title: 'Registration'} },
  { path: '/exhibition', name: 'Exhibition', component: Exhibition, meta: {title: 'Exhibition'} },
  { path: '/exhibition/exhibitors', name: 'Exhibitor list', component: ExhibitorList, meta: {title: 'Exhibitor List'} },
  { path: '/exhibition/exhibitor/:id', component: Industry, meta: {title: 'Exhibitor'} },
  { path: '/exhibition/map', name: 'Exhibition map', component: ExhibitionMap, meta: {title: 'Exhibition Map'} },  
  { path: '/about', name: 'About', component: About, meta: {title: 'About'} },
  { path: '/visitor', name: 'Visitor information', component: Visitor, meta: {title: 'Visitor information'} },
  { path: '/programme/CPC', name: 'Congress Programme Committee', component: CPC, meta: {title: 'Congress Programme Committee'} },
  { path: '/formats', name: 'Session formats', component: Formats, meta: {title: 'Session formats'} },
  { path: '/media', name: 'Media resources', component: Media, meta: {title: 'Media resources'} },
  { path: '/media/registration', name: 'Media registration', component: MediaRegistration, meta: {title: 'Media registration'} },
  { path: '/FAQ', name: 'FAQ', component: faq, meta: {title: 'FAQ'} },
  { path: '/timelines', name: 'Congress timelines', component: Timelines, meta: {title: 'Congress timelines'}},
  { path: '/programme/previousFS', name: 'Previous focused symposia', component: PreviousFS, meta: {title: 'Previous focused symposia'}},
  { path: '/programme/SessionTypes', name: 'Session types', component: Types, meta: {title: 'Session Types'} },
  { path: '/programme/SessionTypes/:type/:role', component: RoleOverview, meta: {title: 'Session role overview'} },
  { path: '/volunteering', name: 'Volunteering', component: Volunteering, meta: {title: 'Volunteering'} },
  { path: '/programme/indaba', name: 'Indaba', component: () => import('../views/IndabaOpen.vue'), meta: {title: 'Indaba'} },
  { path: '/registration/visas', name: 'Visas', component: () => import('../views/Visa.vue'), meta: {title: 'Visas'} },
  { path: '/presenter-tips', name: 'Presenter tips', component: () => import('../views/PresenterTips.vue'), meta: {title: 'Presenter tips'} },
  { path: '/congressconnect', name: 'Congress connect', component: () => import('../views/CongressConnect.vue'), meta: {title: 'Congress connect'} },
  { path: '/programme/online/23June', name: 'Friday 23 June', component: () => import('../views/ProgrammeOnline23June.vue'), meta: {title: 'Friday 23 June'} },
  { path: '/programme/online/24June', name: 'Saturday 24 June', component: () => import('../views/ProgrammeOnline24June.vue'), meta: {title: 'Saturday 24 June'} },
  { path: '/programme/online/25June', name: 'Sunday 25 June', component: () => import('../views/ProgrammeOnline25June.vue'), meta: {title: 'Sunday 25 June'} },

  { path: '/pdftest', component: PdfTest, meta: {title: 'PDF Test'} },
  

  { path: '/profile', name: 'My profile', component: Profile, meta: {title: 'My profile'} },
  { path: '/settings', name: 'My settings', component: Settings, meta: {title: 'My settings'} },
  { path: '/messages', name: 'Messages', component: Messages, meta: {title: 'Messages'} },
  { path: '/search', name: 'Search', component: Search, meta: {title: 'Search'} },
  { path: '/my/congress', name: 'My congress', component: MyCongress, meta: {title: 'My congress'} },

  { path: '/programme/session/:id', component: Session, meta: {title: 'Session'} },
  { path: '/programme/sessions/:type', component: Sessions, meta: {title: 'Session'} },
  { path: '/programme/presentation/:id', component: Presentation, meta: {title: 'Presentation'} },
  { path: '/programme/person/:id', component: Person, meta: {title: 'Person profile'} },

]

const router = createRouter({
  history: createWebHashHistory(process.env.BASE_URL),
  routes,
  scrollBehavior() {
    document.getElementById('app').scrollIntoView({ behavior: 'smooth' });
  }
})

export default router
