<style scoped>
.accordion-item {
    /*border-color: #ffffff;
    border-width:3px;*/
    border-bottom: 3px solid #ffffff;
    color: #ffffff;
}
.accordion-item:last-child {
    border-bottom:none;
}
.accordion-header, .accordion-button, .accordion-button:not(.colapsed), .accordion-button:focus {
    background-color: #41b6e6;
    border-color: #41b6e6;
    color: #ffffff;
    box-shadow:none;
    font-weight:600;
    font-size: 1.5rem;
}
.accordion-body {
    background-color: #41b6e620;
}
.accordion-button:after, .accordion-button:not(.collapsed)::after {
    background-image:url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='white'%3e%3cpath fill-rule='evenodd' d='M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z'/%3e%3c/svg%3e");
}
</style>


<template>
    <!-- Help -->
    <section>
        <div class="accordion mt-3 fg-wp-white" id="Help">
            <div class="shadow">
                <div class="accordion-item accordion-flush">
                    <h2 class="accordion-header" id="headingRequirements">
                        <button
                            data-bs-target="#collapseRequirements"
                            aria-controls="collapseRequirements"
                            class="accordion-button collapsed"
                            type="button"
                            data-bs-toggle="collapse"
                            aria-expanded="true"
                        >
                            <img src="../../public/img/Table-requirements.svg" height="32" class="me-3" />
                            Requirements
                        </button>
                    </h2>
                    <div
                        id="collapseRequirements"
                        aria-labelledby="headingRequirements"
                        class="accordion-collapse collapse"
                        data-bs-parent="#Help"
                    >
                        <div class="accordion-body fg-wp-black">
                            <p><b>The chair must have secured the agreement of all presenters before making a submission on their behalf.</b></p>
                            <p>World Physiotherapy and the CPC advocate for diversity and international perspectives across all congress sessions. This can be achieved through a range of aspects: a balance of gender, nationalities, perspectives from low, middle, high income countries/territories, including experiences from service users and under-represented groups or ethnicities.</p>
                            <p>Where applicable to the topic, priority will be given to symposia that <b>include perspectives from low-income countries/territories</b>. Successful symposium chairs can apply for a bursary to support the travel and registration needs of no more than one presenter from a World Physiotherapy member organisation in a low or lower-middle income country/territory (see appendix 1 for details).</p>
                            <p>World Physiotherapy and the CPC advocate for in-person presentations, but there may be an option for limited live remote presenter contributions (<b>no more than one per session</b>). If you wish to include this option, please provide details on the submission system. Please note, remote presenters will also need to register for congress.</p>
                            <p>A chair or a presenter (in-person or remote) may <b>only appear in one symposium</b>.</p>
                            <p>The online submission form offers a limited word count detailed below.</p>
                            <p>You will be asked to provide the following:</p>
                            <ol>
                                <li>title of suggested symposium [10 words]</li>
                                <li>names of the chair and all presenters</li>
                                <li>contact details for all presenters</li>
                                <li>relevance to physiotherapy globally [75 words]</li>
                                <li>target audience [25 words]</li>
                                <li>summary of the session focus and structure, including plans for audience interaction [100 words]</li>
                                <li>completed abstract for the symposium
                                    <p>One abstract, jointly authored by the chair and all the presenters, should provide a comprehensive overview of the symposium and linked presentations. This will be included in the congress proceedings and should be structured using the following headings:</p>
                                    <ul>
                                        <li>title</li>
                                        <li>authors (chair first, followed by all presenters)</li>
                                        <li>learning objectives (up to three)</li>
                                        <li>abstract</li>
                                        <li>implications/conclusions</li>
                                        <li>key words (up to three)</li>
                                        <li>funding acknowledgements</li>
                                        <li>supporting references (up to 10)</li>
                                    </ul>
                                    <p>[Total 750 words]</p>
                                </li>
                                <li>short biography for the chair and each presenter. This should outline academic qualifications, positions/appointments, most recent publication and presentation track record (last five years), research information (interests, grants), and professional practice background. [200 words for each biography]</li>
                                <li>details how diversity has been considered in preparing the submission [50 words]</li> 
                                <li>information on language support needs</li>
                                <li>consent for the submission to be considered for an alternative congress format, if unsuccessful</li>
                                <li>details of any presentations or publications of the work made prior to the World Physiotherapy Congress 2023 [50 words]</li>
                                <li>request for any presenters to be considered for travel funding assistance [separate application process, see appendix 1]</li>
                            </ol>

                        </div>
                    </div>
                </div>

                <div class="accordion-item accordion-flush">
                    <h2 class="accordion-header" id="headingPub">
                        <button
                            data-bs-target="#collapsePub"
                            aria-controls="collapsePub"
                            class="accordion-button collapsed"
                            type="button"
                            data-bs-toggle="collapse"
                            aria-expanded="true"
                        >
                        <img src="../../public/img/Table-publication.svg" height="32" class="me-3" />
                            Post-congress publication
                        </button>
                    </h2>
                    <div
                        id="collapsePub"
                        aria-labelledby="headingPub"
                        class="accordion-collapse collapse"
                        data-bs-parent="#Help"
                    >
                        <div class="accordion-body fg-wp-black">
                            <p>Focused symposia bring together presenters working on the same topic from diverse parts of the world. The abstract and presentations will be made available as part of a digital offering and presenters may be invited to take part in a follow-up online discussion to complement this.</p>
                        </div>
                    </div>
                </div>

                <div class="accordion-item accordion-flush">
                    <h2 class="accordion-header" id="headingPrinciples">
                        <button
                            data-bs-target="#collapsePrinciples"
                            aria-controls="collapsePrinciples"
                            class="accordion-button collapsed"
                            type="button"
                            data-bs-toggle="collapse"
                            aria-expanded="true"
                        >
                        <img src="../../public/img/Table-selection.svg" height="32" class="me-3" />
                            Selection principles
                        </button>
                    </h2>
                    <div
                        id="collapsePrinciples"
                        aria-labelledby="headingPrinciples"
                        class="accordion-collapse collapse"
                        data-bs-parent="#Help"
                    >
                        <div class="accordion-body fg-wp-black">
                            <p>Submissions will be reviewed against the following principles:</p>
                            <p><b>Explore:</b> the most recent ideas, evidence, methods and areas of controversy in the literature related to outcomes for people and populations</p>
                            <p><b>Inspire:</b> with a clear purpose and applicable outcomes for participants</p> 
                            <p><b>Connect:</b> participants by reflecting the diversity of the global profession and draw in audience from different fields</p>
                            <p><b>Include:</b> presenters with international experience and standing<sup>1</sup>  in the topic area, representing a range of global, possibly service user perspectives and presenters from underrepresented groups</p>
                            <p><b>Impact:</b> practice, education, research and/or policy by making clear the implications arising from the presentations including considerations on the international diversity of the profession</p>
                            <p>&nbsp;</p>
                            <p><sup>1</sup> Consideration will be given to symposia that support inclusion, as appropriate, of less experienced with established presenters.</p>
                        </div>
                    </div>
                </div>

                <div class="accordion-item accordion-flush">
                    <h2 class="accordion-header" id="headingGen">
                        <button
                            data-bs-target="#collapseGen"
                            aria-controls="collapseGen"
                            class="accordion-button collapsed"
                            type="button"
                            data-bs-toggle="collapse"
                            aria-expanded="true"
                        >
                        <img src="../../public/img/Table-geninfo.svg" height="32" class="me-3" />
                            General information
                        </button>
                    </h2>
                    <div
                        id="collapseGen"
                        aria-labelledby="headingGen"
                        class="accordion-collapse collapse"
                        data-bs-parent="#Help"
                    >
                        <div class="accordion-body fg-wp-black">
                            <ol>
                                <li>The congress language is English and all proposals and presentations must be made in English. Translation may be provided.</li>
                                <li>A symposium chair or presenter may only appear in one symposium proposal submission. This does not affect any abstract submissions.</li>
                                <li>Symposium chairs and all presenters must register for at least the day of congress that their session is programmed.</li>
                                <li>In keeping with World Physiotherapy policy honorarium, fee, waiving of registration fees or payment of expenses will not be provided for chairs or symposium presenters, except where a bursary is applied for and awarded to support a symposium presenter from a World Physiotherapy member organisation in a low or lower-middle-income country/territory.</li>
                                <li>Proposals should address the current evidence, areas of controversy in the literature, implications for practice and the need for further research.</li>
                                <li>All research and studies that involve human participants and animal subjects reported in submitted proposals must comply with <a href="https://bit.ly/3xHm9ch" target="_blank">general ethical principles.</a><sup>2</sup>.  See also the global standards<sup>3</sup></li>
                                <li>All proposals and presentations must adhere to the use of “people-first” language. A person must not be referred to by disability or condition, and terms that could be considered biased or discriminatory in any way should be removed (eg use “person with a stroke” instead of “stroke patients”).</li>
                                <li>Any source of funding or support for the work being presented should be acknowledged.</li>
                                <li> Unsuccessful high quality focused symposium submissions may be considered for a different session format (eg discussion session or seminar). This would mean:
                                    <ul>
                                        <li>the session outline and objectives would need to be amended, to align with the proposed session format</li>
                                        <li>submitting authors would need to be willing to be guided by the CPC to amend the session structure as needed</li>
                                        <li>it may not be possible for all submitting authors to be included in the amended session structure</li>
                                    </ul>
                                </li>
                                <li>The CPC may invite symposium chairs and presenters to contribute to other congress programme sessions, such as chairing platform presentations.</li>
                                <li>World Physiotherapy is planning for a digital offering in addition to the in-person congress, with details still to be defined. This will include the recording of sessions. As plans are agreed the implications will be communicated with symposium chairs and their cooperation is expected.</li>
                                <li>The author(s) retain the right, after presentation at the World Physiotherapy Congress 2023, to subsequently include the work in articles, books, or derivative works that he/she authors or edits, provided said use does not imply the endorsement of World Physiotherapy.</li>
                                <li>Standard lecture room layout will be provided with PowerPoint presentation in all focused symposium rooms.</li>
                                <li>Successful chairs and presenters will be expected to use their social media channels to promote their involvement in the World Physiotherapy Congress 2023 and will be supported by World Physiotherapy in these efforts.</li>
                                <li>Focused symposia are not to be used as marketing opportunities for new products, equipment or organisations, nor to refute or denigrate competitors’ products.</li>
                                <li>Descriptions and objectives for each focused symposium will be published in the preliminary and final programmes, on World Physiotherapy’s website, and in other promotional material.</li>
                                <li>Congress programme placement will be determined by the CPC to ensure best fit with the overall congress programme. Any requests for specific dates and times cannot be guaranteed but will be considered if submitted at the time of proposal submission.</li>
                                <li>Any changes to an accepted focused symposium must be submitted to the CPC for review and approval.</li>
                                <li>On request, a teleconference to support the collaboration between the relevant focused symposium presenters can be facilitated by World Physiotherapy staff and this is actively encouraged.</li>
                                <li>All decisions of the CPC are final.</li>
                            </ol>
                            <p>&nbsp;</p>
                            <p><sup>2</sup> With thanks to Julius Sim, University of Keele, UK, for permission to use this document.</p> 
                            <p><sup>3</sup> <a href="https://www.wma.net/policies-post/wma-declaration-of-helsinki-ethical-principles-for-medical-research-involving-human-subjects/" target="_blank">Declaration of Helsinki</a> of the World Medical Association and the Council for International Organizations of Medical Sciences’ (CIOMS) <a href="https://cioms.ch/publications/product/international-ethical-guidelines-for-health-related-research-involving-humans/" target="_blank">International Ethical Guidelines for Biomedical Research Involving Human Subjects</a> published in collaboration with the World Health Organization. CIOMS and International Council for Laboratory Animal Science (2012) <a href="https://grants.nih.gov/grants/olaw/Guiding_Principles_2012.pdf" target="_blank">International Guiding Principles for Biomedical Research Involving Animals</a>.</p>
                        </div>
                    </div>
                </div>

                <div class="accordion-item accordion-flush">
                    <h2 class="accordion-header" id="headingConsent">
                        <button
                            data-bs-target="#collapseConsent"
                            aria-controls="collapseConsent"
                            class="accordion-button collapsed"
                            type="button"
                            data-bs-toggle="collapse"
                            aria-expanded="true"
                        >
                        <img src="../../public/img/Table-consent.svg" height="32" class="me-3" />
                            Consent and permissions
                        </button>
                    </h2>
                    <div
                        id="collapseConsent"
                        aria-labelledby="headingConsent"
                        class="accordion-collapse collapse"
                        data-bs-parent="#Help"
                    >
                        <div class="accordion-body fg-wp-black">
                            <p>In submitting an abstract you must confirm that:</p><ul><li>you accept responsibility for the accuracy of the submitted abstract and understand that the content cannot be modified or corrected after the abstract submission deadline and that it will be published exactly as submitted</li><li>all co-authors are aware of and agree to the content of the abstract and support the data presented</li><li>all presenters have agreed to participate in this session at congress, should the proposal be accepted for presentation, and take responsibility for their own travel, accommodation and registration costs</li><li>you accept responsibility as the contact person for all correspondence about the abstract and to share information with co-authors about its status</li><li>you have secured any copyright/permissions clearance required relating to any previous presentations, equipment or other material for inclusion in the World Physiotherapy Congress 2023, its recordings/proceedings or other congress publications</li><li>you consent to the session being recorded and made available by World Physiotherapy</li><li>for all studies involving human participants or animal subjects, permission has been obtained from the relevant ethics committee and properly informed consent given where appropriate</li><li>anyone who is identifiable in the abstract and presentation has given their consent to be included</li><li>the work is original, except for extracts from copyrighted works used with permission from the copyright holders, and that it does not infringe upon any copyright, proprietary, or personal right of any third party</li><li>you have identified any potential conflicts of interest eg financial interest in products or processes described in the abstract, stock ownership, membership on an advisory board or board of directors, or other substantive relationships</li></ul>
                
                        </div>
                    </div>
                </div>

                <div class="accordion-item accordion-flush">
                    <h2 class="accordion-header" id="headingCopyright">
                        <button
                            data-bs-target="#collapseCopyright"
                            aria-controls="collapseCopyright"
                            class="accordion-button collapsed"
                            type="button"
                            data-bs-toggle="collapse"
                            aria-expanded="true"
                        >
                        <img src="../../public/img/Table-copyright.svg" height="32" class="me-3" />
                            Copyright
                        </button>
                    </h2>
                    <div
                        id="collapseCopyright"
                        aria-labelledby="headingCopyright"
                        class="accordion-collapse collapse"
                        data-bs-parent="#Help"
                    >
                        <div class="accordion-body fg-wp-black">
                            <p>In submitting an abstract you must confirm that:</p><ul><li>you accept responsibility for the accuracy of the submitted abstract and understand that the content cannot be modified or corrected after the abstract submission deadline and that it will be published exactly as submitted</li><li>all co-authors are aware of and agree to the content of the abstract and support the data presented</li><li>all presenters have agreed to participate in this session at congress, should the proposal be accepted for presentation, and take responsibility for their own travel, accommodation and registration costs</li><li>you accept responsibility as the contact person for all correspondence about the abstract and to share information with co-authors about its status</li><li>you have secured any copyright/permissions clearance required relating to any previous presentations, equipment or other material for inclusion in the World Physiotherapy Congress 2023, its recordings/proceedings or other congress publications</li><li>you consent to the session being recorded and made available by World Physiotherapy</li><li>for all studies involving human participants or animal subjects, permission has been obtained from the relevant ethics committee and properly informed consent given where appropriate</li><li>anyone who is identifiable in the abstract and presentation has given their consent to be included</li><li>the work is original, except for extracts from copyrighted works used with permission from the copyright holders, and that it does not infringe upon any copyright, proprietary, or personal right of any third party</li><li>you have identified any potential conflicts of interest eg financial interest in products or processes described in the abstract, stock ownership, membership on an advisory board or board of directors, or other substantive relationships</li></ul>
                
                        </div>
                    </div>
                </div>

                <div class="accordion-item accordion-flush">
                    <h2 class="accordion-header" id="headingContact">
                        <button
                            data-bs-target="#collapseContact"
                            aria-controls="collapseContact"
                            class="accordion-button collapsed"
                            type="button"
                            data-bs-toggle="collapse"
                            aria-expanded="true"
                        >
                        <img src="../../public/img/Table-geninfo.svg" height="32" class="me-3" />
                            Contact us
                        </button>
                    </h2>
                    <div
                        id="collapseContact"
                        aria-labelledby="headingContact"
                        class="accordion-collapse collapse"
                        data-bs-parent="#Help"
                    >
                        <div class="accordion-body fg-wp-black">
                            <p>If you have any questions or require support with your session, please contact <a href="mailto:abstract@world.physio">abstracts@world.physio.</a></p>
                            <p>If you have any general questions related to congress, please contact <a href="congress@world.physio">congress@world.physio.</a></p>                
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
</template>