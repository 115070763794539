<template>
    <!-- Comments -->

    <div id="comments" class="d-flex flex-column align-items-stretch flex-shrink-0 bg-white">
        <form class="d-flex flex-nowrap mb-1 px-3">
            <input type="text" class="form-control col-lg-6" placeholder="Add a comment..." aria-label="Add comments">
        </form>

        <div class="list-group list-group-flush border-bottom scrollarea">
            <a href="#" class="list-group-item list-group-item-action py-3 lh-tight">
                <div class="d-flex w-100 align-items-center justify-content-between mb-1">
                    <div class="fw-bold small">Dan Moore</div>
                    <div class="small fw-light">10:22</div>
                </div>
                <div class="mb-1 small">Comment text goes here.  This is a comment, it should wrap and continue on the next line.</div>
            </a>
            <a href="#" class="list-group-item list-group-item-action py-3 lh-tight">
                <div class="d-flex w-100 align-items-center justify-content-between mb-1">
                    <div class="fw-bold small">Rachel Moore</div>
                    <div class="small fw-light">10:01</div>
                </div>
                <div class="mb-1 small">Comment text goes here.  This is a comment, it should wrap and continue on the next line.</div>
            </a>
            <a href="#" class="list-group-item list-group-item-action py-3 lh-tight ps-5">
                <div class="d-flex w-100 align-items-center justify-content-between mb-1">
                    <div class="fw-bold small">Dan Moore</div>
                    <div class="small fw-light">10:01</div>
                </div>
                <div class="mb-1 small">Comment text goes here.  This is a comment, it should wrap and continue on the next line.</div>
            </a>
            <a href="#" class="list-group-item list-group-item-action py-3 lh-tight ps-5">
                <div class="d-flex w-100 align-items-center justify-content-between mb-1">
                    <div class="fw-bold small">Rachel Moore</div>
                    <div class="small fw-light">10:02</div>
                </div>
                <div class="mb-1 small">Comment text goes here.  This is a comment, it should wrap and continue on the next line.</div>
            </a>
            <a href="#" class="list-group-item list-group-item-action py-3 lh-tight">
                <div class="d-flex w-100 align-items-center justify-content-between mb-1">
                    <div class="fw-bold small">Dan Moore</div>
                    <div class="small fw-light">2 June 2021</div>
                </div>
                <div class="mb-1 small">Comment text goes here.  This is a comment, it should wrap and continue on the next line.</div>
            </a>
            <a href="#" class="list-group-item list-group-item-action py-3 lh-tight">
                <div class="d-flex w-100 align-items-center justify-content-between mb-1">
                    <div class="fw-bold small">Dan Moore</div>
                    <div class="small fw-light">1 June 2021</div>
                </div>
                <div class="mb-1 small">Comment text goes here.  This is a comment, it should wrap and continue on the next line.</div>
            </a>
            <a href="#" class="list-group-item list-group-item-action py-3 lh-tight">
                <div class="d-flex w-100 align-items-center justify-content-between mb-1">
                    <div class="fw-bold small">Dan Moore</div>
                    <div class="small fw-light">1 June 2021</div>
                </div>
                <div class="mb-1 small">Comment text goes here.  This is a comment, it should wrap and continue on the next line.</div>
            </a>
            <a href="#" class="list-group-item list-group-item-action py-3 lh-tight">
                <div class="d-flex w-100 align-items-center justify-content-between mb-1">
                    <div class="fw-bold small">Dan Moore</div>
                    <div class="small fw-light">1 June 2021</div>
                </div>
                <div class="mb-1 small">Comment text goes here.  This is a comment, it should wrap and continue on the next line.</div>
            </a>
            <a href="#" class="list-group-item list-group-item-action py-3 lh-tight">
                <div class="d-flex w-100 align-items-center justify-content-between mb-1">
                    <div class="fw-bold small">Dan Moore</div>
                    <div class="small fw-light">28 May 2021</div>
                </div>
                <div class="mb-1 small">Comment text goes here.  This is a comment, it should wrap and continue on the next line.</div>
            </a>
            <a href="#" class="list-group-item list-group-item-action py-3 lh-tight">
                <div class="d-flex w-100 align-items-center justify-content-between mb-1">
                    <div class="fw-bold small">Dan Moore</div>
                    <div class="small fw-light">27 May 2021</div>
                </div>
                <div class="mb-1 small">Comment text goes here.  This is a comment, it should wrap and continue on the next line.</div>
            </a>
            <a href="#" class="list-group-item list-group-item-action py-3 lh-tight">
                <div class="d-flex w-100 align-items-center justify-content-between mb-1">
                    <div class="fw-bold small">Dan Moore</div>
                    <div class="small fw-light">27 May 2021</div>
                </div>
                <div class="mb-1 small">Comment text goes here.  This is a comment, it should wrap and continue on the next line.</div>
            </a>
            <a href="#" class="list-group-item list-group-item-action py-3 lh-tight">
                <div class="d-flex w-100 align-items-center justify-content-between mb-1">
                    <div class="fw-bold small">Dan Moore</div>
                    <div class="small fw-light">27 May 2021</div>
                </div>
                <div class="mb-1 small">Comment text goes here.  This is a comment, it should wrap and continue on the next line.</div>
            </a>
        </div>
    </div>
</template>