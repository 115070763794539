<script setup>
    import { defineProps } from 'vue'
    import PersonCard from '@/components/PersonCard.vue'
    import store from '@/store'

    const props = defineProps({
        title : { type: String, default() { return "" }},
        people : { type: Object, default() { return null }},
        showFunction : { type: Boolean, default() { return true}},
        searchBar : { type: Boolean, default() { return false}}
    })

    const people = props.people ? props.people : store.state.people

</script>


<template>

    <header v-if="props.searchBar" id="filter-bar" class="shadow bg-wp-congress py-1">
        <div class="container pt-4">    
            <div class="d-flex flex-wrap justify-content-center">
                <form class="col-7 col-lg-6 pe-3">
                    <input type="search" class="form-control fs-6 border-0 wp-rounded shadow mb-3" placeholder="Search presenters/chairs..." aria-label="Search" />
                </form>
                <div class="col-5 col-lg-3">
                    <select class="form-select mb-4 bg-wp-congress fg-wp-dark border-2 border-primary wp-rounded" aria-label=".form-select">
                        <option selected>Sort by name</option>
                        <option value="1">Sort by country/territory</option>
                    </select>
                </div>
            </div>
        </div>
    </header>

    <!-- Main content -->
    <main id="content" class="bg-wp-congress-light pt-5">
        <div class="container">
            <h1 v-if="props.title" class="text-center mb-3">{{ props.title }}</h1>
            <div class="row row-cols-2 row-cols-md-3 row-cols-lg-4 row-cols-xl-5 justify-content-center g-5">

                <person-card v-for="item in people" v-bind:key="item.personId"
                    :personId="item.personId"
                    :personImg="item.personImg"
                    :personName="item.personName"
                    :personCountry="item.personCountry"
                    :personFunction="item.personFunction"
                    :personBio="item.personBio"
                    :showFunction="props.showFunction"
                />

            </div>
        </div>
    </main>
</template>


