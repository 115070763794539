<script setup>
import { ref } from 'vue'
import store from '@/store'

let countries = store.state.countries

const incomelevel = ref("select")

function onChange(event) {
    incomelevel.value = event.target.value
}

</script>



<template>     
    <!-- Select country -->
    <div class="row g-5 mt-0">
        <div class="col-12">
            <div class="wp-rounded shadow bg-wp-congress-light">
                <h3 class="bg-wp-congress wp-rounded-top text-center p-3 mb-0">Check the income level for the country/territory where you are resident</h3>
                <div class="wp-rounded-bottom p-4">

                    <select class="form-select form-select-lg" aria-label=".form-select-lg example" ref="incomelevel" @change="onChange($event)">
                        <option selected value="select" key="">Select your country/territory</option>
                        <option v-for="country in countries" :value="country.level" :key="country.country">{{country.country}}</option>
                    </select>

                    <div v-if="incomelevel.value !='select'" class="mt-5 text-center">
                    <p>Your country/territory income level, according to the World Bank, is</p>
                    <p class="fs-3 fg-wp-dark">{{incomelevel.value}}</p>
                    </div>

                </div>                 
            </div>
        </div>
    </div>
</template>