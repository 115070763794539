<script setup>
    import { defineProps } from 'vue'

    const props = defineProps({
        title: String,
        id: { type: String, default() { return "" }},
        type:{ type: String, default() { return "normal" }}
    })

    let headerClass = "bg-wp-dark"
    
    switch(props.type) {
        case "header1": 
            headerClass="bg-wp-dark-header1"; 
            break;
    }

</script>


<template>
    <header id="header" :class="headerClass">
        <div class="container py-0 g-0">
            <div class="row">
                <div class="col mt-4 text-center">
                    <h2 v-if="props.id" class="fg-wp-light fw-bold">{{props.id}}</h2>
                    <h1 class="mt-0 pb-1 px-2 fg-wp-light">{{ props.title }}</h1>
                    <p class="fs-5 px-2 opacity-90">
                        <slot name="summary"></slot>
                    </p>
                    <div class="mt-0 mb-4 ms-2 me-lg-auto pt-3">
                        <div class="d-flex flex-column flex-sm-row justify-content-center px-2">
                            <slot name="buttons"></slot>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </header>
</template>