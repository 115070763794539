<script setup>
import PageHeader from "@/components/PageHeader.vue"
import ActionButton from '@/components/ActionButton.vue'
import CountryLevels from '@/components/CountryLevels.vue'
import Accordion from "@/components/Accordion.vue"
import AccordionItem from "@/components/AccordionItem.vue"

const eb=1
const ad=1

</script>


<template>
    <PageHeader title="Registration fees">
        <template #summary>Registration opens: October 2022</template>
        <template #buttons>
            <ActionButton action="registerCongress" />
        </template>
    </PageHeader>

    <!-- Main content -->
    <main id="content">
        <div class="container py-5">

            <!-- Intro message -->
            <div class="row g-5">
                <div class="col-12">
                    <div class="bg-wp-congress-light fs-6 wp-rounded shadow h-100 p-4">
                        <!--<p class="fw-bold fg-wp-dark fs-5">Register now to connect with your colleagues from around the world.</p>-->

                        <ul>
                            <li>All registration fees are in US dollars.</li>
                            <li>World Physiotherapy is offering reduced fees for people from low and lower middle income countries and territories.</li>
                            <li>Registration fees exclude VAT which will be charged at 5%, as per the rate applicable in the United Arab Emirates.</li>
                            <li>Presenters notified after 15 December 2022 will be offered the early bird rate as a presenter rate.</li>
                            <li>For those unable to attend the in-person event, an online event will be held 23-25 June 2023. Recordings of presentations, along with posters, will be made available followed by live Q&A discussions with presenters from the in-person event.</li>
                        </ul>
                    </div>
                </div>
            </div>

            <!-- Yellow buttons-->
            <div class="row mt-0 g-5 align-items-center text-center">
                <div class="col-6">
                    <router-link to="/registration/categories" class="btn bg-wp-exercise shadow p-3 fs-4">Definitions of registration categories</router-link>
                </div>

                <div class="col-6">
                    <router-link to="/registration" class="btn bg-wp-exercise shadow p-3 fs-4">Find out why you should attend</router-link>
                </div>
            </div>


            <CountryLevels />


            <!-- Fees  -->
            <div id="fees" class="mt-5 text-center">
                <h2 class="fw-bold">Registration fees (in-person event)</h2>

                <div id="fees-high" class="mt-5 text-center">
                    <h2>High and upper middle income country/territory</h2>

                    <div class="row g-5 justify-content-center text-center">

                        <!-- High:  Early bird -->
                        <div v-if="eb" class="col-lg-4">
                            <div class="bg-wp-congress p-3">
                                <div class="fs-4 fw-bold">Early bird</div>
                                <div class="fs-5">(up to 15 December 2022)</div>
                            </div>        

                            <div class="row row-cols-2 g-0 text-center align-items-center bg-wp-congress-light">
                                <div class="col p-3 border-end border-5 border-white">
                                    <div class="fs-5 fw-bold fg-wp-dark">Full registration</div>
                                </div>
                                <div class="col p-3">
                                    <div class="fs-5 fw-bold fg-wp-dark">1 day</div>
                                </div>
                                <div class="col bg-wp-grey-light p-3 border-end border-5 border-white" >
                                    <div class="fs-6">Member</div>
                                    <div class="fs-6 text-nowrap">PT assistant</div>
                                    <div class="fs-1 fg-wp-black">$785</div>
                                </div>
                                <div class="col bg-wp-grey-light p-3">
                                    <div class="fs-6">Member</div>
                                    <div class="fs-6 text-nowrap">PT assistant</div>
                                    <div class="fs-1 fg-wp-black">$280</div>
                                </div>
                                <div class="col bg-wp-grey-light p-3 border-end border-5 border-white" >
                                    <div class="fs-6">Retired</div>
                                    <div class="fs-6">Entry level student</div>
                                    <div class="fs-1 fg-wp-black">$415</div>
                                </div>
                                <div class="col bg-wp-grey-light p-3">
                                    <div class="fs-6">Retired</div>
                                    <div class="fs-6">Entry level student</div>
                                    <div class="fs-1 fg-wp-black">$170</div>
                                </div>
                                <div class="col bg-wp-grey-light p-3 border-end border-5 border-white" >
                                    <div class="fs-6 text-nowrap">Non member</div>
                                    <div class="fs-1 fg-wp-black">$1,175</div>
                                </div>
                                <div class="col bg-wp-grey-light p-3">
                                    <div class="fs-6 text-nowrap">Non member</div>
                                    <div class="fs-1 fg-wp-black">$455</div>
                                </div>
                            </div>
                        </div>

                        <!-- High: Advance -->
                        <div v-if="ad" class="col-lg-4 ">
                            <div class="bg-wp-congress p-3">
                                <div class="fs-4 fw-bold">Advance</div>
                                <div class="fs-5">(up to 15 May 2023)</div>
                            </div>   

                            <div class="row row-cols-2 g-0 text-center align-items-center bg-wp-congress-light">
                                <div class="col p-3 border-end border-5 border-white">
                                    <div class="fs-5 fw-bold fg-wp-dark">Full registration</div>
                                </div>
                                <div class="col p-3">
                                    <div class="fs-5 fw-bold fg-wp-dark">1 day</div>
                                </div>
                                <div class="col bg-wp-grey-light p-3 border-end border-5 border-white" >
                                    <div class="fs-6">Member</div>
                                    <div class="fs-6 text-nowrap">PT assistant</div>
                                    <div class="fs-1 fg-wp-black">$880</div>
                                </div>
                                <div class="col bg-wp-grey-light p-3">
                                    <div class="fs-6">Member</div>
                                    <div class="fs-6 text-nowrap">PT assistant</div>
                                    <div class="fs-1 fg-wp-black">$345</div>
                                </div>
                                <div class="col bg-wp-grey-light p-3 border-end border-5 border-white" >
                                    <div class="fs-6">Retired</div>
                                    <div class="fs-6 ">Entry level student</div>
                                    <div class="fs-1 fg-wp-black">$460</div>
                                </div>
                                <div class="col bg-wp-grey-light p-3">
                                    <div class="fs-6">Retired</div>
                                    <div class="fs-6 ">Entry level student</div>
                                    <div class="fs-1 fg-wp-black">$180</div>
                                </div>
                                <div class="col bg-wp-grey-light p-3 border-end border-5 border-white" >
                                    <div class="fs-6 text-nowrap">Non member</div>
                                    <div class="fs-1 fg-wp-black">$1,295</div>
                                </div>
                                <div class="col bg-wp-grey-light p-3">
                                    <div class="fs-6 text-nowrap">Non member</div>
                                    <div class="fs-1 fg-wp-black">$485</div>
                                </div>
                            </div>
                        </div>

                        <!-- High: Onsite -->
                        <div class="col-lg-4">
                            <div class="bg-wp-congress p-3">
                                <div class="fs-4 fw-bold">Onsite</div>
                                <div class="fs-5">&nbsp;</div>
                            </div>   

                            <div class="row row-cols-2 g-0 text-center align-items-center bg-wp-congress-light">
                                <div class="col p-3 border-end border-5 border-white">
                                    <div class="fs-5 fw-bold fg-wp-dark">Full registration</div>
                                </div>
                                <div class="col p-3">
                                    <div class="fs-5 fw-bold fg-wp-dark">1 day</div>
                                </div>
                                <div class="col bg-wp-grey-light p-3 border-end border-5 border-white" >
                                    <div class="fs-6">Member</div>
                                    <div class="fs-6 text-nowrap">PT assistant</div>
                                    <div class="fs-1 fg-wp-black">$975</div>
                                </div>
                                <div class="col bg-wp-grey-light p-3">
                                    <div class="fs-6">Member</div>
                                    <div class="fs-6 text-nowrap">PT assistant</div>
                                    <div class="fs-1 fg-wp-black">$360</div>
                                </div>
                                <div class="col bg-wp-grey-light p-3 border-end border-5 border-white" >
                                    <div class="fs-6">Retired</div>
                                    <div class="fs-6 ">Entry level student</div>
                                    <div class="fs-1 fg-wp-black">$505</div>
                                </div>
                                <div class="col bg-wp-grey-light p-3">
                                    <div class="fs-6">Retired</div>
                                    <div class="fs-6">Entry level student</div>
                                    <div class="fs-1 fg-wp-black">$190</div>
                                </div>
                                <div class="col bg-wp-grey-light p-3 border-end border-5 border-white" >
                                    <div class="fs-6 text-nowrap">Non member</div>
                                    <div class="fs-1 fg-wp-black">$1,410</div>
                                </div>
                                <div class="col bg-wp-grey-light p-3">
                                    <div class="fs-6 text-nowrap">Non member</div>
                                    <div class="fs-1 fg-wp-black">$505</div>
                                </div>
                            </div>

                        </div>
                    </div>
                </div>


                <div id="fees-mid" class="mt-5 text-center">
                    <h2>Lower middle income country/territory</h2>

                    <div class="row g-5 justify-content-center text-center">

                        <!-- Mid:  Early bird -->
                        <div v-if="eb" class="col-lg-4">
                            <div class="bg-wp-congress p-3">
                                <div class="fs-4 fw-bold">Early bird</div>
                                <div class="fs-5">(up to 15 December 2022)</div>
                            </div>

                            <div class="row row-cols-2 g-0 text-center align-items-center bg-wp-congress-light">
                                <div class="col p-3 border-end border-5 border-white">
                                    <div class="fs-5 fw-bold fg-wp-dark">Full registration</div>
                                </div>
                                <div class="col p-3">
                                    <div class="fs-5 fw-bold fg-wp-dark">1 day</div>
                                </div>
                                <div class="col bg-wp-grey-light p-3 border-end border-5 border-white" >
                                    <div class="fs-6">Member</div>
                                    <div class="fs-6 text-nowrap">PT assistant</div>
                                    <div class="fs-1 fg-wp-black">$590</div>
                                </div>
                                <div class="col bg-wp-grey-light p-3">
                                    <div class="fs-6">Member</div>
                                    <div class="fs-6 text-nowrap">PT assistant</div>
                                    <div class="fs-1 fg-wp-black">$215</div>
                                </div>
                                <div class="col bg-wp-grey-light p-3 border-end border-5 border-white" >
                                    <div class="fs-6">Retired</div>
                                    <div class="fs-6 ">Entry level student</div>
                                    <div class="fs-1 fg-wp-black">$315</div>
                                </div>
                                <div class="col bg-wp-grey-light p-3">
                                    <div class="fs-6">Retired</div>
                                    <div class="fs-6 ">Entry level student</div>
                                    <div class="fs-1 fg-wp-black">$125</div>
                                </div>
                                <div class="col bg-wp-grey-light p-3 border-end border-5 border-white" >
                                    <div class="fs-6 text-nowrap">Non member</div>
                                    <div class="fs-1 fg-wp-black">$1,175</div>
                                </div>
                                <div class="col bg-wp-grey-light p-3">
                                    <div class="fs-6 text-nowrap">Non member</div>
                                    <div class="fs-1 fg-wp-black">$455</div>
                                </div>
                            </div>
                        </div>

                        <!-- Mid: Advance -->
                        <div v-if="ad" class="col-lg-4 ">
                            <div class="bg-wp-congress p-3">
                                <div class="fs-4 fw-bold">Advance</div>
                                <div class="fs-5">(up to 15 May 2023)</div>
                            </div>   

                            <div class="row row-cols-2 g-0 text-center align-items-center bg-wp-congress-light">
                                <div class="col p-3 border-end border-5 border-white">
                                    <div class="fs-5 fw-bold fg-wp-dark">Full registration</div>
                                </div>
                                <div class="col p-3">
                                    <div class="fs-5 fw-bold fg-wp-dark">1 day</div>
                                </div>
                                <div class="col bg-wp-grey-light p-3 border-end border-5 border-white" >
                                    <div class="fs-6">Member</div>
                                    <div class="fs-6 text-nowrap">PT assistant</div>
                                    <div class="fs-1 fg-wp-black">$660</div>
                                </div>
                                <div class="col bg-wp-grey-light p-3">
                                    <div class="fs-6">Member</div>
                                    <div class="fs-6 text-nowrap">PT assistant</div>
                                    <div class="fs-1 fg-wp-black">$240</div>
                                </div>
                                <div class="col bg-wp-grey-light p-3 border-end border-5 border-white" >
                                    <div class="fs-6">Retired</div>
                                    <div class="fs-6 ">Entry level student</div>
                                    <div class="fs-1 fg-wp-black">$345</div>
                                </div>
                                <div class="col bg-wp-grey-light p-3">
                                    <div class="fs-6">Retired</div>
                                    <div class="fs-6 ">Entry level student</div>
                                    <div class="fs-1 fg-wp-black">$135</div>
                                </div>
                                <div class="col bg-wp-grey-light p-3 border-end border-5 border-white" >
                                    <div class="fs-6 text-nowrap">Non member</div>
                                    <div class="fs-1 fg-wp-black">$1,295</div>
                                </div>
                                <div class="col bg-wp-grey-light p-3">
                                    <div class="fs-6 text-nowrap">Non member</div>
                                    <div class="fs-1 fg-wp-black">$485</div>
                                </div>
                            </div>
                        </div>

                        <!-- Mid: Onsite -->
                        <div class="col-lg-4">
                            <div class="bg-wp-congress p-3">
                                <div class="fs-4 fw-bold">Onsite</div>
                                <div class="fs-5">&nbsp;</div>
                            </div>   

                            <div class="row row-cols-2 g-0 text-center align-items-center bg-wp-congress-light">
                                <div class="col p-3 border-end border-5 border-white">
                                    <div class="fs-5 fw-bold fg-wp-dark">Full registration</div>
                                </div>
                                <div class="col p-3">
                                    <div class="fs-5 fw-bold fg-wp-dark">1 day</div>
                                </div>
                                <div class="col bg-wp-grey-light p-3 border-end border-5 border-white" >
                                    <div class="fs-6">Member</div>
                                    <div class="fs-6 text-nowrap">PT assistant</div>
                                    <div class="fs-1 fg-wp-black">$730</div>
                                </div>
                                <div class="col bg-wp-grey-light p-3">
                                    <div class="fs-6">Member</div>
                                    <div class="fs-6 text-nowrap">PT assistant</div>
                                    <div class="fs-1 fg-wp-black">$270</div>
                                </div>
                                <div class="col bg-wp-grey-light p-3 border-end border-5 border-white" >
                                    <div class="fs-6">Retired</div>
                                    <div class="fs-6 ">Entry level student</div>
                                    <div class="fs-1 fg-wp-black">$380</div>
                                </div>
                                <div class="col bg-wp-grey-light p-3">
                                    <div class="fs-6">Retired</div>
                                    <div class="fs-6">Entry level student</div>
                                    <div class="fs-1 fg-wp-black">$145</div>
                                </div>
                                <div class="col bg-wp-grey-light p-3 border-end border-5 border-white" >
                                    <div class="fs-6 text-nowrap">Non member</div>
                                    <div class="fs-1 fg-wp-black">$1,410</div>
                                </div>
                                <div class="col bg-wp-grey-light p-3">
                                    <div class="fs-6 text-nowrap">Non member</div>
                                    <div class="fs-1 fg-wp-black">$505</div>
                                </div>
                            </div>

                        </div>
                    </div>
                </div>


                <div id="fees-low" class="mt-5 text-center">

                <h2>Lower income country/territory</h2>

                <div class="row g-5 justify-content-center text-center">

                    <!-- Low:  Early bird -->
                    <div v-if="eb" class="col-lg-4">
                        <div class="bg-wp-congress p-3">
                                <div class="fs-4 fw-bold">Early bird</div>
                                <div class="fs-5">(up to 15 December 2022)</div>
                            </div>   

                        <div class="row row-cols-2 g-0 text-center align-items-center bg-wp-congress-light">
                            <div class="col p-3 border-end border-5 border-white">
                                <div class="fs-5 fw-bold fg-wp-dark">Full registration</div>
                            </div>
                            <div class="col p-3">
                                <div class="fs-5 fw-bold fg-wp-dark">1 day</div>
                            </div>
                            <div class="col bg-wp-grey-light p-3 border-end border-5 border-white" >
                                <div class="fs-6">Member</div>
                                <div class="fs-6 text-nowrap">PT assistant</div>
                                <div class="fs-1 fg-wp-black">$390</div>
                            </div>
                            <div class="col bg-wp-grey-light p-3">
                                <div class="fs-6">Member</div>
                                <div class="fs-6 text-nowrap">PT assistant</div>
                                <div class="fs-1 fg-wp-black">$140</div>
                            </div>
                            <div class="col bg-wp-grey-light p-3 border-end border-5 border-white" >
                                <div class="fs-6">Retired</div>
                                <div class="fs-6 ">Entry level student</div>
                                <div class="fs-1 fg-wp-black">$205</div>
                            </div>
                            <div class="col bg-wp-grey-light p-3">
                                <div class="fs-6">Retired</div>
                                <div class="fs-6 ">Entry level student</div>
                                <div class="fs-1 fg-wp-black">$85</div>
                            </div>
                            <div class="col bg-wp-grey-light p-3 border-end border-5 border-white" >
                                <div class="fs-6 text-nowrap">Non member</div>
                                <div class="fs-1 fg-wp-black">$1,175</div>
                            </div>
                            <div class="col bg-wp-grey-light p-3">
                                <div class="fs-6 text-nowrap">Non member</div>
                                <div class="fs-1 fg-wp-black">$455</div>
                            </div>
                        </div>
                    </div>

                    <!-- Low: Advance -->
                    <div v-if="ad" class="col-lg-4 ">
                        <div class="bg-wp-congress p-3">
                                <div class="fs-4 fw-bold">Advance</div>
                                <div class="fs-5">(up to 15 May 2023)</div>
                            </div>   

                        <div class="row row-cols-2 g-0 text-center align-items-center bg-wp-congress-light">
                            <div class="col p-3 border-end border-5 border-white">
                                <div class="fs-5 fw-bold fg-wp-dark">Full registration</div>
                            </div>
                            <div class="col p-3">
                                <div class="fs-5 fw-bold fg-wp-dark">1 day</div>
                            </div>
                            <div class="col bg-wp-grey-light p-3 border-end border-5 border-white" >
                                <div class="fs-6">Member</div>
                                <div class="fs-6 text-nowrap">PT assistant</div>
                                <div class="fs-1 fg-wp-black">$440</div>
                            </div>
                            <div class="col bg-wp-grey-light p-3">
                                <div class="fs-6">Member</div>
                                <div class="fs-6 text-nowrap">PT assistant</div>
                                <div class="fs-1 fg-wp-black">$155</div>
                            </div>
                            <div class="col bg-wp-grey-light p-3 border-end border-5 border-white" >
                                <div class="fs-6">Retired</div>
                                <div class="fs-6 ">Entry level student</div>
                                <div class="fs-1 fg-wp-black">$235</div>
                            </div>
                            <div class="col bg-wp-grey-light p-3">
                                <div class="fs-6">Retired</div>
                                <div class="fs-6 ">Entry level student</div>
                                <div class="fs-1 fg-wp-black">$90</div>
                            </div>
                            <div class="col bg-wp-grey-light p-3 border-end border-5 border-white" >
                                <div class="fs-6 text-nowrap">Non member</div>
                                <div class="fs-1 fg-wp-black">$1,295</div>
                            </div>
                            <div class="col bg-wp-grey-light p-3">
                                <div class="fs-6 text-nowrap">Non member</div>
                                <div class="fs-1 fg-wp-black">$485</div>
                            </div>
                        </div>
                    </div>

                    <!-- Low: Onsite -->
                    <div class="col-lg-4">
                        <div class="bg-wp-congress p-3">
                                <div class="fs-4 fw-bold">Onsite</div>
                                <div class="fs-5">&nbsp;</div>
                            </div>   

                        <div class="row row-cols-2 g-0 text-center align-items-center bg-wp-congress-light">
                            <div class="col p-3 border-end border-5 border-white">
                                <div class="fs-5 fw-bold fg-wp-dark">Full registration</div>
                            </div>
                            <div class="col p-3">
                                <div class="fs-5 fw-bold fg-wp-dark">1 day</div>
                            </div>
                            <div class="col bg-wp-grey-light p-3 border-end border-5 border-white" >
                                <div class="fs-6">Member</div>
                                <div class="fs-6 text-nowrap">PT assistant</div>
                                <div class="fs-1 fg-wp-black">$490</div>
                            </div>
                            <div class="col bg-wp-grey-light p-3">
                                <div class="fs-6">Member</div>
                                <div class="fs-6 text-nowrap">PT assistant</div>
                                <div class="fs-1 fg-wp-black">$180</div>
                            </div>
                            <div class="col bg-wp-grey-light p-3 border-end border-5 border-white" >
                                <div class="fs-6">Retired</div>
                                <div class="fs-6 ">Entry level student</div>
                                <div class="fs-1 fg-wp-black">$255</div>
                            </div>
                            <div class="col bg-wp-grey-light p-3">
                                <div class="fs-6">Retired</div>
                                <div class="fs-6">Entry level student</div>
                                <div class="fs-1 fg-wp-black">$95</div>
                            </div>
                            <div class="col bg-wp-grey-light p-3 border-end border-5 border-white" >
                                <div class="fs-6 text-nowrap">Non member</div>
                                <div class="fs-1 fg-wp-black">$1,410</div>
                            </div>
                            <div class="col bg-wp-grey-light p-3">
                                <div class="fs-6 text-nowrap">Non member</div>
                                <div class="fs-1 fg-wp-black">$505</div>
                            </div>
                        </div>

                    </div>
                </div>
            </div>
        </div>


            <!-- Whats included -->
            <div class="row g-5 mt-0">
                <div class="col-12">
                    <div class="wp-rounded shadow h-100 bg-wp-congress-light">
                        <h3 class="bg-wp-congress wp-rounded-top text-center p-3 mb-0">What’s included in your registration for the in-person event</h3>
                        <div class="wp-rounded-bottom p-3 fs-5">
                            <div class="row gx-0 gy-3">
                                <h4>
                                    The registration fee for the in-person event includes the following for all participants:
                                </h4>
                                <div class="d-flex align-items-center">
                                    <img height="50" src="../../public\img\reg\RegFees-1-sessions.svg" />
                                    <div class="ms-2">
                                        admission to all scientific sessions on days registered (over 130 across three days)
                                    </div>
                                </div>
                                <div class="d-flex align-items-center">
                                    <img height="50" src="../../public\img\reg\RegFees-2-abstracts.svg" />
                                    <div class="ms-2">
                                        access to all submitted abstracts and biographies of presenters online
                                    </div>
                                </div>
                                <div class="d-flex align-items-center">
                                    <img height="50" src="../../public\img\reg\RegFees-3-posters.svg" />
                                    <div class="ms-2">
                                        access to over 800 poster and ePoster presentations
                                    </div>
                                </div>
                                <div class="d-flex align-items-center">
                                    <img height="50" src="../../public\img\reg\RegFees-4-exhibition.svg" />
                                    <div class="ms-2">
                                        access to the largest international exhibition aimed at physiotherapists
                                    </div>
                                </div>
                                <div class="d-flex align-items-center">
                                    <img height="50" src="../../public\img\reg\RegFees-5-webApp.svg" />
                                    <div class="ms-2">
                                        mobile friendly congress web platform giving you essential information to get the most out of your attendance
                                    </div>
                                </div>
                                <div class="d-flex align-items-center">
                                    <img height="50" src="../../public\img\reg\RegFees-6-courses.svg" />
                                    <div class="ms-2">
                                        the opportunity to register to attend a <a href="https://wp2023.world.physio/#/programme/sessions/da7ad0c0-3ed1-4500-1346-0b0000000363">pre- or post-congress course</a> (additional charge)
                                    </div>
                                </div>
                                <div class="d-flex align-items-center">
                                    <img height="50" src="../../public\img\reg\RegFees-7-ondemand.svg" />
                                    <div class="ms-2">
                                        full access to the online event
                                    </div>
                                </div>

                                <h4 class="mt-5">
                                    Registration for full (three day) congress participants will also include:
                                </h4>
                                <div class="d-flex align-items-center">
                                    <img height="50" src="../../public\img\reg\RegFees-8-opening.svg" />
                                    <div class="ms-2">
                                        the opening session on day one
                                    </div>
                                </div>
                                <div class="d-flex align-items-center">
                                    <img height="50" src="../../public\img\reg\RegFees-9-welcome.svg" />
                                    <div class="ms-2">
                                        the networking welcome reception at the end of day one
                                    </div>
                                </div>
                                <div class="d-flex align-items-center">
                                    <img height="50" src="../../public\img\reg\RegFees-10-clinicalvisits.svg" />
                                    <div class="ms-2">
                                        the opportunity to attend one of the <a href="https://wp2023.world.physio/#/programme/sessions/da7ad0c0-3ed1-4500-1346-0b0000000313">clinical visits</a> to see professional practice in Dubai (additional charge)
                                    </div>
                                </div>

                                <p class="mt-5 fw-bold">
                                    Please note: fees do not include accommodation, teas/coffees, lunches or travel to/from Dubai.
                                </p>
                            </div>

                        </div>
                    </div>
                </div>
            </div>



            <!-- Yellow feature boxes -->
            <div class="row row-eq-height g-5 mt-0">
                <div class="col-lg-4">
                    <div class="wp-rounded shadow h-100 text-center position-relative bg-wp-white">
                        <div class="bg-wp-exercise wp-rounded-top p-3 mb-0">
                            <img height="128" src="../../public/img/reg/RegCategories.svg" />
                        </div>
                        <div class="text-center p-4">
                            <router-link to="/registration/categories" class="text-decoration-none fw-bold fs-5 stretched-link">View registration categories</router-link>
                        </div>
                    </div>
                </div>

                <div class="col-lg-4">
                    <div class="wp-rounded shadow h-100 text-center position-relative bg-wp-white">
                        <div class="bg-wp-exercise wp-rounded-top p-3 mb-0">
                            <img height="128" src="../../public/img/reg/RegFees-ondemand.svg" />
                        </div>
                        <div class="text-center p-4">
                            <router-link to="/registration/fees-online" class="text-decoration-none fw-bold fs-5 stretched-link">View registration fees for online event</router-link>
                        </div>
                    </div>
                </div>

                <div class="col-lg-4">
                    <div class="wp-rounded shadow h-100 text-center position-relative bg-wp-white">
                        <div class="bg-wp-exercise wp-rounded-top p-3 mb-0">
                            <img height="128" src="../../public/img/Download-bursary.svg" />
                        </div>
                        <div class="text-center p-4">
                            <router-link to="/registration/bursary" class="text-decoration-none fw-bold fs-5 stretched-link">Bursary programme</router-link>                        </div>
                    </div>
                </div>
            </div>


            <!-- FAQs -->
            <div class="mt-5">
                <Accordion parentId="registration">
                    <AccordionItem parentId="registration" itemId="cancellation">
                        <template #header>
                            <img height="50" class="me-3" src="../../public/img/reg/RegFees-OD-cancellation.svg" /> Cancellation policy
                        </template>
                        <template #body>
                            <p>Cancellations, substitutions and changes to registrations must be submitted in writing to the World Physiotherapy Congress 2023 registration team by email to <a href='mailto:worldphysio2023@kenes.com'>worldphysio2023@kenes.com</a></p>   
                            <p>Substitutions for registration will be accepted up until Friday 13 May 2023 (23:59 UTC).</p>
                            
                            <h4>Refunds</h4>
                            <p>Refunds for registration, including those resulting from changes to registrations, will be made as follows:</p>
                            <ul>
                                <li>In-person event registration: refund less $60 [$40 for lower middle income fee band; $40 for low income fee band] up to Friday 13 May 2023</li>
                                <li>Online event registration: refund less $20 [$15 for lower middle income fee band; $10 for low income fee band] up to Friday 13 May 2023</li>
                                <li>No refunds for cancellations made on or after Friday 13 May 2023 (23:59 UTC)</li>
                            </ul>
                        </template> 
                    </AccordionItem>
                    <AccordionItem parentId="registration" itemId="contact">
                        <template #header>
                            <img height="50" class="me-3" src="../../public/img/reg/RegFees-OD-contact.svg" /> Contact
                        </template>
                        <template #body>
                            <p>For questions or further information regarding registration, please email: <a href='mailto:worldphysio2023@kenes.com'>worldphysio2023@kenes.com</a></p>   
                        </template> 
                    </AccordionItem>
                    <AccordionItem parentId="registration" itemId="faq">
                        <template #header>
                            <img height="50" class="me-3" src="../../public/img/reg/RegFees-OD-FAQ.svg" /> FAQs
                        </template>
                        <template #body>
                            <p>View our <a href="#/app/generalinfo/d03ca532-0318-47c2-82c4-235ec9aec420">FAQs</a></p>   
                        </template> 
                    </AccordionItem>                        
                </Accordion>
            </div>



        </div> 
    </main>



</template>
