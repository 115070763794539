<style scoped>
.wp-person-card {
    text-align:center;
    position:relative;
}
.wp-person-card a {
    text-decoration: none;
}
.wp-person {
}
.wp-person img {
    border-radius:50%;
    transform: scale(1);
    -webkit-transition: .3s ease-in-out;
    transition: .3s ease-in-out;
    overflow:hidden;
}
.wp-person-caption {
    text-align:center;
    font-size: 1.2rem;
}
</style>

<template>
  <div class="wp-person-card">
      <div class="wp-person">
          <img class="img-fluid shadow" :src="personImg">
      </div>
      <div class="wp-person-caption mt-2">
          <div class="fg-wp-dark fs-4"><router-link :to="personLink" class="stretched-link">{{ personName }}</router-link></div>
          <div class="fg-wp-congress fs-6">{{ personCountry }}</div>
          <div v-if="showFunction" class="fs-6">{{ personFunction }}</div>
      </div>
  </div>
</template>

<script>
export default {
  props: {
    personId: Number,
    personImg: String,
    personName: String,
    personCountry: String,
    personFunction: String,
    personBio: String,
    showFunction: Boolean,
  },
  computed: {
    personLink: function() {
      return '/programme/person/'+this.personId;
    }
  }
}
</script>
