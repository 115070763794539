<script setup>
import store from '@/store'

const authenticated = store.state.user.authenticated;

</script>


<template>
    <!-- Main navigation -->
    <nav
        id="main-nav"
        class="sticky-lg-top navbar navbar-expand-md py-2 border-bottom bg-wp-light justify-content-between"
    >
        <div class="container">
            <!--<router-link to="/" title="WP2023 home page" class="navbar-brand"><img src="@/assets/logo.png" height="32"></router-link>-->
            <button
                class="navbar-toggler p-1 border-wp-grey"
                type="button"
                data-bs-toggle="collapse"
                data-bs-target="#navbar"
                aria-controls="navbar"
                aria-expanded="false"
                aria-label="Toggle navigation"
                border="1"
            >
                <span class="navbar-toggler-icon"></span>
            </button>

            <div class="navbar-collapse collapse" id="navbar">
                <ul class="navbar-nav me-3 pb-3 pb-sm-0 fg-wp-dark">
                    <li>
                        <router-link to="/" class="nav-link px-2" title="Home page">
                            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-house-door-fill mb-1" viewBox="0 0 16 16">
                                <path d="M6.5 14.5v-3.505c0-.245.25-.495.5-.495h2c.25 0 .5.25.5.5v3.5a.5.5 0 0 0 .5.5h4a.5.5 0 0 0 .5-.5v-7a.5.5 0 0 0-.146-.354L13 5.793V2.5a.5.5 0 0 0-.5-.5h-1a.5.5 0 0 0-.5.5v1.293L8.354 1.146a.5.5 0 0 0-.708 0l-6 6A.5.5 0 0 0 1.5 7.5v7a.5.5 0 0 0 .5.5h4a.5.5 0 0 0 .5-.5z"/>
                            </svg>
                        </router-link>
                    </li>
                    <li class="nav-item dropdown">
                        <a
                            id="registration-dropdown"
                            href="#"
                            class="nav-link dropdown-toggle px-2"
                            data-bs-toggle="dropdown"
                            aria-expanded="false"
                        >Registration</a>
                        <ul class="dropdown-menu" aria-labelledby="registration-dropdown">
                            <li>
                                <router-link to="/registration" class="dropdown-item">Registration</router-link>
                            </li>
                            <li>
                                <router-link to="/registration/fees" class="dropdown-item">Registration fees</router-link>
                            </li>
                            <li>
                                <router-link to="/registration/bursary" class="dropdown-item">Bursary programme</router-link>
                            </li>
                            <li>
                                <router-link to="/registration/accommodation" class="dropdown-item">Accommodation</router-link>
                            </li>                            
                            <li>
                                <router-link to="/registration/travel" class="dropdown-item">Travel</router-link>
                            </li>
                            <li>
                                <router-link to="/registration/visas" class="dropdown-item">Visas</router-link>
                            </li>
                            <li>
                                <router-link to="/registration/photo" class="dropdown-item">Photography exhibition</router-link>
                            </li>
                        </ul>
                    </li>
                    <li class="nav-item dropdown">
                        <a
                            id="programme-dropdown"
                            href="#"
                            class="nav-link dropdown-toggle px-2"
                            data-bs-toggle="dropdown"
                            aria-expanded="false"
                        >Programme</a>
                        <ul class="dropdown-menu" aria-labelledby="programme-dropdown">
                            <li>
                                <router-link
                                    to="/programme/abstracts"
                                    class="dropdown-item"
                                >Abstracts</router-link>
                            </li>
                            <li>
                                <router-link
                                    to="/programme/indaba"
                                    class="dropdown-item"
                                >Call for Indaba</router-link>
                            </li>
                            <li>
                                <router-link
                                    to="/programme/call-for-fs"
                                    class="dropdown-item"
                                >Call for FS</router-link>
                            </li>
                            <li>
                                <router-link
                                    to="/programme/sessions"
                                    class="dropdown-item"
                                >Sessions</router-link>
                            </li>
                            <li>
                                <router-link
                                    to="/programme/participants"
                                    class="dropdown-item"
                                >Presenters/chairs</router-link>
                            </li>
                            <li>
                                <router-link
                                    to="/programme/sessiontypes"
                                    class="dropdown-item"
                                >Session types</router-link>
                            </li>
                            <li>
                                <router-link
                                    to="/programme/outline"
                                    class="dropdown-item"
                                >Programme at a glance</router-link>
                            </li>
                            <li>
                                <router-link
                                    to="/programme/online"
                                    class="dropdown-item"
                                >Online event programme</router-link>
                            </li>

                            <li>
                                <router-link
                                    class="dropdown-item"
                                    to="/programme/CPC"
                                >Congress programme committee</router-link>
                            </li>
                        </ul>
                    </li>
                    <li class="nav-item">
                        <router-link to="/exhibition" class="nav-link px-2">Exhibition</router-link>
                    </li>
                    <li class="nav-item dropdown">
                        <a
                            id="programme-dropdown"
                            href="#"
                            class="nav-link dropdown-toggle px-2"
                            data-bs-toggle="dropdown"
                            aria-expanded="false"
                        >Media</a>
                        <ul class="dropdown-menu" aria-labelledby="programme-dropdown">
                            <li>
                                <router-link to="/media" class="dropdown-item">Media resources</router-link>
                            </li>
                            <li>
                                <router-link to="/media/registration" class="dropdown-item">Media registration</router-link>
                            </li>
                        </ul>
                    </li>

                    <li class="nav-item dropdown">
                        <a
                            id="about-dropdown"
                            href="#"
                            class="nav-link dropdown-toggle px-2"
                            data-bs-toggle="dropdown"
                            aria-expanded="false"
                        >About</a>
                        <ul class="dropdown-menu" aria-labelledby="about-dropdown">
                            <li>
                                <router-link
                                    class="dropdown-item"
                                    to="/about"
                                >About World Physiotherapy</router-link>
                            </li>
                            <li>
                                <hr class="dropdown-divider" />
                            </li>
                            <li>
                                <router-link class="dropdown-item" to="/visitor">Vistor information</router-link>
                            </li>
                            <li>
                                <hr class="dropdown-divider" />
                            </li>
                            <li>
                                <router-link class="dropdown-item" to="/volunteering">Volunteering</router-link>
                            </li>
                            <li>
                                <router-link class="dropdown-item" to="/timelines">Congress timelines</router-link>
                            </li>

                            <li>
                                <router-link class="dropdown-item" to="/FAQ">FAQs</router-link>
                            </li>

                        </ul>
                    </li>
                </ul>
            </div>

            <form action="/search" class="col col-md-3 ps-2">
                <input
                    type="search"
                    class="form-control py-1"
                    placeholder="Search all content..."
                    aria-label="Search"
                />
            </form>

            <!-- Show my congress if authenticated -->
            <ul v-if="authenticated" id="nav-personal" class="nav">
                <li class="nav-item dropdown">
                    <a
                        id="myaccount-dropdown"
                        class="dropdown-toggle py-1 ms-2 btn btn-outline position-relative bg-wp-dark fg-wp-light border-wp-grey"
                        role="button"
                        data-bs-toggle="dropdown"
                        aria-expanded="false"
                    >
                        <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="20"
                            height="20"
                            fill="currentColor"
                            class="bi bi-person-circle"
                            viewBox="0 0 16 16"
                        >
                            <path d="M11 6a3 3 0 1 1-6 0 3 3 0 0 1 6 0z" />
                            <path
                                fill-rule="evenodd"
                                d="M0 8a8 8 0 1 1 16 0A8 8 0 0 1 0 8zm8-7a7 7 0 0 0-5.468 11.37C3.242 11.226 4.805 10 8 10s4.757 1.225 5.468 2.37A7 7 0 0 0 8 1z"
                            />
                        </svg>
                        <span class="d-none d-sm-inline ps-1">My congress</span>
                        <span class="position-absolute top-0 start-100 translate-middle p-2 bg-danger border border-light rounded-circle">
                            <span class="visually-hidden">unread messages</span>
                        </span>
                    </a>
                    <ul
                        class="dropdown-menu dropdown-menu-end"
                        aria-labelledby="myaccount-dropdown"
                    >
                        <li>
                            <div class="inline-notification">
                                <router-link class="dropdown-item" to="/messages">
                                    Messages
                                    <span class="float-end badge rounded-pill bg-danger">
                                        12
                                    </span>
                                </router-link>
                            </div>
                        </li>
                        <li>
                            <router-link class="dropdown-item" to="/my/congress">
                                My congress
                                <span class="float-end badge rounded-pill bg-danger">
                                    1
                                </span>
                            </router-link>
                        </li>
                        <li>
                            <hr class="dropdown-divider" />
                        </li>
                        <li>
                            <router-link class="dropdown-item" to="/profile">My profile</router-link>
                        </li>
                        <li>
                            <router-link class="dropdown-item" to="/settings">My settings</router-link>
                        </li>
                        <li>
                            <hr class="dropdown-divider" />
                        </li>
                        <li>
                            <a class="dropdown-item" href="#">Logout</a>
                        </li>
                    </ul>
                </li>
            </ul>

            <!-- Show login if not authenticated -->
            <ul
                v-if="!authenticated"
                id="nav-personal"
                class="nav navbar-nav me-3 pb-3 pb-sm-0 fw-bold fg-wp-dark"
            >
                <li>
                    <router-link class="nav-link" to="/login">Login</router-link>
                </li>
            </ul>

            <!--
            <router-link to="/messages" class="ms-2 btn btn-outline-primary btn-sm position-relative">
                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-bell" viewBox="0 0 16 16">
                    <path d="M8 16a2 2 0 0 0 2-2H6a2 2 0 0 0 2 2zM8 1.918l-.797.161A4.002 4.002 0 0 0 4 6c0 .628-.134 2.197-.459 3.742-.16.767-.376 1.566-.663 2.258h10.244c-.287-.692-.502-1.49-.663-2.258C12.134 8.197 12 6.628 12 6a4.002 4.002 0 0 0-3.203-3.92L8 1.917zM14.22 12c.223.447.481.801.78 1H1c.299-.199.557-.553.78-1C2.68 10.2 3 6.88 3 6c0-2.42 1.72-4.44 4.005-4.901a1 1 0 1 1 1.99 0A5.002 5.002 0 0 1 13 6c0 .88.32 4.2 1.22 6z"/>
                </svg>
                <span class="position-absolute top-0 start-100 translate-middle p-2 bg-danger border border-light rounded-circle">
                    <span class="visually-hidden">unread messages</span>
                </span>
            </router-link>
            --> 
        </div>
    </nav>
</template>


