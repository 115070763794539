<template>
    <header id="header" class="bg-wp-dark">
        <div class="container pt-4 pb-2">
            <div class="d-flex flex-wrap justify-content-between">
                <div class="col-12">
                    <h1 class="mt-0 fg-wp-light">Messages</h1>
                    <p class=""></p>
                </div>
            </div>
        </div>
    </header>

    <!-- Main content -->
    <main id="content" class=" py-1">

              <!-- Notifications listing -->
      <section id="notifications" class="py-5">
          <div class="container">
              <div class="row row-cols-1 gy-5 gx-0">

                  <div class="card wp-card">
                      <div class="row g-0 shadow rounded bg-wp-light">
                          <div class="col-12 col-lg-8">
                              <div class="card-body" >
                                  <h3 class="card-title">Notification</h3>
                                  <p class="card-text">
                                  Notification text goes here
                                  </p>
                                  <a href="#" class="btn btn-sm btn-wp-congress">Read more</a>
                              </div>
                          </div>
                      </div>
                  </div>

                  <div class="card wp-card">
                      <div class="row g-0 shadow rounded bg-wp-light">
                          <div class="col-lg-8">
                              <div class="card-body" >
                                  <h3 class="card-title">Notification</h3>
                                  <p class="card-text">
                                  Notification text goes here
                                  </p>
                                  <a href="#" class="btn btn-sm btn-wp-congress">Read more</a>
                              </div>
                          </div>
                      </div>
                  </div>

                  <div class="card wp-card">
                      <div class="row g-0 shadow rounded bg-wp-light">
                          <div class="col-lg-8">
                              <div class="card-body" >
                                  <h3 class="card-title">Notification</h3>
                                  <p class="card-text">
                                  Notification text goes here
                                  </p>
                                  <a href="#" class="btn btn-sm btn-wp-congress">Read more</a>
                              </div>
                          </div>
                      </div>
                  </div>

              </div>
          </div>
      </section>

    </main>
</template>
