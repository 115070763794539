<script setup>
    import PersonStub from '@/components/PersonStub.vue'
    import { defineProps } from 'vue'
    import store from '@/store'

    const props = defineProps({
        id: Number,
        SessionId: String,
        Time: String,
        Type: String,
        Title: String,
        Abstract: String,
        Presenters: Object,
        Description: String,
        multiplePresentations: { type: Boolean, default() { return true }}
    })

    const presLink = "/programme/presentation/"+props.id.toString()

    console.log("Stub many pres = "+JSON.stringify(props.multiplePresentations))

    const presenterObjects = props.Presenters.map((pres) => {
        return store.state.people.find((p) => p.personId == pres)
    })

</script>

<template>
    <div class="card wp-card shadow position-relative">
        <div v-if="props.multiplePresentations" class="card-header d-flex flex-wrap justify-content-between bg-wp-dark">
            <div class="session-time">{{ props.Time }}</div>
            <div class="session-code">{{ props.id }}</div>
        </div>
        <div class="p-4">
            <div class="mb-4">
                <div class="fg-wp-dark fw-bold pb-2">{{ props.Title }}</div>

                <div class="row g-3 mb-2">
                    <person-stub v-for="item in presenterObjects" v-bind:key="item.personId"
                        :personId="item.personId"
                        :personImg="item.personImg"
                        :personName="item.personName"
                        :personCountry="item.personCountry"
                        class="col-12 col-sm-6" />
                </div>

            </div>

            <div v-if="props.multiplePresentations">
                <router-link class="button btn btn-outline-primary ms-2" :to="presLink" role="button"><i class="bi bi-file-earmark-play-fill" /> View presentation</router-link>
            </div>

            <div v-if="props.Description" class="pt-3">
                <div class="description" v-html="props.Description" />
            </div>

            <!-- Session abstract -->
            <div v-if="!props.multiplePresentations" id="abstract" class="py-5">
                <div class="outline ng-binding" ng-bind-html="item.outline" ng-if="item.outline ">                    
                    <p><b>MUSCULOSKELETAL DISORDERS – HOW SPECIFIC SHOULD TREATMENT BE?</b></p>
                    
                    <div class="information">
                        <span class="ng-binding"><u>D Christiansen</u><sup>1,2</sup>, <u>F Desmeules</u><sup>3,4</sup>, <u>JS Roy</u><sup>5,6</sup>, <u>G Sole</u><sup>7</sup>, <u>K McCreesh</u><sup>8,9</sup></span>
                    </div>
                    
                    <div class="information">
                        &nbsp;
                    </div>
                    
                    <div class="information">
                        <span class="ng-binding"><sup>1</sup>Regional Hospital West Jutland, Occupational Medicine - University Research Clinic, Herning, Denmark, <sup>2</sup>Aarhus University, Department of Clinical Medicine, Aarhus, Denmark, <sup>3</sup>University of Montréal, School of Rehabilitation, Faculty of Medicine, Montréal, Québec, Canada, <sup>4</sup>University of Montreal Affiliated Research Center, Maisonneuve-Rosemont Hospital Research Center, Montréal, Québec, Canada, <sup>5</sup>Faculty of Medicine, Laval University, Department of Rehabilitation,, Quebec City (QC), Canada, <sup>6</sup>Quebec Rehabilitation Institute, Centre for Interdisciplinary Research in Rehabilitation and Social Integration, Quebec City (QC), Canada, <sup>7</sup>University of Otago, Centre for Health, Activity and Rehabilitation, School of Physiotherapy, Box 56, Dunedin, 9054, New Zealand, <sup>8</sup>University of Limerick, School of Allied Health, Limerick, Ireland, <sup>9</sup>University of Limerick, Ageing Research Centre, Health Research Institute,, Limerick, Ireland</span>
                    </div>
                    
                    <div class="information">
                        &nbsp;
                    </div>
                    
                    <div class="information">
                        <span class="ng-binding"><b>Learning objectives:</b> </span>
                    </div>
                    
                    <ol><li class="information">
                            <span class="ng-binding">To discuss current evidence for the delivery of site- and disorder-specific interventions compared to general interventions for musculoskeletal disorders. </span>
                        </li>
                        <li class="information">
                            <span class="ng-binding">To gain knowledge on the available treatment options and modes of delivery for the most common musculoskeletal disorders. </span>
                        </li>
                        <li class="information">
                            <span class="ng-binding">To explore common mechanisms of physiotherapy interventions for patients with musculoskeletal disorders.</span>
                        </li>
                    </ol><div class="information">
                        <br><span class="ng-binding"><b><b>Description:&nbsp;</b></b>&nbsp;<strong>Musculoskeletal (MSK)</strong> disorders are a leading cause of impaired physical health and disability worldwide. Randomised trials support physiotherapy interventions, such as patient education, manual therapy and exercise, as first line treatment for a variety of MSK disorders (1). Most studies have been focused at specific regional pain presentations, such as low back, shoulder or knee pain. The clinical course of recovery, however, appears to be irrespective of the site of MSK pain and therefore similar principles may underlay treatment of these disorders (2).&nbsp;<br>
                        &nbsp;<br><strong>Exercise&nbsp;</strong>is consistently recommended for patients with MSK disorders; however the most effective types of exercises are still unclear (3). Many exercise types exist, including specific approaches such as motor control exercises, proprioceptive exercises, targeted resistance or gradual loading of specific muscles and their tendons. Evidence shows that these different therapeutic approaches are effective in terms of reduction of pain and disabilities. There is also mounting evidence for the use of more general approaches to treat MSK disorders (4). We will look more closely at the most recent published evidence comparing specific approaches to general exercise approaches such as general resistance training, cardiovascular training or to physical activities such as Yoga, Pilates or Tai-chi. &nbsp;&nbsp;<br>
                        &nbsp;<br>
                        Most MSK pain cannot be linked conclusively to a specific pathoanatomical driver (5). Furthermore,&nbsp;t<strong>he transition from acute to chronic MSK pain</strong>&nbsp;is, in most cases, not linked to the extent of the initial injury. The etiology of chronic MSK pain is likely multifactorial, and may include interaction of numerous factors. Several factors have been shown to explain the transition to chronic pain, such as psychosocial (psychological distress, depressive mood, pain catastrophisation, somatization, passive coping strategies) and lifestyle (smoking, stress, nutrition, activity level, sleep) factors (6). In fact, the strongest risk factors for developing chronic MSK pain are not related to the injury itself. We will discuss the hypothesis that specific interventions might not be needed for most cases, and that interventions built around global education (including lifestyle) and exercise to improve the patient's self-efficacy might be the key.<br>
                        &nbsp;<br><strong>Advice and education</strong>&nbsp;aims to improve patients' understanding of their MSK pain, comorbidities and management choices. Such education should enable shared decision-making and improve self-determination. Specific diagnostic information is usually sought by the patient. However, patient education focussing only on specific pathoanatomy and pathomechanics appears to be ineffective for prevention and management of MSK disorders (7). We will present recent advances that integrates such knowledge with pain biology (neurosciences) and behavioural concepts, as well as general lifestyle factors (8). All factors should be weaved into the patient's context, adapting to culturally diverse groups, and be compatible with their individual-specific health literacy. Patient education thus moves beyond disorder-specific information to encompass understanding of pain biology, behavioural approaches and general health.&nbsp;<br>
                        &nbsp;<br>
                        The increasing global burden of MSK disorders also requires physiotherapists to examine <strong>modes of intervention delivery</strong>&nbsp;in order to provide timely, effective and economically sustainable services. A growing body of research comparing the one-to-one model to other modes such as group, or telehealth-supported interventions suggests that these less individualized modes of delivery may provide equivalent outcomes to one-to-one treatment (9, 10). While one-to-one physiotherapy provides the best means of delivering individualized care, group or minimally supervised programmes may offer enhanced effects for self-efficacy and accessibility of services. We will examine the benefits and drawbacks of these different approaches, in the context of providing effective, equitable and patient-centered physiotherapy into the future.</span>
                    </div>
                    
                    <div class="information">
                        <br><span class="ng-binding"><b>Implications/conclusions:</b> The commonalities for the clinical course of recovery, risk for chronicity as well as effectiveness of physiotherapy interventions across MSK disorders of different body sites indicate that we should consider commonalities of interventions. Once red flags and indications for referral have been considered, general principles of interventions should be considered rather than basing these on site-specific pathoanatomic findings. While specific manual therapy, exercise and education might be needed, general principles for enhancing self-efficacy and self-management, and wider issues influencing the pain experiences and health should receive greater focus. Such focus may enhance physiotherapists' role and impact on decreasing the burden of MSK disorders.</span>
                    </div>
                    
                    <div class="information">
                        <br><strong>References:</strong>
                    </div>
                    
                    <div class="information">
                        1. Babatunde, O.O., et al., Effective treatment options for musculoskeletal pain in primary care: A systematic overview&nbsp;of current evidence. PloS one, 2017. 12(6):<br>
                        2. de Vos Andersen, N.B., et al., Clinical course and prognosis of musculoskeletal pain in patients referred for physiotherapy: does&nbsp;pain site matter? BMC musculoskeletal disorders, 2017. 18(1): p. 130-017-1487-3.<br>
                        3. Chou, R., et al., Diagnosis and treatment of low back pain: a joint clinical practice guideline. Ann Intern Med, 2007. 147: p. 478-91.<br>
                        4. Saragiotto, B.T., et al., Motor Control Exercise for Nonspecific Low Back Pain: A Cochrane Review. Spine, 2016. 41: p. 1284-95.<br>
                        5. Roy, J.S., et al., Beyond the Joint: The Role of Central Nervous System Reorganizations in Chronic Musculoskeletal Disorders.&nbsp;JOSPT, 2017. 47: p. 817-821.<br>
                        6. Pincus, T., et al., A systematic review of psychological factors as predictors of chronicity/disability in prospective cohorts of low&nbsp;back pain. Spine, 2002. 27: p. E109-E120.<br>
                        7. Ainpradub, K., et al., Effect of education on non-specific neck and low back pain: A meta-analysis of randomized controlled trials.&nbsp;Man Ther, 2016. 22: p. 31-41.<br>
                        8. Meehan, K.W et al Seven key themes in physical therapy advice for patients living with subacromial shoulder pain. A scoping&nbsp;review. JOSPT 2020. Accepted, 18th January.<br>
                        9. O'Keeffe, M., et al., Are group-based and individual physiotherapy exercise programmes equally effective for musculoskeletal&nbsp;conditions? A systematic review and meta-analysis. Br J Sports Med, 2017. 51: p. 126-132.<br>
                        10. Lee, A.C. and N. Harada, Telehealth as a means of health care delivery for physical therapist practice. Phys Ther, 2012. 92 : p.463-8.
                    </div>
                    
                    <div class="information">
                        &nbsp;
                    </div>
                    
                    <div class="information">
                        <span class="ng-binding"><b>Key-words:</b> 1. Musculoskeletal disorders 2. Patient education 3. Modes of intervention delivery</span>
                    </div>
                    
                    <div class="information">
                        <br><span class="ng-binding"><b>Funding acknowledgements:</b> </span>JS Roy is supported by salary award from the Canadian Institutes of Health Research.<br><br><span class="ng-binding">All authors, affiliations and abstracts have been published as submitted.</span>
                    </div>
                </div>
            </div>
        </div>

    </div>
</template>
