<script setup>
import PageHeader from "@/components/PageHeader.vue"

const timelines = [
    {id: 10, date: 'February 2022', item: 'Call for focused symposia opens'},
    {id: 20, date: 'April 2022', item: 'Call for focused symposia closes'},
    {id: 30, date: 'June 2022', item: 'Call for abstracts opens'},
    {id: 40, date: 'June 2022', item: 'Abstract mentoring programme opens'},
    {id: 50, date: 'July 2022', item: 'Focused symposia announced'},
    {id: 60, date: 'October 2022', item: 'Registration and housing opens'},
    {id: 70, date: 'September 2022', item: 'Call for abstracts closes'},
    {id: 80, date: 'September 2022', item: 'Abstract mentoring programe closes'},
    {id: 90, date: '15 December 2022', item: 'Early bird registration deadline'},
    {id: 95, date: 'January 2023', item: 'Abstract outcomes'},
    {id: 100, date: 'March 2023', item: 'Final programme announced'},
    {id: 102, date: '15 May 2023', item: 'Advance registration deadline (in-person event only)'},
    {id: 110, date: '2-4 June 2023', item: 'In-person Congress'},
    {id: 120, date: '16 June 2023', item: 'Advance registration deadline (online event only)'},
    {id: 150, date: '23-25 June 2023', item: 'Online event'},
]
</script>

<template>
    <PageHeader title="Key dates">
        <template #summary>Dates may be subject to change</template>
    </PageHeader>

    <!-- Main content -->
    <main id="content" class="bg-wp-congress-light py-1">
        <div class="container p-5">

            <div v-for="item in timelines" v-bind:key="item.id" class="row gx-5 fs-5 mb-3">
                <div class="col-md-4 fg-wp-dark text-center text-md-end">
                    {{ item.date }}
                </div>
                <div class="col-md-8 text-center text-md-start">
                    {{ item.item }}
                </div>
            </div>

        </div>
    </main>
</template>
