<script setup>
import SubmissionCard from '@/components/SubmissionCard.vue'
import store from '@/store'

const submissions = store.state.abstracts

const submissioncount = submissions.length

</script>


<template>
  <!-- Submission cards -->
  <div v-if="submissioncount>0" id="submissions" class="container pb-5">
    <h3 class="pt-5 pb-2">My submissions</h3>
    <div class="row row-cols-1 row-cols-lg-2 g-5 row-eq-height">
      <SubmissionCard
        v-for="item in submissions"
        v-bind:key="item.id"
        :ID="item.id"
        :Title="item.Title"
        :Type="item.Type"
        :Status="item.Status"
      />
    </div>
  </div>
</template>
