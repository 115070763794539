<script setup>
    import PageHeader from "@/components/PageHeader.vue"
    import SessionCardList from "@/components/SessionCardList.vue"
    import store from '@/store'
    import { useRoute } from 'vue-router'

    const route = useRoute()

    const people = store.state.people

    const personResults = people.filter((person) => person.personId == parseInt(route.params.id))

    const person = personResults[0]

</script>

<style scoped>
.wp-person-card {
    text-align:center;
    position:relative;
}
.wp-person-card a {
    text-decoration: none;
}
.wp-person {
    text-align:center
}
.wp-person img {
    border-radius:50%;
    transform: scale(1);
    -webkit-transition: .3s ease-in-out;
    transition: .3s ease-in-out;
}
.wp-person-caption {
    text-align:center;
    font-size: 1.2rem;
}
</style>



<template>
    <PageHeader :title="person.personName">
        <template #summary>
           <div class="fg-wp-exercise fs-5">{{ person.personCountry }}</div>
           <div class="fs-6">{{ person.personFunction }}</div>
        </template>
        <template #buttons>
            <!--<router-link to="#" class="btn btn-wp-light me-2 mb-2">Programme overview</router-link>-->
            <!--<router-link to="#" class="btn btn-outline-light me-2 mb-2">Presentations</router-link>-->
            <router-link to="/programme/participants" class="btn btn-outline-light me-2 mb-2">Presenters/Chairs</router-link>
            <router-link to="/programme/sessions" class="btn btn-outline-light me-2 mb-2">Sessions</router-link>
        </template>
    </PageHeader>

    <!-- Main content -->
    <main id="content" class="bg-wp-congress-light py-5 position-relative">
        <div class="container">
            <div class="row row-eq-height g-5">

                <div class="col-lg-3">
                    <div class="wp-person">
                        <img :src="person.personImg" class="img-fluid shadow" :alt="person.personName" :title="person.personName">
                    </div>
                </div>
                <div class="col-lg-9">
                    <div class="fs-6" v-html="person.personBio"></div>
                </div>

            </div>            

        </div>
    </main>

    <section id="sessions" class="">
        <SessionCardList title="Sessions" mysessions="true" />
    </section>

</template>