<script setup>
    import PresentationStub from '@/components/PresentationStub.vue'
    import { defineProps } from 'vue'
    import store from '@/store'

    const props = defineProps({
        session: Object,
    })

/*
    const chairResults = store.state.sessionPeople.filter((sessionPerson) => sessionPerson.sessionId == props.session.id && sessionPerson.role == 'Chair/Speaker')
    const chairs = chairResults.map((person) => {
        return store.state.people.find((p) => p.personId == person.personId)
    })

    const speakerResults = store.state.sessionPeople.filter((sessionPerson) => sessionPerson.sessionId == props.session.id && sessionPerson.role == 'Speaker')
    const speakers = speakerResults.map((person) => {
        return store.state.people.find((p) => p.personId == person.personId)
    })
*/

    const presentations = store.state.presentations.filter((pres) => pres.sessionId == props.session.id)

    const multiplePresentations = (presentations.length > 1)

</script>


<template>
    <presentation-stub v-for="item in presentations" v-bind:key="item.id"
        :id="item.id"
        :SessionId="item.SessionId"
        :Time="item.Time"
        :Type="item.Type"
        :Title="item.Title"
        :Abstract="item.Abstract"
        :Presenters="item.Presenters"
        :Description="item.Description"
        :multiplePresentations="multiplePresentations"
        class="mb-5" />

</template>