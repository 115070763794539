<template>
    <header id="header" class="bg-wp-dark">
        <div class="container pt-4 pb-0">
            <div class="d-flex flex-wrap justify-content-between">
                <div class="col-12">
                    <h1 class="mt-0 fg-wp-light">My settings</h1>
                    <p class=""></p>
                </div>
            </div>
        </div>
    </header>

    <!-- Main content -->
    <main id="content" class="bg-wp-congress-light py-1">

    </main>
</template>
