<script setup>
    import PersonStub from '@/components/PersonStub.vue'
    import { defineProps } from 'vue'
    import store from '@/store'

    const props = defineProps({
        session: Object,
    })

    const chairResults = store.state.sessionPeople.filter((sessionPerson) => sessionPerson.sessionId == props.session.id && sessionPerson.function == 'Chair')
    const chairs = chairResults.map((person) => {
        return store.state.people.find((p) => p.personId == person.personId)
    })

    const chairRole = chairResults[0].role + (chairResults.length > 1 ? "s" : "")

/*
    const speakerResults = store.state.sessionPeople.filter((sessionPerson) => sessionPerson.sessionId == props.session.id && sessionPerson.role == 'Speaker')
    const speakers = speakerResults.map((person) => {
        return store.state.people.find((p) => p.personId == person.personId)
    })
    */

</script>


<template>
    <div class="card wp-card shadow">
        <div class="card-header text-center bg-wp-dark d-flex justify-content-between">
            <div class="session-type">{{ props.session.SessionType }}</div>
            <div class="session-type fw-bold">{{ props.session.id }}</div>
        </div>
        <div class="p-4">
            <div class="d-flex align-items-center mb-2">
                <i class="bi bi-calendar-event me-1 fg-wp-dark" />
                <div class="text-nowrap">{{props.session.SessionDate}}</div>
            </div>
            <div class="d-flex align-items-center mb-2">
                <i class="bi bi-clock me-1 fg-wp-dark" />
                <div>{{props.session.SessionTime}}</div>
            </div>
            <div class="d-flex align-items-center mb-2">
                <i class="bi bi-geo-alt me-1 fg-wp-dark" />
                <div>{{props.session.SessionLocation}}</div>
            </div>
            <div class="d-flex align-items-center mb-2">
                <i class="bi bi-tag me-1 fg-wp-dark" />
                <div class="text-nowrap"><a href="#" class="text-decoration-none">{{ props.session.SessionTopic }}</a></div>
            </div>

            <div class="mt-4">
                <h5>{{ chairRole }}</h5>
                <person-stub v-for="item in chairs" v-bind:key="item.personId"
                    :personId="item.personId"
                    :personImg="item.personImg"
                    :personName="item.personName"
                    :personCountry="item.personCountry"
                    class="pb-2" />
            </div>

            <div class="mt-5">
                <routerLink to="/programme/sessions" class="btn btn-primary">View other sessions</routerLink>
            </div>

<!--
            <div class="mt-4">
                <h5>Speakers</h5>
                <person-stub v-for="item in speakers" v-bind:key="item.personId"
                    :personId="item.personId"
                    :personImg="item.personImg"
                    :personName="item.personName"
                    :personCountry="item.personCountry"
                    class="pb-2" />
            </div>
-->
        </div>

    </div>


</template>