<script setup>
    import PageHeader from "@/components/PageHeader.vue"
    import HelpFS from "@/components/HelpFS.vue"
    import ActionButton from '@/components/ActionButton.vue'
    //import SessionCardList from "@/components/SessionCardList.vue"   
    import store from '@/store'
    import { useRoute } from 'vue-router'

    const route = useRoute()

    const types = store.state.types
    const typeResults = types.filter((type) => type.id == route.params.type)
    const type = typeResults[0]

    const roles = store.state.roles
    const roleResults = roles.filter((role) => role.id == route.params.role)
    const role = roleResults[0]

    const pageTitle = type.name + " - " + role.name

    const show = (types = [], roles = []) => {
        if ((types.length==0 ||types.includes(type.id)) && (roles.length==0 || roles.includes(role.id))) return true
        return false
    }

    //const congress = store.state.congress
    //const uploadOption = (role.id=='Speaker' && ['FS','PL','PO','SEM'].includes(type.id))
    //const confWord = role.id=='Speaker' ? 'present' : 'chair'

</script>

<template>
    <PageHeader :title="pageTitle">
        <template #summary>Role overview</template>
        <template #buttons>
            <ActionButton action="mailingList" />
        </template>
    </PageHeader>

    <!-- Main content -->
    <main id="content" class="bg-wp-congress-light py-5 position-relative">
        <div class="container">

            <!-- Feature boxes -->
            <!--
            <div class="row row-cols-lg-3 row-cols-1 justify-content-center row-eq-height g-5">
                <div class="col">
                    <div v-if="congress.confirmation" class="wp-rounded shadow h-100 text-center position-relative bg-wp-white">
                        <div class="bg-wp-exercise wp-rounded-top p-3 mb-0">
                            <img height="128" src="../../public/img/Download-viewFS.svg" />
                        </div>
                        <div class="text-center p-4">
                            <a href="#" target="_blank" class="text-decoration-none fw-bold fs-5 stretched-link">Confirm your intention to {{confWord}}</a>
                        </div>
                    </div>
                    <div v-else class="wp-rounded shadow h-100 text-center position-relative bg-wp-white">
                        <div class="bg-wp-grey wp-rounded-top p-3 mb-0">
                            <img height="128" src="../../public/img/Download-viewFS.svg" />
                        </div>
                        <div class="text-center p-4">
                            <div class="fw-bold fs-5">Confirm your intention to {{confWord}}</div>
                            <div class="pt-2 fs-6">available in July 2022</div>
                        </div>
                    </div>
                </div>
                <div v-if="uploadOption" class="col">
                    <div v-if="congress.upload" class="wp-rounded shadow h-100 text-center position-relative bg-wp-white">
                        <div class="bg-wp-exercise wp-rounded-top p-3 mb-0">
                            <img height="128" src="../../public/img/Download-submissionguidelines.svg" />
                        </div>
                        <div class="text-center p-4">
                            <a href="#" class="text-decoration-none fw-bold fs-5 stretched-link" target="_blank">Upload your presentation</a>
                        </div>
                    </div>
                    <div v-else class="wp-rounded shadow h-100 text-center position-relative bg-wp-white">
                        <div class="bg-wp-grey wp-rounded-top p-3 mb-0">
                            <img height="128" src="../../public/img/Download-submissionguidelines.svg" />
                        </div>
                        <div class="text-center p-4">
                            <div class="fw-bold fs-5">Upload your presentation</div>
                            <div class="pt-2 fs-6">available in April 2023</div>
                        </div>
                    </div>
                </div>

                <div class="col">
                    <div v-if="congress.registration" class="wp-rounded shadow h-100 text-center position-relative bg-wp-white">
                        <div class="bg-wp-exercise wp-rounded-top p-3 mb-0">
                            <img height="128" src="../../public/img/Download-bursary.svg" />
                        </div>
                        <div class="text-center p-4">
                            <a href="#" target="_blank" class="text-decoration-none fw-bold fs-5 stretched-link">Register for congress</a>
                        </div>
                    </div>
                    <div v-else class="wp-rounded shadow h-100 text-center position-relative bg-wp-white">
                        <div class="bg-wp-grey wp-rounded-top p-3 mb-0">
                            <img height="128" src="../../public/img/Download-bursary.svg" />
                        </div>
                        <div class="text-center p-4">
                            <div class="fw-bold fs-5">Register for congress</div>
                            <div class="pt-2 fs-6">available in September 2022</div>
                        </div>
                    </div>
                </div>

            </div>
            -->


            <!-- Key information -->
            
            <div class="row row-eq-height g-5 ">
                <div class="col-lg-6">
                    <div class="wp-rounded shadow h-100 bg-wp-congress-light">
                        <h3 class="bg-wp-congress wp-rounded-top text-center p-3 mb-0">Key resources</h3>
                        <div class="wp-rounded-bottom p-3">
                            <div class="row gx-0 gy-3">
                                <div class="d-flex align-items-center">
                                    <img height="50" src="../../public/img/Format-introduction.svg" />
                                    <a class="ms-2" href="#">Formating guidelines for presentation</a>
                                </div>
                                <div class="d-flex align-items-center">
                                    <img height="50" src="../../public/img/Format-introduction.svg" />
                                    <a class="ms-2" href="#">Webinars/training</a>
                                </div>
                                <div class="d-flex align-items-center">
                                    <img height="50" src="../../public/img/Format-introduction.svg" />
                                    <a class="ms-2" href="#">On the day requirements</a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-lg-6">
                    <div class="wp-rounded shadow h-100 bg-wp-congress-light">
                        <h3 class="bg-wp-congress wp-rounded-top text-center p-3 mb-0">Key dates</h3>
                        <div class="wp-rounded-bottom p-3">
                            <div class="row gx-0 gy-3">
                                <div class="d-flex align-items-center">
                                    <img height="50" src="../../public/img/Format-introduction.svg" />
                                    <div class="ms-2">
                                        Notification of outcome
                                    </div>
                                    <div class="ms-auto text-nowrap">
                                        July 2022
                                    </div>
                                </div>
                                <div class="d-flex align-items-center">
                                    <img height="50" src="../../public/img/Format-QandAs.svg" />
                                    <div class="ms-2 me-auto">
                                        Registration opens
                                    </div>
                                    <div class="ms-auto text-nowrap">
                                        September 2022
                                    </div>
                                </div>
                                <div class="d-flex align-items-center">
                                    <img height="50" src="../../public/img/Format-summary.svg" />
                                    <div class="ms-2 me-auto">
                                        Presentation upload
                                    </div>
                                    <div class="ms-auto text-nowrap">
                                        April 2023
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            
            
            
            <!-- Webinars -->
            <!--
            <div class="wp-rounded shadow h-100 bg-white mt-5">
                <h3 class="bg-wp-congress wp-rounded-top text-center p-3 mb-0">Webinar series</h3>
                <div class="wp-rounded-bottom p-3">
                    <p>
                        Presenting at an international congress is a new experience for many people. 
                        We want our chairs, speakers and all participants to get the most from the experience and will be providing additional support through an interactive webinar series, 
                        links to downloadable resources, and signposting to useful videos. Further details will be added to this section when available
                    </p>
                    <p>
                        Here is the webinar schedule. You will need to register for each one that is relevant to you, using the specific link provided. Each webinar will last one hour, but we will allow for some overflow time in case people have any unanswered questions. 
                    </p>
                    <ol class="list-group list-group-numbered">
                        <li v-if="show([],['Chair'])" class="list-group-item d-flex justify-content-between align-items-center">
                            <div class="ms-2 me-auto">
                                <div class="fw-bold">Preparing to chair key sessions</div>
                                xx April 2023, 00:00 – 00:00 UTC
                            </div>
                            <a href="" class="btn btn-primary">Register now</a>
                        </li>
                        <li v-if="show([],['Chair'])" class="list-group-item d-flex justify-content-between align-items-center">
                            <div class="ms-2 me-auto">
                                <div class="fw-bold">Preparing to chair key sessions</div>
                                xx April 2023, 00:00 – 00:00 UTC
                            </div>
                            <a href="" class="btn btn-primary">Register now</a>
                        </li>
                        <li v-if="show([],['Speaker'])" class="list-group-item d-flex justify-content-between align-items-center">
                            <div class="ms-2 me-auto">
                                <div class="fw-bold">Preparing to present key sessions </div>
                                xx April 2023, 00:00 – 00:00 UTC
                            </div>
                            <a href="" class="btn btn-primary">Register now</a>
                        </li>
                        <li v-if="show([],['Speaker'])" class="list-group-item d-flex justify-content-between align-items-center">
                            <div class="ms-2 me-auto">
                                <div class="fw-bold">Preparing to present key sessions </div>
                                xx April 2023, 00:00 – 00:00 UTC
                            </div>
                            <a href="" class="btn btn-primary">Register now</a>
                        </li>
                    </ol>
                </div>
            </div>
            -->

            <!-- Session info -->
            <div class="wp-rounded shadow h-100 bg-congress-light mt-5">
                <h3 class="bg-wp-congress wp-rounded-top text-center p-3 mb-0">{{type.name}} session format</h3>
                <div class="wp-rounded-bottom p-3">
                    <p>
                        The chair, a recognised expert in the field, has the
                        responsibility to invite and lead a group of international
                        presenters through a series of evidence based and critically
                        reflective linked presentations, drawing out the relevance,
                        applicability, take-home message and potential challenges
                        for the audience. It is important to deliver a coherent session
                        with presenters working together thoughtfully. The aim of
                        the session should be to create an insightful and engaging
                        learning experience for clinicians, managers, educators,
                        researchers and/or policy makers (depending on the topic)
                        that ultimately informs, inspires, involves, and empowers
                        congress participants and allows time for discussion.
                    </p>
                    <p>
                        Each symposium will last
                        <b>60 minutes</b> and consists of:
                    </p>
                    <div class="row gx-0 gy-3">
                        <div class="d-flex align-items-center">
                            <img height="50" src="../../public/img/Format-introduction.svg" />
                            <div class="ms-2">
                                an <b>introduction</b> by the chair
                            </div>
                        </div>
                        <div class="d-flex align-items-center">
                            <img
                                height="50"
                                src="../../public/img/Format-presentations.svg"
                            />
                            <div class="ms-2">
                                a maximum of three 15-minute or four 10-minute
                                <b>presentations</b>
                            </div>
                        </div>
                        <div class="d-flex align-items-center">
                            <img height="50" src="../../public/img/Format-QandAs.svg" />
                            <div class="ms-2">
                                5-10 minutes of
                                <b>questions and answers</b> with the audience spread across the session
                            </div>
                        </div>
                        <div class="d-flex align-items-center">
                            <img height="50" src="../../public/img/Format-summary.svg" />
                            <div class="ms-2">
                                a 5-minute
                                <b>summary</b> by the chair highlighting the implications, applicability and relevant messages to take away.
                            </div>
                        </div>
                    </div>
                    <p class="pt-4">
                        <b class="fg-wp-dark">Informal meet the panel opportunity:</b> in addition to the
                        60-minute session, the congress programme committee
                        (CPC) are working on plans to schedule informal 'meet the
                        panel' sessions after each focused symposium, allowing
                        presenters and participants to continue conversations.
                        Details to be confirmed.
                    </p>
                </div>
            </div>

            <!-- Other info -->
            <div class="pt-5">
                 <HelpFS v-if="show(['FS'])" />
            </div>

        </div>
    </main>

    <!-- Sessions -->
    <!--<SessionCardList :title="`Related ${type.name}`" :type="type.id" />-->

</template>