

<template>
    <div class="ratio ratio-16x9 bg-wp-congress overflow-hidden wp-rounded wp-shadow">
        <iframe :src="src" :title="title" allowfullscreen class="wp-rounded"></iframe>
    </div>
</template>

<script setup>
import { defineProps } from 'vue'

const props = defineProps({
    code: String,
    title: String
})

const src = "https://www.youtube.com/embed/" + props.code + "?rel=0"

</script>



