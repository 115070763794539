import { createStore } from 'vuex'

export default createStore({
  state: {
    user: {
      authenticated: true,
      name: 'Dan Moore',
      email: 'dmoore@world.physio'
    },
    congress: {
      code: 'WP2023',
      name: 'World Physiotherapy Congress 2023',
      programmeDates: true,
      abstractsPublic: false,
      FSconfirmation: false,
      confirmation: false,
      PLupload: false,
      POupload: false,
      registration: false,
      showComments: false,
    },
    actions: {
      submitFS: {active: false, label: 'Submit proposal', to: '/my/symp/?token=eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJkYXRlIjoiMjAyMS0wMi0yNVQxNjo1MzoxNC4yMjhaIiwiaWQiOjUxMjgsImlhdCI6MTYxNDI3MTk5NH0.Vrb-1r9dywttPDOziamzLMOlyo9VsD9Xs_VirErzsWY'},
      submitAbstract: {active: false, label: 'Submit abstract', to: '/'},
      registerCongress: {active: true, label: 'Register', to: '/register'},
      register: {active: true, label: 'Register', to:'/registration' },
      registerExhibitor: {active: true, label: 'Register your interest to be an exhibitor', to: 'mailto:exhibition@world.physio', icon:'envelope-fill'},
      prospectus: {active: true, label: 'Download the prospectus', to: 'https://world.physio/sites/default/files/2022-07/WP2023-ExhibitionProspectus-v1.pdf', icon:'file-pdf-fill'},
      previousFS: {active: true, label: 'View focused symposia from our previous congress', to:'https://congress.physio/2021/programme/fs'},
      mailingList: {active: true, label: 'Join our mailing list', to:'https://mailchi.mp/world.physio/subscribe'},
      worldPhysio: {active: true, label: 'Visit world.physio website', to:'https://world.physio'},
      addToFavourites: {active: true, label: 'Add to favourites', to:'', icon:'heart-fill'},
      evaluate: {active: true, label: 'Evaluate', to:'', icon: 'clipboard-check'},
      translate: {active: true, label: 'Translate', to:'', icon:'translate'},
      exhibitors: {active: true, label: 'View partners/exhibitors', to:'/exhibition/exhibitors', icon:'list-task'},
      exhibition: {active: true, label: 'About exhibition', to:'/exhibition', icon:'info-circle'},
      exhibitorMap: {active: true, label: 'Exhibition map', to:'/exhibition/map', icon:'geo-fill'},
      bursary: {active: false, label: 'Bursary application', to:'https://world.physio'},
      mediaRegistration: {active: true, label: 'Media registration form', to: 'https://world.physio/congress/WP2023-MediaRegistrationForm.pdf', icon:'file-pdf-fill'},
      emirates: {active: true, label: 'Access Emirates website', to:'https://www.emirates.com'},
      photoSubmission: {active: true, label: 'Photo submission form', to: 'https://world.physio/sites/default/files/2022-10/WP2023-Photography-SubmissionForm.pdf', icon:'file-pdf-fill'},
      housingLink: {active: true, label: 'Congress accommodation', to:'https://hotels.kenes.com/congress/WP23'},
      volunteer: {active: true, label: 'Apply to volunteer', to:'https://app.initlive.com/JE/xn9zqxi8va590y'},
      indaba: {active: true, label: 'Submit proposal', to:'https://www.surveymonkey.com/r/GYYKPXK'},
      visa: {active: true, label: 'Visa application', to:'https://gulfcircletours.com/online-visa-application-world-physiotherapy-congress-2023/'},
    },
    favourites: {
      abstracts: [1,2],
      sessions: ['PL-1','PO-2'],
      presentations: ['PL-1234','PO-1234'],
    },
    mycongress: {
      abstracts: [1,2],
      sessions: ['PL-1','PO-2'],
      presentations: ['PL-1234','PO-1234'],
    },
    sessions: [
      {id: 'FS-01', SessionTitle: 'IDENTIFICATION OF SERIOUS PATHOLOGY: THE IMPORTANT ROLE OF PHYSIOTHERAPISTS', Type:'FS', SessionType: 'Focused sympsium', SessionDate: '2 June', SessionTime: '10:00-11:00', SessionLocation: 'Sheikh Rashid F+C+D', SessionChairs: 'Chair1, Chair2', SessionSpeakers: 'Speaker1, Speakers2', SessionTopic: 'Musculoskeletal: spine', SessionDesc: 'Focused symposia are the key sessions at congress. Each focused symposium is chaired by a recognised expert in the field, who leads a group of presenters, drawn from several of World Physiotherapy’s regions, through a series of linked, evidence-based and internationally relevant presentations. Question time with the audience explores the issues raised and the chair concludes with key take-home messages and implications for practice.<br><br>Each focused symposium will be followed by a 30-minute, informal meet the presenter session that allows participants to reach out to presenters and continue the discussion in a small group.'},
      {id: 'FS-02', SessionTitle: 'HOSPITAL REHABILITATION IN HUMANITARIAN SETTINGS - CHALLENGES AND TAILORED SOLUTIONS',  Type:'FS', SessionType: 'Focused sympsium', SessionDate: '2 June', SessionTime: '10:00-11:00', SessionLocation: 'Sheikh Rashid F+C+D', SessionChairs: 'Chair1, Chair2', SessionSpeakers: 'Speaker1, Speakers2'},
      {id: 'FS-03', SessionTitle: 'LEVERAGING COLLABORATIVE INTER-PROFESSIONAL EDUCATION STRATEGIES FOR CONTEXT SPECIFIC WORKFORCE DEVELOPMENT', Type:'FS', SessionType: 'Focused sympsium', SessionDate: '2 June', SessionTime: '10:00-11:00', SessionLocation: 'Sheikh Rashid F+C+D', SessionChairs: 'Chair1, Chair2', SessionSpeakers: 'Speaker1, Speakers2'},
      {id: 'FS-04', SessionTitle: 'PAEDIATRIC PHYSIOTHERAPY: PROMOTING HEALTH LITERACY, PHYSICAL ACTIVITY, AND PARTICIPATION',  Type:'FS', SessionType: 'Focused sympsium', SessionDate: '2 June', SessionTime: '10:00-11:00', SessionLocation: 'Sheikh Rashid F+C+D', SessionChairs: 'Chair1, Chair2', SessionSpeakers: 'Speaker1, Speakers2'},
      {id: 'FS-05', SessionTitle: 'BIOMARKERS ENABLING PRECISION NEURO-MUSCULOSKELETAL HEALTH REHABILITATION (BIOSPHERE) – CHANGING THE PARADIGM', Type:'FS', SessionType: 'Focused sympsium', SessionDate: '2 June', SessionTime: '10:00-11:00', SessionLocation: 'Sheikh Rashid F+C+D', SessionChairs: 'Chair1, Chair2', SessionSpeakers: 'Speaker1, Speakers2'},
      {id: 'FS-06', SessionTitle: 'TRANSFER OF PAIN NEUROSCIENCE EDUCATION INTERVENTIONS BY CULTURAL ADAPTATION RESEARCH',  Type:'FS', SessionType: 'Focused sympsium', SessionDate: '2 June', SessionTime: '10:00-11:00', SessionLocation: 'Sheikh Rashid F+C+D', SessionChairs: 'Chair1, Chair2', SessionSpeakers: 'Speaker1, Speakers2'},
      {id: 'FS-07', SessionTitle: 'IDENTIFICATION OF SERIOUS PATHOLOGY: THE IMPORTANT ROLE OF PHYSIOTHERAPISTS', Type:'FS', SessionType: 'Focused sympsium', SessionDate: '2 June', SessionTime: '10:00', SessionLocation: 'Sheikh Rashid F+C+D', SessionChairs: 'Chair1, Chair2', SessionSpeakers: 'Speaker1, Speakers2'},
      {id: 'FS-08', SessionTitle: 'IMPLEMENTING PLANETARY HEALTH, ENVIRONMENT AND SUSTAINABILITY IN INTERNATIONAL PHYSIOTHERAPY EDUCATION',  Type:'FS', SessionType: 'Focused sympsium', SessionDate: '2 June', SessionTime: '10:00-11:00', SessionLocation: 'Sheikh Rashid F+C+D', SessionChairs: 'Chair1, Chair2', SessionSpeakers: 'Speaker1, Speakers2'},
      {id: 'FS-09', SessionTitle: 'IDENTIFICATION OF SERIOUS PATHOLOGY: THE IMPORTANT ROLE OF PHYSIOTHERAPISTS', Type:'FS', SessionType: 'Focused sympsium', SessionDate: '2 June', SessionTime: '10:00-11:00', SessionLocation: 'Sheikh Rashid F+C+D', SessionChairs: 'Chair1, Chair2', SessionSpeakers: 'Speaker1, Speakers2'},
      {id: 'FS-10', SessionTitle: 'HOSPITAL REHABILITATION IN HUMANITARIAN SETTINGS - CHALLENGES AND TAILORED SOLUTIONS',  Type:'FS', SessionType: 'Focused sympsium', SessionDate: '2 June', SessionTime: '10:00-11:00', SessionLocation: 'Sheikh Rashid F+C+D', SessionChairs: 'Chair1, Chair2', SessionSpeakers: 'Speaker1, Speakers2'},
      {id: 'FS-11', SessionTitle: 'IDENTIFICATION OF SERIOUS PATHOLOGY: THE IMPORTANT ROLE OF PHYSIOTHERAPISTS', Type:'FS', SessionType: 'Focused sympsium', SessionDate: '2 June', SessionTime: '10:00-11:00', SessionLocation: 'Sheikh Rashid F+C+D', SessionChairs: 'Chair1, Chair2', SessionSpeakers: 'Speaker1, Speakers2'},
      {id: 'FS-12', SessionTitle: 'HOSPITAL REHABILITATION IN HUMANITARIAN SETTINGS - CHALLENGES AND TAILORED SOLUTIONS',  Type:'FS', SessionType: 'Focused sympsium', SessionDate: '2 June', SessionTime: '10:00-11:00', SessionLocation: 'Sheikh Rashid F+C+D', SessionChairs: 'Chair1, Chair2', SessionSpeakers: 'Speaker1, Speakers2'},
      {id: 'FS-13', SessionTitle: 'IDENTIFICATION OF SERIOUS PATHOLOGY: THE IMPORTANT ROLE OF PHYSIOTHERAPISTS', Type:'FS', SessionType: 'Focused sympsium', SessionDate: '2 June', SessionTime: '10:00-11:00', SessionLocation: 'Sheikh Rashid F+C+D', SessionChairs: 'Chair1, Chair2', SessionSpeakers: 'Speaker1, Speakers2'},
      {id: 'FS-14', SessionTitle: 'HOSPITAL REHABILITATION IN HUMANITARIAN SETTINGS - CHALLENGES AND TAILORED SOLUTIONS',  Type:'FS', SessionType: 'Focused sympsium', SessionDate: '2 June', SessionTime: '10:00-11:00', SessionLocation: 'Sheikh Rashid F+C+D', SessionChairs: 'Chair1, Chair2', SessionSpeakers: 'Speaker1, Speakers2'},
      {id: 'FS-15', SessionTitle: 'IDENTIFICATION OF SERIOUS PATHOLOGY: THE IMPORTANT ROLE OF PHYSIOTHERAPISTS', Type:'FS', SessionType: 'Focused sympsium', SessionDate: '2 June', SessionTime: '10:00-11:00', SessionLocation: 'Sheikh Rashid F+C+D', SessionChairs: 'Chair1, Chair2', SessionSpeakers: 'Speaker1, Speakers2'},
      {id: 'FS-16', SessionTitle: 'HOSPITAL REHABILITATION IN HUMANITARIAN SETTINGS - CHALLENGES AND TAILORED SOLUTIONS',  Type:'FS', SessionType: 'Focused sympsium', SessionDate: '2 June', SessionTime: '10:00-11:00', SessionLocation: 'Sheikh Rashid F+C+D', SessionChairs: 'Chair1, Chair2', SessionSpeakers: 'Speaker1, Speakers2'},
      {id: 'FS-17', SessionTitle: 'IDENTIFICATION OF SERIOUS PATHOLOGY: THE IMPORTANT ROLE OF PHYSIOTHERAPISTS', Type:'FS', SessionType: 'Focused sympsium', SessionDate: '2 June', SessionTime: '10:00-11:00', SessionLocation: 'Sheikh Rashid F+C+D', SessionChairs: 'Chair1, Chair2', SessionSpeakers: 'Speaker1, Speakers2'},
      {id: 'FS-18', SessionTitle: 'HOSPITAL REHABILITATION IN HUMANITARIAN SETTINGS - CHALLENGES AND TAILORED SOLUTIONS',  Type:'FS', SessionType: 'Focused sympsium', SessionDate: '2 June', SessionTime: '10:00-11:00', SessionLocation: 'Sheikh Rashid F+C+D', SessionChairs: 'Chair1, Chair2', SessionSpeakers: 'Speaker1, Speakers2'},
      {id: 'POS-20', SessionTitle: 'LEVERAGING COLLABORATIVE INTER-PROFESSIONAL EDUCATION STRATEGIES FOR CONTEXT SPECIFIC WORKFORCE DEVELOPMENT',  Type:'PO', SessionType: 'ePoster session', SessionDate: '2 June', SessionTime: '10:00-11:00', SessionLocation: 'Sheikh Rashid F+C+D', SessionChairs: 'Chair1, Chair2', SessionSpeakers: 'Speaker1, Speakers2'},
      {id: 'POS-10', SessionTitle: 'PAEDIATRIC PHYSIOTHERAPY: PROMOTING HEALTH LITERACY, PHYSICAL ACTIVITY, AND PARTICIPATION',  Type:'PO', SessionType: 'ePoster session', SessionDate: '3 June', SessionTime: '10:00-11:00', SessionLocation: 'Sheikh Rashid F+C+D', SessionChairs: 'Chair1, Chair2', SessionSpeakers: 'Speaker1, Speakers2'},
      {id: 'PLS-01', SessionTitle: 'Musculoskeletal: spine 1', Type:'PL', SessionType: 'Platform session', SessionDate: '3 June', SessionTime: '10:00-11:00', SessionLocation: 'Sheikh Rashid F+C+D', SessionChairs: 'Chair1, Chair2', SessionSpeakers: 'Speaker1, Speakers2', SessionTopic: 'Cardiorespiratory'},
      {id: 'PLS-02', SessionTitle: 'Platform session 2',  Type:'PL', SessionType: 'Platform session', SessionDate: '4 June', SessionTime: '10:00-11:00', SessionLocation: 'Sheikh Rashid F+C+D', SessionChairs: 'Chair1, Chair2', SessionSpeakers: 'Speaker1, Speakers2'},
      {id: 'PLS-03', SessionTitle: 'Platform session 3', Type:'PL', SessionType: 'Platform session', SessionDate: '4 June', SessionTime: '10:00-11:00', SessionLocation: 'Sheikh Rashid F+C+D', SessionChairs: 'Chair1, Chair2', SessionSpeakers: 'Speaker1, Speakers2'},
      {id: 'DS-01', SessionTitle: 'Discussion session 1',  Type:'DS', SessionType: 'Discussion session', SessionDate: '3 June', SessionTime: '10:00-11:00', SessionLocation: 'Sheikh Rashid F+C+D', SessionChairs: 'Chair1, Chair2', SessionSpeakers: 'Speaker1, Speakers2'},
      {id: 'DS-02', SessionTitle: 'Discussion session 2',  Type:'DS', SessionType: 'Discussion session', SessionDate: '2 June', SessionTime: '10:00-11:00', SessionLocation: 'Sheikh Rashid F+C+D', SessionChairs: 'Chair1, Chair2', SessionSpeakers: 'Speaker1, Speakers2'},
      {id: 'NWS-01', SessionTitle: 'Network session 1', Type:'NWS', SessionType: 'Networking session', SessionDate: '2 June', SessionTime: '11:00-12:00', SessionLocation: 'Dubai C+D', SessionDesc: 'Session description goes here', SessionChairs: 'Chair1, Chair2', SessionTopic: 'Neurology'},
    ],
    abstracts: [
      {id: '1023', Type: 'Focused symposium', Title: 'PHYSIOTHERAPEUTIC EFFECTS MULTIMODAL EXERCISE PROGRAM ON GAIT DISORDER PARKINSON’S DISEASE', Status: 'Draft'},
      {id: '103', Type: 'Focused symposium', Title: 'REHABILITATION FOR COVID 19 PATIENTS', Status: 'Submitted' },
      {id: '105', Type: 'Focused symposium', Title: 'REHABILITATION FOR COVID 19 PATIENTS', Status: 'Awaiting review' },
      {id: '108', Type: 'Focused symposium', Title: 'REHABILITATION FOR COVID 19 PATIENTS', Status: 'Rejected' },
      {id: '112', Type: 'Focused symposium', Title: 'REHABILITATION FOR COVID 19 PATIENTS', Status: 'Accepted' },
      {id: '126', Type: 'Focused symposium', Title: 'REHABILITATION FOR COVID 19 PATIENTS', Status: 'Withdrawn'},

      {id: '543', Type: 'Platform presentation', Title: 'EXAMPLE ABSTRACT TITLE', Status: 'Draft'},
      {id: '545', Type: 'Platform presentation', Title: 'EXAMPLE ABSTRACT TITLE', Status: 'Submitted'},
      {id: '145', Type: 'Platform presentation', Title: 'EXAMPLE ABSTRACT TITLE', Status: 'Awaiting review'},
      {id: '423', Type: 'Platform presentation', Title: 'EXAMPLE ABSTRACT TITLE', Status: 'Rejected'},      
      {id: '599', Type: 'Platform presentation', Title: 'EXAMPLE ABSTRACT TITLE', Status: 'Awaiting confirmation'},   
      {id: '687', Type: 'Platform presentation', Title: 'EXAMPLE ABSTRACT TITLE', Status: 'Confirmed'},   
      {id: '782', Type: 'Platform presentation', Title: 'EXAMPLE ABSTRACT TITLE', Status: 'Withdrawn'},
      {id: '894', Type: 'Platform presentation', Title: 'EXAMPLE ABSTRACT TITLE', Status: 'Awaiting presentation'},
      {id: '991', Type: 'Platform presentation', Title: 'EXAMPLE ABSTRACT TITLE', Status: 'Ready'},
      
      {id: '543', Type: 'Poster presentation', Title: 'EXAMPLE ABSTRACT TITLE', Status: 'Draft'},
      {id: '545', Type: 'Poster presentation', Title: 'EXAMPLE ABSTRACT TITLE', Status: 'Submitted'},
      {id: '145', Type: 'Poster presentation', Title: 'EXAMPLE ABSTRACT TITLE', Status: 'Awaiting review'},
      {id: '423', Type: 'Poster presentation', Title: 'EXAMPLE ABSTRACT TITLE', Status: 'Rejected'},      
      {id: '599', Type: 'Poster presentation', Title: 'EXAMPLE ABSTRACT TITLE', Status: 'Awaiting confirmation'},   
      {id: '687', Type: 'Poster presentation', Title: 'EXAMPLE ABSTRACT TITLE', Status: 'Confirmed'},   
      {id: '782', Type: 'Poster presentation', Title: 'EXAMPLE ABSTRACT TITLE', Status: 'Withdrawn'},
      {id: '894', Type: 'Poster presentation', Title: 'EXAMPLE ABSTRACT TITLE', Status: 'Awaiting presentation'},
      {id: '991', Type: 'Poster presentation', Title: 'EXAMPLE ABSTRACT TITLE', Status: 'Ready'},
      
      {id: '543', Type: 'ePoster presentation', Title: 'EXAMPLE ABSTRACT TITLE', Status: 'Draft'},
      {id: '545', Type: 'ePoster presentation', Title: 'EXAMPLE ABSTRACT TITLE', Status: 'Submitted'},
      {id: '145', Type: 'ePoster presentation', Title: 'EXAMPLE ABSTRACT TITLE', Status: 'Awaiting review'},
      {id: '423', Type: 'ePoster presentation', Title: 'EXAMPLE ABSTRACT TITLE', Status: 'Rejected'},      
      {id: '599', Type: 'ePoster presentation', Title: 'EXAMPLE ABSTRACT TITLE', Status: 'Awaiting confirmation'},   
      {id: '687', Type: 'ePoster presentation', Title: 'EXAMPLE ABSTRACT TITLE', Status: 'Confirmed'},   
      {id: '782', Type: 'ePoster presentation', Title: 'EXAMPLE ABSTRACT TITLE', Status: 'Withdrawn'},
      {id: '894', Type: 'ePoster presentation', Title: 'EXAMPLE ABSTRACT TITLE', Status: 'Awaiting presentation'},
      {id: '991', Type: 'ePoster presentation', Title: 'EXAMPLE ABSTRACT TITLE', Status: 'Ready'},  
          
    ],
    presentations: [
      {id: 'FS-01', sessionId: 'FS-01', Time: '', Type: 'Focused symposium', Title: 'IDENTIFICATION OF SERIOUS PATHOLOGY: THE IMPORTANT ROLE OF PHYSIOTHERAPISTS', Abstract: '1', Presenters: [99991,99992,99993,99994,99995], Description:'This is a presentation description'},
      {id: 'FS-02', sessionId: 'FS-02', Time: '', Type: 'Focused symposium', Title: 'ABSTRACT TITLE 1234', Abstract: '2', Presenters: [99992], Description:'This is a presentation description'},
      {id: 'PL-1235', sessionId: 'PLS-01', Time: '10:00', Type: 'Platform presentation', Title: 'ABSTRACT TITLE', Abstract: '2', Presenters: [99993]},
      {id: 'PL-0563', sessionId: 'PLS-01', Time: '10:10', Type: 'Platform presentation', Title: 'ABSTRACT TITLE', Abstract: '2', Presenters: [99994]},
      {id: 'PL-1002', sessionId: 'PLS-01', Time: '10:20', Type: 'Platform presentation', Title: 'IDENTIFICATION OF SERIOUS PATHOLOGY: THE IMPORTANT ROLE OF PHYSIOTHERAPISTS', Abstract: '2', Presenters: [99995]},
      {id: 'PL-0232', sessionId: 'PLS-01', Time: '10:30', Type: 'Platform presentation', Title: 'ABSTRACT TITLE 1234', Abstract: '2', Presenters: [99996]},
      {id: 'FS-02', sessionId: 'FS-02', Time: '10:00', Type: 'Focused symposium', Title: 'ABSTRACT TITLE 1234', Abstract: '2', Presenters: [99997]},
    ],
    people: [
      {
        personId: 99991,
        personName: 'Murat Dalkilinc',
        personImg: 'https://world.physio/sites/default/files/styles/our_team_profile_sc200x200/public/2022-02/Murat.png?h=2a479378&itok=iTQhJ3sq',
        personCountry: 'United Arab Emirates',
        personFunction: 'Congress programme committee member',
        personBio: 'Murat graduated as a physiotherapist in 1998 from Hacettepe University/Turkey. He obtained MSc and Ph.D. degrees from the same institution in 2001 and 2011, respectively.<br><br>He started his career as a sports physiotherapist in the basketball team and attended several national and international tournaments as a pitch-side physiotherapist. Since then, he has focused mainly on musculoskeletal and postoperative physiotherapy.<br><br>He acted as a secretary-general and vice president at the Turkish Physiotherapy Association between 2008/2014. Currently, he is a board member of Emirates Physiotherapy Society.<br><br>He contributed to translation of several key textbooks into Turkish and published numerous articles in peer-reviewed journals.<br><br>He is working as a clinical supervisor at UAE Armed Forces since 2013 and as a part-time lecturer at Istinye University, Institute of Health since 2021. He is also co-founder of Fizyodemi (Online Education Platform) and Korteks Klinik (Clinic). Murat is strongly interested in pain science, Mulligan concept of manual therapy, therapeutic exercises, and digital health applications.'
      },
      {
        personId: 99992,
        personName: 'Catherine Joy Escuadra',
        personImg: 'https://world.physio/sites/default/files/styles/our_team_profile_sc200x200/public/2021-07/Catherine%20Joy%20Escuadra_0.jpg?h=e10c9b28&itok=Yb3oVpl3',
        personCountry: 'Philippines',
        personFunction: 'Congress programme committee member',
        personBio: 'Catherine is a faculty member at the University of Santo Tomas, Manila, Philippines, with a Master’s in health profession education. She is also an editorial board member of the Philippine Journal of Allied Health Sciences and the member secretary of the ethics review committee. Her research interest and expertise on interprofessional education and collaboration, blended learning, evidence-based practice, older adults, and non-communicable diseases has led to her international research presentations, lectures, and publications. As part of the board of officers of the Philippine Physical Therapy Association, she has helped organise student and professional physiotherapy congresses in the Philippines and Asia region. Catherine served as a CPC member (2019), as an abstract reviewer and research presenter (2019, 2021) and moderator (2021) for previous World Physiotherapy congresses. She is currently taking a PhD in educational big data at Ewha Womans University, Seoul, Republic of Korea. Catherine is a member of the Philippine Physical Therapy Association.'
      },      {
        personId: 99993,
        personName: 'Jennifer Hale',
        personImg: 'https://world.physio/sites/default/files/styles/our_team_profile_sc200x200/public/2021-07/Jennifer%20Hale_0.JPG?h=616327c9&itok=Us_v7Jxd',
        personCountry: 'United States',
        personFunction: 'Congress programme committee member',
        personBio: "Jennifer is a clinical associate professor in the doctor of physical therapy program at Tufts University School of Medicine, and a board-certified specialist in neurologic physical therapy. She graduated from Boston University with a DPT and completed her residency training at TIRR Memorial Hermann. She teaches in the areas of neuroscience and neurologic rehabilitation. Jennifer was a visiting lecturer in the department of physical therapy & rehabilitation sciences at Qatar University, UAE, where she assisted with curriculum development for a new undergraduate physiotherapy programme. She consulted for World Physiotherapy on the TEAM project, a collaborative effort with UCP Wheels for Humanity and the Ukraine Association of Physical Therapy, that aimed to professionalise physiotherapy practice in Ukraine. She is currently a PhD candidate in epidemiology at the University of Texas School of Public Health. Her research interest is in the area of physical activity and Parkinson's disease in women. Jennifer is a member of the American Physical Therapy Association."
      },      {
        personId: 99994,
        personName: 'Natasha Hinwood',
        personImg: 'https://world.physio/sites/default/files/styles/our_team_profile_sc200x200/public/2021-07/Natasha%20Hinwood_0.jpg?h=0d3123e9&itok=uapTxM1x',
        personCountry: 'Ireland',
        personFunction: 'Congress programme committee member',
        personBio: 'Natasha was born in Zimbabwe, and now lives in Dublin, Ireland. She is a recent physiotherapy graduate from Trinity College, Dublin. As an active member of the Irish Society of Chartered Physiotherapists (ISCP), she participates in several interest groups, including the ISCP student council, ISCP conference committee, and works as the student council observer to the ISCP Board. Since 2019, Natasha has worked with the World Physiotherapy Future Network group as a regional facilitator for the Europe region. The World Physiotherapy Future Network is a group that focuses on establishing, growing, and maintaining links between student and recent graduate physiotherapists. Natasha helps achieve these aims through participation in group discussion with other students, work on social media and outreach programmes, and planning events aimed at students and recent graduates. Natasha participated both as a volunteer and a panellist speaker on the topic of education during the World Physiotherapy Congress 2021 online. Nathasha is a member of the Irish Society of Chartered Physiotherapists).'
      },      {
        personId: 99995,
        personName: 'Nathan Hutting',
        personImg: 'https://world.physio/sites/default/files/styles/our_team_profile_sc200x200/public/2021-07/Nathan%20Hutting_0.jpg?h=651d1e2e&itok=8NwNo7AQ',
        personCountry: 'Netherlands',
        personFunction: 'Congress programme committee member',
        personBio: "Nathan is an associate professor at the HAN University of Applied Sciences in the Netherlands. He also works as a physiotherapist and manual therapist in a private practice. A board member of the Dutch Association for Manual Therapy, he is also the member organisation delegate of the Netherlands to IFOMPT, and a member of the scientific college physical therapy of the Royal Dutch Society for Physical Therapy.<br><br>His current research topics include musculoskeletal conditions, chronic conditions, self-management, patient-centered care, shared decision making, manual therapy/musculoskeletal physiotherapy, occupational health, and work-focused healthcare.<br><br>Nathan has made many national and international presentations and chaired focused symposia at three World Physiotherapy congresses. He has published over 30 peer-reviewed articles and has been successful in obtaining more than 800.000 euro in research funding (as primary applicant or co-applicant). He is also an associate editor of BMC Musculoskeletal Disorders and a member of the International Advisory Board of Musculoskeletal Science and Practice. Nathan is a member of the Royal Dutch Society for Physiotherapy."
      },      {
        personId: 99996,
        personName: 'Luciana De Michelis',
        personImg: 'https://world.physio/sites/default/files/styles/our_team_profile_sc200x200/public/2021-07/Luciana%20Mendonca.jpeg?h=3b11f132&itok=y_IUJVCO',
        personCountry: 'Brazil',
        personFunction: 'Congress programme committee member',
        personBio: "Luciana is a professor in a federal university in Brazil and develops research in the field of sports physical therapy. She has participated in the last three IOC world conferences on injury and illness in sport with poster and workshop presentations. In 2021 she is due to present a symposium on sports injury prevention and harm reduction in the global south: Socioecological considerations for contextualised research, policy, and practice. She was involved in organisation of physical therapy services for the Rio 2016 Olympics and Paralympics Games. She was the first female president of the Brazilian Society of Sports Physical Therapy (SONAFE), in a country with many restrictions to women's participation in sport and politics. Since 2017, she has been an executive director of the World Physiotherapy subgroup International Federation of Sports Physical Therapy (IFSPT) and is now IFSPT's secretary and chair of the research committee. She is committed to enhancing the dissemination of sports physiotherapy good practice and knowledge globally and to increase equity in sports physiotherapy. Luciana is a member of the Physiotherapists' Association of Brazil)."
      },      {
        personId: 99997,
        personName: 'Witness Mudzi',
        personImg: 'https://world.physio/sites/default/files/styles/our_team_profile_sc200x200/public/2021-07/Witness%20Mudzi%20cropped.jpg?h=24459076&itok=2kN3wVSd',
        personCountry: 'South Africa',
        personFunction: 'Congress programme committee member',
        personBio: 'Witness is the director of the postgraduate school at the University of the Free State. He joined the University of the Free State from the University of the Witwatersrand where he worked for close to 14 years. He holds a PhD from the University of the Witwatersrand and has published more than 35 articles in peer-reviewed journals. He has supervised to completion more than 25 MSc and PhD students. Between 2016 and 2021, he was the deputy president and president of the South African Society of Physiotherapy. Witness has participated in and presented at World Physiotherapy congresses since 2007, and was a member of the international scientific committee for the congress in Cape Town in 2017. His research interests include: adult neurology with specific focus on stroke, head injuries and spinal cord injuries; paediatric neurology; higher education; public health. He has clinical experience in adult and paediatric neurology which spans over 25 years. Witness is a member of the South African Society of Physiotherapy.'
      },      {
        personId: 99998,
        personName: 'Anupa Pathak',
        personImg: 'https://world.physio/sites/default/files/styles/our_team_profile_sc200x200/public/2021-07/Anupa%20Pathak_0.jpg?h=391c89b5&itok=pD5aAEtF',
        personCountry: 'Nepal',
        personFunction: 'Congress programme committee member',
        personBio: "Anupa is a physiotherapist from Nepal. She will soon finish her PhD at the University of Otago, New Zealand. She has published 13 peer-reviewed papers. Her research expertise lies in chronic pain, outcome measurement, and mixed-methods designs. Her research interests include outcome measurement, implementation science, and health literacy.<br><br>She has presented at several international conferences, including the last three World Physiotherapy congresses (in Cape Town, Geneva, and 2021 virtual congress) in a variety of formats (focused symposium, discussion, state of the art, oral and poster presentations). Anupa hopes to bring her perspectives from Nepal, South Asia, and New Zealand to the congress in 2023 and learn from other members of the world physiotherapy community. Anupa is a member of the Nepal Physiotherapy Association."
      },      {
        personId: 99999,
        personName: 'César Sá',
        personImg: 'https://world.physio/sites/default/files/styles/our_team_profile_sc200x200/public/2021-07/C%C3%A9sar%20S%C3%A1_0.JPG?h=71976bb4&itok=XGipwBpx',
        personCountry: 'Portugal',
        personFunction: 'Congress programme committee member',
        personBio: "César is a clinical physiotherapist specialising in aquatic, paediatric and musculoskeletal physiotherapy. He works in an institution (CERCIMB) that supports babies, children, and adults with disabilities. He also works in a public swimming pool with older people and children with several diseases and disabilities, giving individual support and group classes, and works in private practice at Clube 18, where he gives clinical pilates classes, physiotherapy sessions and coordinates the clinic department.<br><br>César is the founder and president of the International Organisation of Aquatic Physical Therapists (IOAPT), a World Physiotherapy subgroup, and a board member of the Portuguese aquatic physiotherapy interest group (GIFA) of the Portuguese Physiotherapy Association. César is a member of the Portuguese Association of Physiotherapists.<br><br>He organised and has been a speaker on several courses, conferences and workshops on the subject of aquatic and paediatric physiotherapy, earning the title of best young investigator in two international congresses. César has presented at previous World Physiotherapy congresses (in Singapore in 2015), chaired networking sessions (in Cape Town in 2017, Geneva in 2019, and online in 2021).<br><br> He has also published a paper, Results of an Aquatic Exercise Program on Balance, Risk of Falls, Fear of Falling, and Quality of Live in Older Adults, in the Journal of Aquatic Physical Therapy."
      },      {
        personId: 99910,
        personName: 'Remla Shirma',
        personImg: 'https://world.physio/sites/default/files/styles/our_team_profile_sc200x200/public/2021-07/Remla%20Shirma_0.jpg?h=e479a0eb&itok=tm1MbmiG',
        personCountry: 'Tanzania',
        personFunction: 'Congress programme committee member',
        personBio: "Remla has 20 years of work experience in healthcare, both in the UK and in Tanzania. Her work experience as a physiotherapist in the UK includes working in the National Health Service, private hospitals and clinics, and in the voluntary sector. In Tanzania, she has healthcare experience in private and public sectors. Remla founded a healthcare company Physiocare (T) Ltd, which operates physiotherapy clinics and supplies rehabilitation products.<br><br>In Tanzania, Remla has worked closely with the government and international organisations within the field of health in advocating for better rehabilitation services. She is a member of the rehabilitation technical working group under the Ministry of Health in Tanzania. She is also a council member of the Medical Council - a professional medical regulatory authority. Remla is a member of the Association of Physiotherapists in Tanzania.<br><br>Remla has spoken at national and international conferences on issues relating to physiotherapy and rehabilitation, and is a strong activist for better service and the development of the physiotherapy profession in Tanzania."
      },      {
        personId: 99911,
        personName: 'Jackie Whittaker',
        personImg: 'https://world.physio/sites/default/files/styles/our_team_profile_sc200x200/public/2021-07/Jackie%20Whittaker.jpg?h=99d542f5&itok=mE8qwYDd',
        personCountry: 'Canada',
        personFunction: 'Congress programme committee chair',
        personBio: 'Jackie is an assistant professor in the department of physical therapy, University of British Columbia, and a research scientist at Arthritis Research Canada in Vancouver and Michael Smith Foundation for Health Research Scholar (Canada). She is recognised by the Canadian Physiotherapy Association (CPA) as a clinical specialist in musculoskeletal physiotherapy with a research focus on preventing youth sport injuries and their consequences, including inactivity, obesity and osteoarthritis. Her background encompasses 21 years of clinical practice, 20 years as a clinical educator, seven years of research training (PhD and post-doctoral fellowship) and six years leading clinical research as an assistant professor. During her career, Jackie has held numerous professional roles, including World Physiotherapy representative on the Organization for Economic Cooperation and Development Patient Reported Indicators Survey Working Group (Hip and Knee Replacement), a member of congress programme committee for World Physiotherapy congresses in 2019 and 2021, and an associate editor for the British Journal of Sports Medicine. Jackie is a member of the Canadian Physiotherapy Association.'
      },      {
        personId: 99912,
        personName: 'Misako Yokoyama',
        personImg: 'https://world.physio/sites/default/files/styles/our_team_profile_sc200x200/public/2021-07/Misako-Yokoyama.png?h=766efcb2&itok=kRDr_whR',
        personCountry: 'Japan',
        personFunction: 'Congress programme committee member',
        personBio: "Misako is a paediatric physiotherapist. She obtained her national qualification as a Japanese physiotherapist in 1986 and began working at Kitasato University Hospital. In 2006 she was transferred to the Faculty of Health Sciences at Kitasato University. She received a Master's degree in health medicine in 2008 and a PhD from Kitasato University Graduate School of Medicine in 2013. Her research focuses on respiratory physiotherapy and autonomic nervous system activity in children. She is also a managing director of the Japanese Physical Therapy Association and wants to build an intervention and service delivery model based on evidence and international standards. Misako also teaches students and medical professionals, lectures at national conferences, and oversees student writing. She has attended the World Physiotherapy congresses since 1999 and was the presenter of the International Organisation of Physical Therapists in Paediatrics (IOPTP) seminar in 2021. She is a member of the IOPTP research committee. Misako is a member of the Japanese Physical Therapy Association."
      },      {
        personId: 99913,
        personName: 'Tomer Yona',
        personImg: 'https://world.physio/sites/default/files/styles/our_team_profile_sc200x200/public/2021-07/Tomer%20Yona_0.jpg?h=c02f14d5&itok=HfRq6Me3',
        personCountry: 'Israel',
        personFunction: 'Congress programme committee member',
        personBio: "Tomer is a qualified physiotherapist, currently doing his PhD in biomedical engineering in the Technion - Israel Institute of Technology.<br><br>He is engaged in research focusing on biomechanics, ACL rehabilitation, education, and medical technologies. He presented some of his work at WCPT Congress 2019.<br><br>In recent years, he has taken an active part in organising the Israeli annual physiotherapy conference and many workshops and lectures with national and international speakers.<br><br>Tomer served his first term on the CPC for World Physiotherapy congress 2021 online. Tomer is a member of the Israeli Association of Physiotherapists."
      },
    ],
    industry: [
      { id: 1, name: "EGZO", level: "Platinum", image: "/img/industry/EGZO-300x300.png", stand: "A12", website: "https://world.physio", email: "info@world.physio", phone: "+44 1234 5678", street: "Unit 17, Empire Square", city: "London", zip: "SE1 4NA", country: "United Kingdom" },
      { id: 2, name: "Hocoma", level: "Platinum", image: "/img/industry/Hocoma-300x300.png", stand: "B1", website: "https://world.physio", email: "info@world.physio", phone: "+44 1234 5678", street: "Unit 17, Empire Square", city: "London", zip: "SE1 4NA", country: "United Kingdom"  },
      { id: 3, name: "Rehab Essentials", level: "Gold", image: "/img/industry/RehabEssentials-300x300.png", stand: "A5", website: "https://world.physio", email: "info@world.physio", phone: "+44 1234 5678", street: "Unit 17, Empire Square", city: "London", zip: "SE1 4NA", country: "United Kingdom"  },
      { id: 4, name: "World Physiotherapy", level: "Exhibitor", image: "/img/industry/WP-300x300.png", stand: "M1", website: "https://world.physio", email: "info@world.physio", phone: "+44 1234 5678", street: "Unit 17, Empire Square", city: "London", zip: "SE1 4NA", country: "United Kingdom"  },
      { id: 5, name: "Partner X", level: "Exhibitor", image: "/img/industry/Hocoma-300x300.png", stand: "A7", website: "https://world.physio", email: "info@world.physio", phone: "+44 1234 5678", street: "Unit 17, Empire Square", city: "London", zip: "SE1 4NA", country: "United Kingdom"  },
      { id: 6, name: "Company Y", level: "Exhibitor", image: "/img/industry/EGZO-300x300.png", stand: "M8", website: "https://world.physio", email: "info@world.physio", phone: "+44 1234 5678", street: "Unit 17, Empire Square", city: "London", zip: "SE1 4NA", country: "United Kingdom"  },
      { id: 7, name: "A partner with a very very very long name indeed", level: "Exhibitor", image: "/img/industry/Hocoma-300x300.png", stand: "A19", website: "https://world.physio", email: "info@world.physio", phone: "+44 1234 5678", street: "Unit 17, Empire Square", city: "London", zip: "SE1 4NA", country: "United Kingdom"  },
      { id: 8, name: "Partner Z", level: "Exhibitor", image: "/img/industry/RehabEssentials-300x300.png" },
      { id: 9, name: "Partner 9", level: "Exhibitor", image: "/img/industry/Hocoma-300x300.png" },
      { id: 10, name: "Partner 10", level: "Exhibitor", image: "/img/industry/RehabEssentials-300x300.png" },
      { id: 11, name: "Partner 11", level: "Exhibitor", image: "/img/industry/Hocoma-300x300.png" },
      { id: 12, name: "Partner 12", level: "Exhibitor", image: "/img/industry/EGZO-300x300.png" },
      { id: 13, name: "Partner 13", level: "Exhibitor", image: "/img/industry/Hocoma-300x300.png" },
    ],
    types: [
      {id:'FS',name:'Focused symposia (FS)',presOpen:false,presMsg:'Opens January 2023',confOpen:false,confMsg:'Opens July 2022'},
      {id:'PL',name:'Classic platform abstract presentations (PL)',presOpen:false,presMsg:'Opens January 2023',confOpen:false,confMsg:'Opens July 2022'},
      {id:'DS',name:'Discussion session (DS)',presOpen:false,presMsg:'Opens January 2023',confOpen:false,confMsg:'Opens July 2022'},
    ],
    roles: [
      {id:'Chair',name:'Chair'},
      {id:'Speaker',name:'Speaker'},
    ],
    sessionPeople: [
      {sessionId: 'FS-01', personId: 99992, function: 'Chair', role: 'Chair/Presenter' },
      {sessionId: 'FS-01', personId: 99993, function: 'Speaker', role: 'Speaker' },
      {sessionId: 'FS-01', personId: 99994, function: 'Speaker', role: 'Speaker' },
      {sessionId: 'FS-01', personId: 99995, function: 'Speaker', role: 'Speaker' },
      {sessionId: 'FS-02', personId: 99992, function: 'Chair', role: 'Chair/Presenter' },
      {sessionId: 'FS-02', personId: 99999, function: 'Speaker', role: 'Speaker' },
      {sessionId: 'FS-02', personId: 99998, function: 'Speaker', role: 'Speaker' },
      {sessionId: 'PLS-01', personId: 99998, function: 'Chair', role: 'Chair' },
      {sessionId: 'PLS-01', personId: 99999, function: 'Chair', role: 'Chair' },
      {sessionId: 'NWS-01', personId: 99998, function: 'Chair', role: 'Chair' },
      {sessionId: 'NWS-01', personId: 99999, function: 'Chair', role: 'Chair' },

    ],
    countries: [
      { country:"Afghanistan", level:"Low income"},
      { country:"Albania", level:"High and upper middle income"},
      { country:"Algeria", level:"Lower middle income"},
      { country:"Andorra", level:"High and upper middle income"},
      { country:"Angola", level:"Lower middle income"},
      { country:"Antigua and Barbuda", level:"High and upper middle income"},
      { country:"Argentina", level:"High and upper middle income"},
      { country:"Armenia", level:"High and upper middle income"},
      { country:"Australia", level:"High and upper middle income"},
      { country:"Austria", level:"High and upper middle income"},
      { country:"Azerbaijan", level:"High and upper middle income"},
      { country:"Bahamas", level:"High and upper middle income"},
      { country:"Bahrain", level:"High and upper middle income"},
      { country:"Bangladesh", level:"Lower middle income"},
      { country:"Barbados", level:"High and upper middle income"},
      { country:"Belarus", level:"High and upper middle income"},
      { country:"Belgium", level:"High and upper middle income"},
      { country:"Belize", level:"High and upper middle income"},
      { country:"Benin", level:"Lower middle income"},
      { country:"Bermuda", level:"High and upper middle income"},
      { country:"Bhutan", level:"Lower middle income"},
      { country:"Bolivia", level:"Lower middle income"},
      { country:"Bosnia and Herzegovina", level:"High and upper middle income"},
      { country:"Botswana", level:"High and upper middle income"},
      { country:"Brazil", level:"High and upper middle income"},
      { country:"Brunei", level:"High and upper middle income"},
      { country:"Bulgaria", level:"High and upper middle income"},
      { country:"Burkina Faso", level:"Low income"},
      { country:"Myanmar", level:"Lower middle income"},
      { country:"Burundi", level:"Low income"},
      { country:"Cambodia", level:"Lower middle income"},
      { country:"Cameroon", level:"Lower middle income"},
      { country:"Canada", level:"High and upper middle income"},
      { country:"Central African Republic", level:"Low income"},
      { country:"Chad", level:"Low income"},
      { country:"Chile", level:"High and upper middle income"},
      { country:"China", level:"High and upper middle income"},
      { country:"Colombia", level:"High and upper middle income"},
      { country:"Comoros", level:"Lower middle income"},
      { country:"Congo", level:"Lower middle income"},
      { country:"Congo (Democratic Republic)", level:"Low income"},
      { country:"Costa Rica", level:"High and upper middle income"},
      { country:"Ivory Coast", level:"Lower middle income"},
      { country:"Croatia", level:"High and upper middle income"},
      { country:"Cuba", level:"High and upper middle income"},
      { country:"Curaçao", level:"High and upper middle income"},
      { country:"Cyprus", level:"High and upper middle income"},
      { country:"Czech Republic", level:"High and upper middle income"},
      { country:"Denmark", level:"High and upper middle income"},
      { country:"Djibouti", level:"Lower middle income"},
      { country:"Dominica", level:"High and upper middle income"},
      { country:"Dominican Republic", level:"High and upper middle income"},
      { country:"East Timor", level:"Lower middle income"},
      { country:"Ecuador", level:"High and upper middle income"},
      { country:"Egypt", level:"Lower middle income"},
      { country:"El Salvador", level:"Lower middle income"},
      { country:"Equatorial Guinea", level:"High and upper middle income"},
      { country:"Eritrea", level:"Low income"},
      { country:"Estonia", level:"High and upper middle income"},
      { country:"Ethiopia", level:"Low income"},
      { country:"Fiji", level:"High and upper middle income"},
      { country:"Finland", level:"High and upper middle income"},
      { country:"France", level:"High and upper middle income"},
      { country:"Gabon", level:"High and upper middle income"},
      { country:"Gambia", level:"Low income"},
      { country:"Georgia", level:"High and upper middle income"},
      { country:"Germany", level:"High and upper middle income"},
      { country:"Ghana", level:"Lower middle income"},
      { country:"Greece", level:"High and upper middle income"},
      { country:"Grenada", level:"High and upper middle income"},
      { country:"Guatemala", level:"High and upper middle income"},
      { country:"Guinea", level:"Low income"},
      { country:"Guinea-Bissau", level:"Low income"},
      { country:"Guyana", level:"High and upper middle income"},
      { country:"Haiti", level:"Lower middle income"},
      { country:"Honduras", level:"Lower middle income"},
      { country:"Hong Kong", level:"High and upper middle income"},
      { country:"Hungary", level:"High and upper middle income"},
      { country:"Iceland", level:"High and upper middle income"},
      { country:"India", level:"Lower middle income"},
      { country:"Indonesia", level:"Lower middle income"},
      { country:"Iran", level:"Lower middle income"},
      { country:"Iraq", level:"High and upper middle income"},
      { country:"Ireland", level:"High and upper middle income"},
      { country:"Israel", level:"High and upper middle income"},
      { country:"Italy", level:"High and upper middle income"},
      { country:"Jamaica", level:"High and upper middle income"},
      { country:"Japan", level:"High and upper middle income"},
      { country:"Jordan", level:"High and upper middle income"},
      { country:"Kazakhstan", level:"High and upper middle income"},
      { country:"Kenya", level:"Lower middle income"},
      { country:"Kiribati", level:"Lower middle income"},
      { country:"Kosovo", level:"High and upper middle income"},
      { country:"Kuwait", level:"High and upper middle income"},
      { country:"Kyrgyzstan", level:"Lower middle income"},
      { country:"Laos", level:"Lower middle income"},
      { country:"Latvia", level:"High and upper middle income"},
      { country:"Lebanon", level:"Lower middle income"},
      { country:"Lesotho", level:"Lower middle income"},
      { country:"Liberia", level:"Low income"},
      { country:"Libya", level:"High and upper middle income"},
      { country:"Liechtenstein", level:"High and upper middle income"},
      { country:"Lithuania", level:"High and upper middle income"},
      { country:"Luxembourg", level:"High and upper middle income"},
      { country:"Macau", level:"High and upper middle income"},
      { country:"Madagascar", level:"Low income"},
      { country:"Malawi", level:"Low income"},
      { country:"Malaysia", level:"High and upper middle income"},
      { country:"Maldives", level:"High and upper middle income"},
      { country:"Mali", level:"Low income"},
      { country:"Malta", level:"High and upper middle income"},
      { country:"Marshall Islands", level:"High and upper middle income"},
      { country:"Mauritania", level:"Lower middle income"},
      { country:"Mauritius", level:"High and upper middle income"},
      { country:"Mexico", level:"High and upper middle income"},
      { country:"Moldova", level:"High and upper middle income"},
      { country:"Monaco", level:"High and upper middle income"},
      { country:"Mongolia", level:"Lower middle income"},
      { country:"Montenegro", level:"High and upper middle income"},
      { country:"Morocco", level:"Lower middle income"},
      { country:"Mozambique", level:"Low income"},
      { country:"Myanmar", level:"Lower middle income"},
      { country:"Namibia", level:"High and upper middle income"},
      { country:"Nauru", level:"High and upper middle income"},
      { country:"Nepal", level:"Lower middle income"},
      { country:"Netherlands", level:"High and upper middle income"},
      { country:"New Zealand", level:"High and upper middle income"},
      { country:"Nicaragua", level:"Lower middle income"},
      { country:"Niger", level:"Low income"},
      { country:"Nigeria", level:"Lower middle income"},
      { country:"Korea (Democratic People's Republic of)", level:"Low income"},
      { country:"Norway", level:"High and upper middle income"},
      { country:"Oman", level:"High and upper middle income"},
      { country:"Pakistan", level:"Lower middle income"},
      { country:"Palau", level:"High and upper middle income"},
      { country:"Palestine", level:"Lower middle income"},
      { country:"Panama", level:"High and upper middle income"},
      { country:"Papua New Guinea", level:"Lower middle income"},
      { country:"Paraguay", level:"High and upper middle income"},
      { country:"Peru", level:"High and upper middle income"},
      { country:"Philippines", level:"Lower middle income"},
      { country:"Poland", level:"High and upper middle income"},
      { country:"Portugal", level:"High and upper middle income"},
      { country:"Puerto Rico", level:"High and upper middle income"},
      { country:"Qatar", level:"High and upper middle income"},
      { country:"Romania", level:"High and upper middle income"},
      { country:"Russia", level:"High and upper middle income"},
      { country:"Rwanda", level:"Low income"},
      { country:"St Kitts and Nevis", level:"High and upper middle income"},
      { country:"St Lucia", level:"High and upper middle income"},
      { country:"Samoa", level:"Lower middle income"},
      { country:"San Marino", level:"High and upper middle income"},
      { country:"Sao Tome and Principe", level:"Lower middle income"},
      { country:"Saudi Arabia", level:"High and upper middle income"},
      { country:"Senegal", level:"Lower middle income"},
      { country:"Serbia", level:"High and upper middle income"},
      { country:"Seychelles", level:"High and upper middle income"},
      { country:"Sierra Leone", level:"Low income"},
      { country:"Singapore", level:"High and upper middle income"},
      { country:"Sint Maarten (Dutch part)", level:"High and upper middle income"},
      { country:"Slovakia", level:"High and upper middle income"},
      { country:"Slovenia", level:"High and upper middle income"},
      { country:"Solomon Islands", level:"Lower middle income"},
      { country:"Somalia", level:"Low income"},
      { country:"South Africa", level:"High and upper middle income"},
      { country:"Korea (Republic of)", level:"High and upper middle income"},
      { country:"South Sudan", level:"Low income"},
      { country:"Spain", level:"High and upper middle income"},
      { country:"Sri Lanka", level:"Lower middle income"},
      { country:"Sudan", level:"Low income"},
      { country:"Suriname", level:"High and upper middle income"},
      { country:"Eswatini", level:"Lower middle income"},
      { country:"Sweden", level:"High and upper middle income"},
      { country:"Switzerland", level:"High and upper middle income"},
      { country:"Syria", level:"Low income"},
      { country:"Taiwan", level:"High and upper middle income"},
      { country:"Tajikistan", level:"Lower middle income"},
      { country:"Tanzania", level:"Lower middle income"},
      { country:"Thailand", level:"High and upper middle income"},
      { country:"East Timor", level:"Lower middle income"},
      { country:"Togo", level:"Low income"},
      { country:"Tonga", level:"High and upper middle income"},
      { country:"Trinidad and Tobago", level:"High and upper middle income"},
      { country:"Tunisia", level:"Lower middle income"},
      { country:"Turkey", level:"High and upper middle income"},
      { country:"Turkmenistan", level:"High and upper middle income"},
      { country:"Tuvalu", level:"High and upper middle income"},
      { country:"Uganda", level:"Low income"},
      { country:"Ukraine", level:"Lower middle income"},
      { country:"United Arab Emirates", level:"High and upper middle income"},
      { country:"United Kingdom", level:"High and upper middle income"},
      { country:"United States", level:"High and upper middle income"},
      { country:"Uruguay", level:"High and upper middle income"},
      { country:"Uzbekistan", level:"Lower middle income"},
      { country:"Vanuatu", level:"Lower middle income"},
      { country:"Venezuela", level:"High and upper middle income"},
      { country:"Vietnam", level:"Lower middle income"},
      { country:"Yemen", level:"Low income"},
      { country:"Zambia", level:"Low income"},
      { country:"Zimbabwe", level:"Lower middle income"},      
    ]
  },
  mutations: {
  },
  actions: {
  },
  modules: {
  }
})