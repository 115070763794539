<script setup>
    import { defineProps } from 'vue'

    const props = defineProps({
        itemId: String,
        parentId: String,
        show: { type: Boolean, default() { return false }}
    })

    let headerTarget = "header"+props.parentId+"-"+props.itemId
    let collapseTarget = "collapse"+props.parentId+"-"+props.itemId
    let collapseDataTarget = "#"+collapseTarget
    let parentTarget = "#"+props.parentId
    let showClass = props.show ? " show" : ""

</script>

<style scoped>
.accordion-item {
    /*border-color: #ffffff;
    border-width:3px;*/
    border-bottom: 3px solid #ffffff;
    color: #ffffff;
}
.accordion-item:last-child {
    border-bottom:none;
}
.accordion-header, .accordion-button, .accordion-button:not(.colapsed), .accordion-button:focus {
    background-color: #41b6e6;
    border-color: #41b6e6;
    color: #ffffff;
    box-shadow:none;
    font-weight:600;
    font-size: 1.5rem;
}
.accordion-body {
    /*background-color: #41b6e620;*/
    background-color: #ffffff;
}
.accordion-button:after, .accordion-button:not(.collapsed)::after {
    background-image:url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='white'%3e%3cpath fill-rule='evenodd' d='M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z'/%3e%3c/svg%3e");
}

</style>



<template>
    <div class="accordion-item accordion-flush">
        <h2 class="accordion-header" :id="headerTarget">
            <button
                :data-bs-target="collapseDataTarget"
                :aria-controls="collapseTarget"
                class="accordion-button collapsed"
                type="button"
                data-bs-toggle="collapse"
                aria-expanded="true"
            >
            <slot name="header"></slot>
            </button>
        </h2>
        <div
            :id="collapseTarget"
            :aria-labelledby="headerTarget"
            :data-bs-parent="parentTarget"
            :class="`accordion-collapse collapse ${showClass}`"
        >
            <div class="accordion-body fg-wp-black">
               <slot name="body"></slot>
            </div>
        </div>
    </div>
</template>