<script setup>
    import Partners from '@/components/Partners.vue'
    import PageHeader from "@/components/PageHeader.vue"
    import ActionButton from '@/components/ActionButton.vue'    
    import IndustryCard from '@/components/IndustryCard.vue'
    import store from '@/store'

    let industry = store.state.industry

    let letters = []
    industry.forEach((ind) => {
        if (!letters.includes(ind.name[0])) letters.push(ind.name[0])
    })
    letters.sort()

</script>

<template>
    <PageHeader title="Congress partners and exhibitors">
        <template #summary>Find out who will be exhibiting and the World Physiotherapy Congress 2023 in Dubai</template>
        <template #buttons>
            <ActionButton action="exhibitorMap" />
            <ActionButton action="exhibition" />
        </template>
    </PageHeader>    
    
    <!-- Congress partners listing -->
    <Partners />


    <!-- Congress exhibitors listing -->
    <!--<div class="bg-wp-congress-mid">
        <div class="container text-center py-4">
            <h1 class="m-0">Partners and exhibitors</h1>
        </div>
    </div>
    -->

    <!-- Exhibitor filter bar-->
    <header id="filter-bar" class="shadow bg-wp-congress">
        <div class="container pt-4">    
            <div class="d-flex flex-wrap justify-content-start">
                <form class="mb-3 col-12 col-md-10">
                    <input type="search" class="form-control" placeholder="Search for exhibitor..." aria-label="Search" />
                </form>
                <div class="col-2 col-md-2 ps-md-2">
                    <select class="form-select mb-4" aria-label=".form-select">
                        <option selected>All types</option>
                        <option value="1">Platinum</option>
                        <option value="2">Gold</option>
                        <option value="3">Silver</option>
                        <option value="4">Exhibitor</option>
                    </select>
                </div>

            </div>
        </div>

        <div class="container pb-4">
            <div class="d-flex flex-wrap justify-content-center">
                <a v-for="letter in letters" v-bind:key="letter" class="btn btn-sm btn-wp-exercise me-1">{{letter}}</a>
            </div>
        </div>
    </header>

    <section id="exhibitors" class="bg-wp-congress-mid py-5">
        <div class="container">
            <div class="row row-eq-height row-cols-2 row-cols-md-3 row-cols-lg-4 row-cols-xl-5 g-5 justify-content-center">
                <IndustryCard v-for="item in industry" v-bind:key="item.id"
                    :id="item.id"
                    :name="item.name"
                    :level="item.level"
                    :image="item.image">
                </IndustryCard>
            </div>
        </div>
    </section>
</template>