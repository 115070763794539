<template>

  <PageNavigation />
  <PageBanner :home="true" />

  <!-- Page content -->
  <router-view :key="$route.fullPath" />
  <!-- End Page content-->

</template>


<script setup>
    import PageNavigation from '@/components/PageNavigation.vue'
    import PageBanner from '@/components/PageBanner.vue'

</script>

<script>
export default {
  watch: {
      '$route' (to) {
          let title = 'WP2023'
          if (to.meta.title) title += ' | '+to.meta.title
          if (to.params.id) title += ' | '+to.params.id
          document.title = title
      }
  },
  computed: {
    isHome() {
      return this.$route.name=="Home"
    }
  }
}
</script>