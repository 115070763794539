<script setup>
    import { defineProps } from 'vue'
    import store from '@/store'

    const congress = store.state.congress

    const props = defineProps({
      SessionCode: String,
      SessionType: String,
      SessionDate: String,
      SessionTime: String,
      SessionLocation: String,
      SessionTitle: String,
      SessionChairs: String,
      SessionSpeakers: String,
      mysessions: { type: Boolean, default() { return false }},
      showpeople: { type: Boolean, default() { return false }}      
    })

    const sessionLink = '/#/programme/session/'+props.SessionCode
    const showDates = congress.programmeDates

    //const AbstractType = ['Platform session','Poster session','ePoster session'].includes(props.SessionType)

    // Insert logic to check confirmation status of logged in user
    //const confirmed = (props.SessionType=='Discussion session')

</script>



<template>
  <div class="col">
      <div class="card wp-card shadow wp-session">
          <div class="card-header d-flex flex-wrap justify-content-between s-fs">
              <div class="session-code">{{ props.SessionCode }}</div>
              <div class="session-type">{{ props.SessionType }}</div>
          </div>
          <div v-if="showDates" class="session-programming d-flex flex-wrap justify-content-between">
                <div class="session-date"><i class="bi bi-calendar-event pe-1 fg-wp-dark" />{{ props.SessionDate }}</div>
                <div class="session-time"><i class="bi bi-clock pe-1 fg-wp-dark" />{{ props.SessionTime }}</div>
                <div class="session-room"><i class="bi bi-geo-alt pe-1 fg-wp-dark" />{{ props.SessionLocation }}</div>
          </div>
          <div class="card-body bg-wp-light">

              <h6 class="card-title">{{ props.SessionTitle }}</h6>

              <p v-if="props.showpeople" class="card-text pt-3">
                  <b>Chair/Presenter:</b> {{ props.SessionChairs }}<br>
                  <b>Presenters:</b> {{ props.SessionSpeakers }}
              </p>

              <div class="mt-3">
                <a :href="sessionLink" class="btn btn-sm btn-outline-primary stretched-link">View</a>
                <!--<a v-if="props.mysessions && !AbstractType && !confirmed" href="#" class="btn btn-sm btn-primary ms-2">Confirm intention to present</a>-->  <!-- Link to planner confirmation -->
                <!--<a v-if="props.mysessions && !AbstractType && confirmed" href="#" class="btn btn-sm btn-primary ms-2">Update confirmation</a>-->  <!-- Link to planner confirmation -->
              </div>
          </div>
      </div>
  </div>
</template>
