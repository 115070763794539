<script setup>
    import { defineProps } from 'vue'

    const props = defineProps({
        title: String,
        type:{ type: String, default() { return "normal" }}
    })

    let headerClass = "bg-wp-dark"
    
    switch(props.type) {
        case "header1": 
            headerClass="bg-wp-dark-header1"; 
            break;
    }

</script>


<template>
    <header id="header" :class="headerClass">
        <div class="container py-5">
            <div class="row justify-content-center justify-content-md-between g-5">
                <div class="col-12 col-md-8">
                    <div class="text-center text-md-start">
                        <h1 class="mt-0 fg-wp-light">{{ props.title }}</h1>
                        <p class="opacity-70">
                            <slot name="summary"></slot>
                        </p>
                        <div class="mt-5 mb-5 me-lg-auto pt-3">
                            <div class="d-flex flex-column flex-md-row justify-content-center justify-content-md-start mt-2">
                                <slot name="buttons"></slot>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-12 col-md-4">
                    <slot></slot>
                </div>
            </div>
        </div>
    </header>
</template>