<script setup>
    import PageHeader2col from "@/components/PageHeader2col.vue"
    import ActionButton from '@/components/ActionButton.vue'
    import EmbedVideo from "@/components/EmbedVideo.vue"
    //import Partners from '@/components/Partners.vue'

    import store from '@/store'
    import { useRoute } from 'vue-router'

    const route = useRoute()

    const industryList = store.state.industry

    const industryResults = industryList.filter((ind) => ind.id == route.params.id)

    const exhibitor = industryResults[0]

    const hasAddress = exhibitor.street || exhibitor.city || exhibitor.zip || exhibitor.country

</script>

<template>

    <PageHeader2col :title="exhibitor.name">
        <template #summary>{{ exhibitor.level }}</template>
        <template #buttons>
            <ActionButton action="exhibitors" />
            <ActionButton action="addToFavourites" type="light" />
        </template>
        <template #default>
            <div class="text-center text-md-end"  >
                <img :src="exhibitor.image" class="card-img-full bg-white shadow wp-border-radius p-3" style="max-width:300px" :alt="exhibitor.name">
            </div>
        </template>
    </PageHeader2col>

    <section class="container">
        <div class="row g-5">
            <div class="col pt-xl-5 order-2 order-xl-1">

                <h2 v-if="exhibitor.stand" class="">Stand <span class="fg-wp-congress">{{exhibitor.stand}}</span></h2>

                <!-- Exhibitor description -->
                <div id="description" class="pb-5">
                    <p>Lorem ipsum dolor sit, amet consectetur adipisicing elit. Eveniet repellendus illum nisi at facere minus?</p>
                    
                    <p>Magni beatae corrupti consequatur dolor eius soluta, facere eligendi a! Nobis, deleniti at? Odio, nemo?
                    Lorem ipsum dolor sit amet consectetur adipisicing elit. Tenetur neque et fugit illo, asperiores in dolore 
                    laboriosam nulla ex provident voluptates aliquid reiciendis recusandae distinctio? Architecto fugit doloribus omnis unde!</p>

                    <p>Magni beatae corrupti consequatur dolor eius soluta, facere eligendi a! Nobis, deleniti at? Odio, nemo?
                    Lorem ipsum dolor sit amet consectetur adipisicing elit. Tenetur neque et fugit illo, asperiores in dolore 
                    laboriosam nulla ex provident voluptates aliquid reiciendis recusandae distinctio? Architecto fugit doloribus omnis unde!</p>

                    <p>Magni beatae corrupti consequatur dolor eius soluta, facere eligendi a! Nobis, deleniti at? Odio, nemo?
                    Lorem ipsum dolor sit amet consectetur adipisicing elit. Tenetur neque et fugit illo, asperiores in dolore 
                    laboriosam nulla ex provident voluptates aliquid reiciendis recusandae distinctio? Architecto fugit doloribus omnis unde!</p>
                </div>

                <!-- TODO: Show exhition map when avaialble-->


                <!-- Video -->
                <div id="video1" class="pb-5">
                    <div class="shadow wp-rounded p-0">
                        <EmbedVideo code="uoWQ5KaeRxE" title="Introductory video" />
                    </div>
                </div>

                <div id="video2" class="pb-5">
                    <div class="shadow wp-rounded p-0">
                        <EmbedVideo code="uoWQ5KaeRxE" title="Introductory video" />
                    </div>
                </div>

                
            </div>
            
            <div class="pt-5 col-xl-4 order-1 order-xl-2">
                <!-- Info -->

                <div class="card wp-card shadow wp-border-radius h-auto">
                    <div class="card-header text-center bg-wp-congress">
                        Contact details
                    </div>
                    <div class="p-4">
                        <div v-if="exhibitor.website" class="d-flex flex-row pb-3">
                            <i class="bi bi-globe label" aria-label="Website"></i>
                            <a :href="exhibitor.website" target="_blank">{{exhibitor.website}}</a>
                        </div>
                        <div v-if="exhibitor.email" class="d-flex flex-row pb-3">
                            <i class="bi bi-envelope label" aria-label="Email"></i>
                            <a :href="'mailto://'+exhibitor.email" target="_blank">{{exhibitor.email}}</a>
                        </div>
                        <div v-if="exhibitor.phone" class="d-flex flex-row pb-3">
                            <i class="bi bi-telephone label" aria-label="Phone"></i>
                            <div>{{exhibitor.phone}}</div>
                        </div>
                        <div v-if="hasAddress" class="d-flex flex-row">
                            <i class="bi bi-geo-alt label" aria-label="Location"></i>
                            <div>
                                <div v-if="exhibitor.street">{{exhibitor.street}}</div>
                                <div v-if="exhibitor.city">{{exhibitor.city}}</div>
                                <div v-if="exhibitor.zip">{{exhibitor.zip}}</div>
                                <div v-if="exhibitor.country">{{exhibitor.country}}</div>
                            </div>                             
                        </div>
                    </div>
                </div>


                <div class="pt-5">
                    <div class="card wp-card shadow wp-border-radius h-auto">
                        <div class="card-header text-center bg-wp-congress">
                            Exhibition map
                        </div>
                        <div class="overflow-hidden m-0 wp-border-bottom">
                            <a class="exhibition-map" href="/#/exhibition/map">
                                <img src="/img/industry/WP2023-ExhibitionFloorPlan-v1.svg" class="bg-white img-fluid " alt="Exhibition map" />
                            </a>
                        </div>
                    </div>
                </div>

            </div>

        </div>

        <div class="col pb-5">
            <h2 class="header-line pb-3">Download exhibitor resources</h2>
            <div class="row row eq-height row-cols-2 row-cols-md-3 row-cols-lg-4 g-5 justify-content-center">

                <div class="col">
                    <div class="card wp-card">
                        <div class="shadow wp-border-radius">
                            <div class="wp-card-img">
                                <img src="/img/industry/ExhibitorBrochure1.png" class="card-img-top wp-card-img bg-white" alt="Brochure 1">
                            </div>
                            <div class="p-3 text-center">
                                <a href="#" class="btn btn-sm btn-outline-primary stretched-link">View brochure</a>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="col">
                    <div class="card wp-card">
                        <div class="shadow wp-border-radius">
                            <div class="wp-card-img">
                                <img src="/img/industry/ExhibitorBrochure2.png" class="card-img-top wp-card-img bg-white" alt="Brochure 2">
                            </div>
                            <div class="p-3 text-center">
                                <a href="#" class="btn btn-sm btn-outline-primary stretched-link">View brochure</a>
                            </div>
                        </div>
                    </div>
                </div>
            
                <div class="col">
                    <div class="card wp-card">
                        <div class="shadow wp-border-radius">
                            <div class="wp-card-img">
                                <img src="/img/industry/ExhibitorBrochure1.png" class="card-img-top wp-card-img bg-white" alt="Brochure 3">
                            </div>
                            <div class="p-3 text-center">
                                <a href="#" class="btn btn-sm btn-outline-primary stretched-link">View brochure</a>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="col">
                    <div class="card wp-card">
                        <div class="shadow wp-border-radius">
                            <div class="wp-card-img">
                                <img src="/img/industry/ExhibitorBrochure2.png" class="card-img-top wp-card-img bg-white" alt="Brochure 4">
                            </div>
                            <div class="p-3 text-center">
                                <a href="#" class="btn btn-sm btn-outline-primary stretched-link">View brochure</a>
                            </div>
                        </div>
                    </div>
                </div>


            </div>
        </div>
    </section>

    <!--<Partners buttons />-->

</template>