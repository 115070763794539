<script setup>
    import { defineProps } from 'vue'

    const props = defineProps({
        ID: String,
        Title: String,
        Type: String,
        Status: String
    })


    const FSType = (props.Type == 'Focused symposium')
    const PosterType = ['Poster presentation','ePoster presentation'].includes(props.Type)

    const ManageFSSubscription = (FSType && props.Status=='Draft')

    const ManageAbstractSubscription = (!FSType && props.Status=='Draft')
    const ConfirmAbstract = (!FSType && props.Status=='Awaiting confirmation')

    const ViewFS = (FSType)
    const ViewAbstract = (!FSType)

    const ChangePres = (!FSType && ['Confirmed','Programmed','Awaiting presentation','Ready'].includes(props.Status))

    const Withdraw = (!FSType && ['Awaiting confirmation','Confirmed','Programmed','Awaiting presentation','Ready'].includes(props.Status))

    const UploadPDF = (PosterType && ['Awaiting presentation'].includes(props.Status))
    const ChangeUploadPDF = (PosterType && ['Ready'].includes(props.Status))
    const UploadPPT = (!PosterType && !FSType && props.Status=='Awaiting presentation')
    const ChangeUploadPPT = (!PosterType && !FSType && props.Status=='Ready')

</script>


<template>
  <div class="col">
      <div class="card wp-card shadow wp-session position-relative">
          <div class="card-header d-flex flex-wrap justify-content-between s-fs">
              <div class="session-type">{{ Type }}</div>
              <div class=" fw-bold fg-light">{{ Status }}</div>
          </div>
          <div class="card-body bg-wp-light">
              <h6 class="card-title">{{ Title }} <span class="badge rounded-pill fg-wp-dark bg-wp-congress-light">{{ props.ID }}</span></h6>
              
              <div class="mt-4">
                <a v-if="ViewFS" href="#" class="btn btn-sm btn-outline-primary me-2 mt-2">View</a> <!-- Link to view FS abstract --> 
                <a v-if="ViewAbstract" href="#" class="btn btn-sm btn-outline-primary me-2 mt-2">View</a> <!-- Link to view abstract -->   
                <a v-if="ManageFSSubscription" href="#" class="btn btn-sm btn-primary me-2 mt-2">Manage submission</a> <!-- Link to FS submission system -->
                <a v-if="ManageAbstractSubscription" href="#" class="btn btn-sm btn-primary me-2 mt-2">Manage submission</a> <!-- Link to abstract submission system -->
                <a v-if="ConfirmAbstract" href="#" class="btn btn-sm btn-primary me-2 mt-2">Confirm intention to present</a> <!-- Link to abstract confirmation -->
                <a v-if="UploadPDF" href="#" class="btn btn-sm btn-primary me-2 mt-2">Upload e-poster</a> <!-- Link to poster upload -->
                <a v-if="UploadPPT" href="#" class="btn btn-sm btn-primary me-2 mt-2">Upload Powerpoint</a> <!-- Link to Powerpoint upload -->
                <a v-if="ChangeUploadPDF" href="#" class="btn btn-sm btn-primary me-2 mt-2">Change e-poster</a> <!-- Link to poster upload -->
                <a v-if="ChangeUploadPPT" href="#" class="btn btn-sm btn-primary me-2 mt-2">Change Powerpoint</a> <!-- Link to Powerpoint upload -->
                <a v-if="ChangePres" href="#" class="btn btn-sm btn-outline-primary me-2 mt-2">Change presenter</a>  <!-- Link to abstract confirmation -->
                <a v-if="Withdraw" href="#" class="btn btn-sm btn-outline-primary me-2 mt-2">Withdraw abstract</a>  <!-- Link to abstract confirmation -->
              </div>
          </div>
      </div>
  </div>
</template>


