<script setup>
    import SessionCard from '@/components/SessionCard.vue'
    import store from '@/store'
    import { defineProps } from 'vue'

    const props = defineProps({
        searchBar: { type: Boolean, default() { return false }},
        type: { type: String, default() { return "" }},
        title: { type: String, default() { return "" }},
        mysessions: { type: Boolean, default() { return false }},
        showpeople: { type: Boolean, default() { return true }}
    })

    let sessions = store.state.sessions
    if (props.type) sessions = sessions.filter((session) => session.Type == props.type)
    if (props.mysessions) sessions = sessions.filter((session) => ['FS-01','DS-01','PLS-1','POS-01'].includes(session.id)) // TODO: work out "my sessions" logic
    console.log("props.type="+props.type)

</script>


<template>
    <header v-if="props.searchBar" id="filter-bar" class="shadow bg-wp-congress py-1">
        <div class="container pt-4">
            <div class="d-flex flex-wrap justify-content-center">
                <form class="col-12 col-lg-4 pe-lg-3">
                    <input type="search" class="form-control fs-6 border-0 border-primary wp-rounded shadow mb-4" placeholder="Search programme..." aria-label="Search" />
                </form>
                <div class="col-12 col-lg-3 pe-lg-3">
                    <select class="form-select mb-1 bg-wp-dark border-0 border-white wp-rounded fs-6" aria-label=".form-select">
                        <option selected>All session types</option>
                        <option value="1">Focused sympsia</option>
                        <option value="2">Networking sessions</option>
                        <option value="3">Discussion sessions</option>
                        <option value="4">Seminars</option>
                        <option value="5">Indaba sessions</option>
                        <option value="6">Platform sessions</option>
                        <option value="7">ePosters sessions</option>
                    </select>
                </div>
                <div class="col-12 col-lg-3 pe-lg-3">
                    <select class="form-select mb-1 bg-wp-dark border-0 border-white wp-rounded fs-6" aria-label=".form-select">
                        <option selected>All topics</option>
                        <option value="1">Musculoskeltal</option>
                        <option value="2">Cardiorespiratory</option>
                        <option value="3">Sports</option>
                        <option value="4">Womens health</option>
                        <option value="5">Neurological</option>
                    </select>
                </div>
                <div class="col-6 col-lg-2">
                <select class="form-select mb-4 bg-wp-congress fg-wp-dark border-2 border-primary wp-rounded fs-7" aria-label=".form-select">
                    <option selected>Sort by date/time</option>
                    <option value="1">Sort by title</option>
                </select>
            </div>
            </div>

        </div>
    </header>


    <!-- Main content -->
    <section id="content" class="container py-5">
        <div v-if="props.title && sessions.length>0" class="pt-0">
            <h3 class="pb-2">{{props.title}}</h3>
        </div>

        <!-- Session cards -->
        <div id="sessions" >            
            <div class="row row-cols-1 row-cols-md-2 row-cols-lg-3 g-5 row-eq-height ">

                <session-card v-for="item in sessions" v-bind:key="item.id"
                    :SessionCode="item.id"
                    :SessionTitle="item.SessionTitle"
                    :SessionType="item.SessionType"
                    :SessionDate="item.SessionDate"
                    :SessionTime="item.SessionTime"
                    :SessionLocation="item.SessionLocation"
                    :SessionChairs="item.SessionChairs"
                    :SessionSpeakers="item.SessionSpeakers"
                    :mysessions="props.mysessions"
                    :showpeople="props.showpeople" />

            </div>
        </div>
    </section>
</template>


