<script setup>
    import { defineProps } from 'vue'

    const props = defineProps({
        personId: Number,
        personImg: String,
        personName: String,
        personCountry: String,
    })

    const personLink = '/programme/person/'+props.personId;

</script>

<template>
  <div class="d-flex justify-content-start align-items-center position-relative">
      <div class="me-2">
          <img class="img-fluid wp-round shadow" style="width:50px" :src="props.personImg">
      </div>
      <div class="mt-2">
          <div class="fg-wp-dark"><router-link :to="personLink" class="stretched-link fw-bold text-decoration-none">{{ props.personName }}</router-link></div>
          <div class="fw-bold">{{ props.personCountry }}</div>
      </div>
  </div>
</template>
