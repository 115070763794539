<script setup>
    import PageHeader from "@/components/PageHeader.vue"
    import ActionButton from '@/components/ActionButton.vue'
    import EmbedVideo from "@/components/EmbedVideo.vue"
    //import SessionTimePlace from '@/components/SessionTimePlace.vue'
    //import SessionInfoPanel from '@/components/SessionInfoPanel.vue'
    //import PresentationInfoPanel from '@/components/PresentationInfoPanel.vue'
    import CommentsPanel from '@/components/CommentsPanel.vue'
    //import PersonCardList from '@/components/PersonCardList.vue'

    import store from '@/store'
    //import { useRoute } from 'vue-router'

    //const route = useRoute()

    const showComments = false
    const showVideo = false

    const presentations = store.state.presentations

    const loadpres = "PL-1002"  // TODO: Replace with route.params.id

    const presResults = presentations.filter((pres) => pres.id == loadpres)
    const presentation = presResults[0]

    //const presTitle = presentation.id +": " + presentation.Title

</script>

<template>

    <PageHeader :title="presentation.Title" :id="presentation.id">
        <template #summary>
            <div class="fw-bold fs-2 fg-wp-exercise">{{ presentation.Type }}</div>
        </template>
        <template #buttons>
            <ActionButton action="addToFavourites" type="light-sm" />
            <ActionButton action="view abstract" type="light-sm" />
        </template>
    </PageHeader>

    <section class="container-lg">
        <div class="row g-5 mt-0 ">
            <div class="col-xl-8 order-2 order-xl-1">

                <!-- Video -->
                <div v-if="showVideo" id="video" class="mb-5">
                    <div class="shadow wp-rounded p-0">
                        <EmbedVideo code="uoWQ5KaeRxE" title="Introductory video" />
                    </div>
                </div>

                <!-- Presentations -->
                <!--
                <div class="mb-5">
                    <PresentationInfoPanel :session="session" class="h-100" />
                </div>-->

            </div>

            
            <!-- Session summary information -->
            <!--<div class="col-xl-4 order-1 order-xl-2 h-100">
                <SessionInfoPanel :session="session"  />
            </div>-->

            <div v-if="showComments" ref="commentSection" class="col order-last">
                <CommentsPanel />
            </div>

        </div>
    </section>

</template>