<script setup>
    import PageHeader from "@/components/PageHeader.vue"
    import ActionButton from '@/components/ActionButton.vue'
    import EmbedVideo from "@/components/EmbedVideo.vue"
    //import SessionTimePlace from '@/components/SessionTimePlace.vue'
    import SessionInfoPanel from '@/components/SessionInfoPanel.vue'
    import PresentationInfoPanel from '@/components/PresentationInfoPanel.vue'
    import CommentsPanel from '@/components/CommentsPanel.vue'
    //import PersonCardList from '@/components/PersonCardList.vue'

    import store from '@/store'
    import { useRoute } from 'vue-router'

    const route = useRoute()

    const showComments = false
    const showVideo = false

    const sessions = store.state.sessions

    const sessionResults = sessions.filter((session) => session.id == route.params.id)
    const session = sessionResults[0]

    //const sessionTitle = session.id +": " + session.SessionTitle

    /*
    const chairResults = store.state.sessionPeople.filter((sessionPerson) => sessionPerson.sessionId == route.params.id && sessionPerson.role == 'Chair/Speaker')
    const chairs = chairResults.map((person) => {
        return store.state.people.find((p) => p.personId == person.personId)
    })

    const speakerResults = store.state.sessionPeople.filter((sessionPerson) => sessionPerson.sessionId == route.params.id && sessionPerson.role == 'Speaker')
    const speakers = speakerResults.map((person) => {
        return store.state.people.find((p) => p.personId == person.personId)
    })
    */

</script>

<template>

    <PageHeader :title="session.SessionTitle" :id="session.id">
        <template #summary>
            <div class="fw-bold fs-2 fg-wp-exercise">{{ session.SessionType }}</div>
        </template>
        <template #buttons>
            <ActionButton action="addToFavourites" type="light-sm" />
            <ActionButton action="translate" type="light-sm" />
            <ActionButton action="evaluate" type="light-sm" />
        </template>
    </PageHeader>

    <!-- <SessionTimePlace :session="session" /> -->
    
    <!--
    <PersonCardList title="Chair/Speakers" :people="chairs" />
    <PersonCardList title="Speakers" :people="speakers" class="pb-5" />
    -->

    <section class="container-lg">
        <div class="row g-5 mt-0 ">

            <div class="col-xl-8 order-2 order-xl-1">

                <!-- Video -->
                <div v-if="showVideo" id="video" class="mb-5">
                    <div class="shadow wp-rounded p-0">
                        <EmbedVideo code="uoWQ5KaeRxE" title="Introductory video" />
                    </div>
                </div>

                <!-- Description -->
                <div v-if="session.SessionDesc" id="description">
                    <div class="wp-rounded shadow position-relative mb-5 bg-wp-congress-light">
                        <div class="p-4" v-html="session.SessionDesc" />
                    </div>
                </div>

                <!-- Presentations -->
                <div class="mb-5">
                    <PresentationInfoPanel :session="session" class="h-100" />
                </div>

            </div>

            
            <!-- Session summary information -->
            <div class="col-xl-4 order-1 order-xl-2 h-100">
                <SessionInfoPanel :session="session"  />

            </div>

            <div v-if="showComments" ref="commentSection" class="col order-last">
                <CommentsPanel />
            </div>

        </div>
    </section>

</template>