<script setup>
    import store from '@/store'
    import { defineProps } from 'vue'

    const props = defineProps({
        action: String,
        type:{ type: String, default() { return "normal" }},
    })

    const actions = store.state.actions

    let actionTo = null
    let actionLabel = null
    let actionActive = false
    let actionIcon = null

    if (actions[props.action]) {
        actionTo = actions[props.action].to
        actionLabel = actions[props.action].label
        actionActive = actions[props.action].active
        actionIcon = actions[props.action].icon ? `bi bi-${actions[props.action].icon}` : null
    }

    let linkType = "inactive"
    if (actionActive) linkType = (actionTo.startsWith("http") || actionTo.startsWith("mailto")) ? "external" : "internal"

    let actionClass = "btn me-2 mb-2 btn-wp-exercise text-nowrap"    
    
    switch(props.type) {
        case "light": 
            actionClass="btn me-2 mb-2 btn-outline-light text-nowrap"
            break
        case "white":
            actionClass="btn me-2 mb-2 btn-wp-light text-nowrap"
            break
        case "light-sm": 
            actionClass="btn btn-sm me-2 mb-2 btn-outline-light text-nowrap"
            break
        case "sm":
            actionClass = "btn btn-sm me-2 mb-2 btn-wp-exercise text-nowrap"
            break
    }

</script>


<template>
    <div>
        <router-link v-if="linkType=='internal'" :to="actionTo" :class="actionClass"><i v-if="actionIcon" :class="actionIcon">&nbsp;</i>{{ actionLabel }}</router-link>
        <a v-if="linkType=='external'" :href="actionTo" :class="actionClass" target='_blank'><i v-if="actionIcon" :class="actionIcon">&nbsp;</i>{{ actionLabel }}</a>
    </div>
</template>