<script setup>
    import { defineProps } from 'vue'

    const props = defineProps({
        id: Number,
        name: String,
        level: String,
        image: String
    })

    const footerClass = (props.level=="Exhibitor" ? "card-footer text-center bg-wp-congress" : "card-footer text-center bg-wp-dark")

    const exhibitorLink = "/exhibition/exhibitor/" + props.id

</script>

<template>
    <div class="card wp-card">
        <div class="shadow wp-border-radius">
            <div class="ratio ratio-1x1 wp-card-img">
                <img :src="props.image" class="card-img-top wp-card-img bg-white" alt="props.name">
            </div>
            <div class="d-flex justify-content-center align-items-center bg-wp-white py-3 px-1" style="height:5rem">
                <h5 class="m-0 text-center" >{{props.name}}</h5>
            </div>
            <div :class="footerClass">
                <h6 class="m-0 fg-white text-uppercase">{{props.level}}</h6>
            </div>
            <router-link :to="exhibitorLink" class="stretched-link" />
        </div>
    </div>

</template>